<main>
  <form [formGroup]="form" autocomplete="off" (ngSubmit)="calculatePPP()">
    <section class="pt-65 pb-35 bg-brand-4">
      <div class="container">
        <div class="archive-header">
          <div class="archive-header-title">
            <h1 class="font-heading mb-30">Purchasing Power Parity (PPP)</h1>
            <p class="mb-0">
              Convert your cost of living from one currency to another using PPP
            </p>
          </div>
          <div class="breadcrumb">
            <a [routerLink]="['/categories']" rel="nofollow">Home</a>
            <span></span>
            <a [routerLink]="['/calculaters']" rel="nofollow">Calculators</a>
            <!-- Added Calculators link -->
            <span></span> PPP  
          </div>
        </div>
      </div>
    </section>

    <section class="pt-30 pb-65">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <input
                    matInput
                    formControlName="SourceId"
                    [hidden]="true"
                     
                  />
                  <ng-select
                    style="margin-top: -20px; height: 40px; width: 100%"
                    formControlName="SourceId"
                    bindLabel="CountryValue"
                    placeholder="Source Country"
                    [searchable]="true"
                    [clearable]="true"
                    (change)="onChangeHosAdmission($event)"
                    
                  >
                    <ng-option
                      *ngFor="let dtRow of dtTabHosAdmission"
                      [value]="dtRow.CountryValue"
                    >
                      {{ dtRow.CountryValue }}
                    </ng-option>
                  </ng-select>
                  <!-- <mat-error *ngIf="form.controls.SourceId.errors?.required">
                    Source Country is required
                  </mat-error> -->
                </mat-form-field>
              </div>

              <div class="col-12">
                <div class="form-group">
                  <input
                    class="form-control"
                    formControlName="Salary"
                    id="Salary"
                    type="text"
                    placeholder="{{ salaryPlaceholder }}"
                  />
                </div>
              </div>

              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <input
                    matInput
                    formControlName="TragetId"
                    [hidden]="true"
                     
                  />
                  <ng-select
                    style="margin-top: -20px; height: 40px; width: 100%"
                    formControlName="TragetId"
                    bindLabel="CountryValue"
                    placeholder="Target Country"
                    [searchable]="true"
                    [clearable]="true"
                    (change)="onChangeHosAdmission2($event)"
                  >
                    <ng-option
                      *ngFor="let dtRow of dtTabHosAdmission"
                      [value]="dtRow.CountryValue"
                    >
                      {{ dtRow.CountryValue }}
                    </ng-option>
                  </ng-select>
                  <!-- <mat-error *ngIf="form.controls.TragetId.errors?.required">
                    Target Country is required
                  </mat-error> -->
                </mat-form-field>
              </div>

              <div class="col-12">
                <div class="form-group">
                  <input
                    class="form-control"
                    formControlName="Output"
                    id="Output"
                    type="text"
                    readonly
                    placeholder="Output"
                  />
                </div>
              </div>
            </div>

            <div class="form-group">
              <button class="btn btn-lg bg-dark text-white" type="submit">
                Calculate PPP
              </button>
            </div>

            <div class="container">
              <div class="archive-header">
                <div class="archive-header-title">
                  <p class="mb-0">
                    {{dynamicMessage}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
           
          </div>
        </div>
      </div>
    </section>
  </form>
</main>
