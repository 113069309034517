import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
 

import {
  Category,
  Product,
  Employee,
  Customers,
  AssignJob,
  APIReturn,
  Currency,
  SupplierType,
  Clients,
  BusinessType,
  AppPrefix,
  AppPrefixtype,
  Suppliers,
  CaseFile,
  AgrementType,
  ModuleMaster,
  MenuRights,
  UserActivityLog,
  Department,
  Designation,
  MenuMaster,
  CasePayment,
  CaseWallet,
  CaseLawyers,
  CaseTimeSheet,
  vin_tables,
  CaseDocs,
  CrystalData,
  ExpenceType,
  Expence,
  Accounts,
  Invoice,
  LawyerStdHR,
  AppSettings,
  CreditInvoice,
  CreditInvoiceDet,
  Employees,
  Units,
  Item,
  Customer,
  Manufacturer,
  QuatationMaster,
  StatusMaster,
  QuotStatus,
  StockItems,
  OutStandingGuarantees,
  Stock,
  Bank,
  SOACustomers,
  SOALedger,
  ColumnAranger,
  SOAPaymentMaster,
  MOHInvoices,
  AICInvoice,
  WSPhotos,
  WSEvents,
  WSNews,
  WSRegistration,
  WSAgeGroup,
  Country,
  WSCoach,
  WSCoachingGroup,
  Items,
  PurchaseMaster,
  ServiceDet,
  ServiceMaster,
  ServiceHistory,
  Attendence,
  Company,
  WAUsers,
  DefMOP,
  TaxType,
  UnitRatio,
  ItemSerail,
  ItemBatch,
  State,
  OpBal,
  AccBook,
  PhysicalConditions,
  RiskByCustomer,
  ServiceSpare,
  Vouchers,
  ServiceLabour,
  ServiceInvoice,
  TabChanges,
  Colors,
  SDetBS,
  SalesMaster,
  SalesDet,
  ColumnMaster,
  HosMeal,
  HosCategory,
  HosRoomDet,
  HosRooms,
  PayPal,
  HosAdmission,
  HosBilling,
  HosRoomVacate,
  Reminder,
  ReminderUsers,
  ReminderGroups,
  ReminderPersonalUsers,
  WABot,
  VouchersDet,
  MobileMenu,
  MobileRights,
  BPBarcodePrint,
  ItemLocation,
  VouhcerGroup,
  BPLocation,
  BPFileType,
  ReportMenuMaster,
  Apps,
  ColumnDet,
  SalesReturnMaster,
  PRDetBS,
  PurchaseReturnMaster,
  PurchaseReturnDet,
  ServiceChats,
  EnquiryDet,
  EnquiryMaster,
  BarCodePrint,
  UEIItems,
  UEIStockMaster,
  UEIHeader,
  EstimateDet,
  EstDetBS,
  EstimateMaster,
  BlogData,
} from './app.models';
import { environment } from 'src/environments/environment';
// import { app } from 'server';
import { catchError, map, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';


// import { AnyCnameRecord } from 'dns';

// import * as pdfToPrinter from 'pdf-to-printer';

export class Data {
  constructor(
    public categories: Category[],
    public compareList: Product[],
    public wishList: Product[],
    public cartList: Product[],
    public totalPrice: number,
    public totalCartCount: number
  ) {}
}

@Injectable()
export class AppService {
//   horizontalPosition: MatSnackBarHorizontalPosition = 'start';
//   verticalPosition: MatSnackBarVerticalPosition = 'bottom';

//   horizontalPositionTop: MatSnackBarHorizontalPosition = 'center';
//   verticalPositionTop: MatSnackBarVerticalPosition = 'top';

  apiReturn: APIReturn | undefined;
//   public Data = new Data(
//     [], // categories
//     [], // compareList
//     [], // wishList
//     [], // cartList
//     [null], //totalPrice,
//     0 //totalCartCount
//   );

  public url = environment.url + '/assets/data/';
  // public gs_api = 'http://localhost:6896/api/';
  public gs_api = 'https://smartappstores.com/ServiceAPI/Api/';

//    public gs_api = 'https://smartappstores.com/UEIAPI/Api/';

  // public gs_apiWsAp = 'http://194.163.160.226:8000/';

  // public gs_api = "http://192.168.0.166:85/api/";
  // public gs_api = 'http://bizinvoiz.com/ServiceAPI/Api/';
  private exRateapiUrl = 'https://v6.exchangerate-api.com/v6/728e1c3a980c932d4acf09e6/latest/'; // Replace YOUR_API_KEY


  public message$: BehaviorSubject<string> = new BehaviorSubject('');
  constructor(
    private datePipe: DatePipe,
    // private _snackBar: MatSnackBar,
    public http: HttpClient,
    // public snackBar: MatSnackBar,
    // public dialog: MatDialog
  ) {}

//   createNewAccount(ls_key, ls_descri) {
//     // console.log('test');
//     //
//     // this.socket.emit('create-session', {
//     //   id: 'test2',
//     //   description:'',
//     // });
//   }



getExchangeRates(baseCurrency: string): Observable<any> {

  return this.http.get(`${this.exRateapiUrl}${baseCurrency}`);
}

public getUuid() {
    // var isLogin = localStorage.getItem('isLoggedIn');
    // var isToken = localStorage.getItem('token');

    // if (isToken == '' || isToken == null){
    // //   this.router.navigate(['adminlogin']);
    // }else{
    // console.log(localStorage.getItem('token'));

    // return sessionStorage.getItem('token') ?? '';
    return '50641509-0DCD-48C6-83B5-4F44C35F4438';
    // }
  }


  public msgBox(ls_type:any, ls_msg:any) {
    let ls_title;
    if (ls_type == 'Error') {
      ls_title = 'Oops. Something went wrong... ';
    //   this.openSnackBarTop(ls_msg);
    //   const dialogRef = this.dialog.open(InfoDialogComponent, {
    //     maxWidth: '400px',
    //     data: {
    //       type: ls_type,
    //       title: ls_title,
    //       message: ls_msg,
    //     },
    //   });
    } else {
      ls_title = 'Information';
    //   this.openSnackBar(ls_msg);
    }
  }

//   openSnackBar(ls_msg:any) {
//     this._snackBar.open('Info', ls_msg, {
//       horizontalPosition: this.horizontalPosition,
//       verticalPosition: this.verticalPosition,
//       duration: 3000,
//     });
//   }

//   openSnackBarTop(ls_msg:any) {
//     this._snackBar.open('Warning', ls_msg, {
//       horizontalPosition: this.horizontalPositionTop,
//       verticalPosition: this.verticalPositionTop,
//       duration: 1000,
//       panelClass: ['redNoMatch'],
//     });
//   }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }


  public getBlogData(ls_flag:any): Observable<BlogData[]> {
    const params = new HttpParams()
       .set('ls_token', this.getUuid())
       .set('ls_flag',ls_flag)
    let options = { params: params };
    return this.http.get<BlogData[]>(this.gs_api +'BlogData', options); 
   } 
  

   public getBlogDataById(ls_flag: any,li_id: any): Observable<BlogData[]> {
    const params = new HttpParams()
       .set('ls_token', this.getUuid())
       .set('ls_flag',ls_flag)
       .set('ls_euid',li_id)
    let options = { params: params };
    return this.http.get<BlogData[]>(this.gs_api +'BlogData', options); 
   } 
   
  public addEditUEIHeader(ueiheaders: UEIHeader) {
    var myPostObject = {
      id: ueiheaders.id,
      uuid: this.getUuid(),
      journalno: ueiheaders.JournalNo,
      date: ueiheaders.Date
        ? this.datePipe.transform(
            new Date(ueiheaders.Date).toISOString(),
            'yyyy-MM-dd'
          )
        : null,
      warehouse: ueiheaders.WareHouse,
      location: ueiheaders.Location,
      companyid: ueiheaders.CompanyId,
      status: ueiheaders.Status,
    };
    return this.http
      .post(this.gs_api + 'UEIHeader', myPostObject)
      .pipe(catchError(this.errorMgmt));
  }

  public getUEIHeader(ls_flag: any): Observable<UEIHeader[]> {
    const params = new HttpParams()
      .set('ls_token', this.getUuid())
      .set('ls_flag', ls_flag);
    let options = { params: params };
    return this.http.get<UEIHeader[]>(this.gs_api + 'UEIHeader', options);
  }

  public getUEIHeaderById(ls_flag: any,li_id: any): Observable<UEIHeader[]> {
    const params = new HttpParams()
      .set('ls_token', this.getUuid())
      .set('ls_flag', ls_flag)
      .set('ls_euid', li_id);
    let options = { params: params };
    return this.http.get<UEIHeader[]>(this.gs_api + 'UEIHeader', options);
  }


  public deleteUEIHeader = (li_id: string): Observable<any> => {
    const params = new HttpParams()
      .set('li_id', li_id)
      .set('ls_token', this.getUuid());
    let options = { params: params };
    return this.http
      .delete(this.gs_api + 'UEIHeader', options)
      .pipe(catchError(this.errorMgmt));
  };

  public addEditItemLocation(itemlocations: ItemLocation) {
    var myPostObject = {
      id: itemlocations.id,
      uuid: this.getUuid(),
      locationname: itemlocations.LocationName,
      address: itemlocations.Address,
      mobile: itemlocations.Mobile,
      notes: itemlocations.Notes,
      companyid: itemlocations.CompanyId,
      status: itemlocations.Status,
    };
    return this.http
      .post(this.gs_api + 'ItemLocation', myPostObject)
      .pipe(catchError(this.errorMgmt));
  }

  public addEditApps(appss: Apps) {
    var myPostObject = {
      id: appss.id,
      uuid: this.getUuid(),
      appname: appss.AppName,
      status: appss.Status,
      orderby: appss.Orderby,
      plantype: appss.PlanType,
    };
    return this.http
      .post(this.gs_api + 'Apps', myPostObject)
      .pipe(catchError(this.errorMgmt));
  }

  public getUEIStockMaster(ls_flag: any): Observable<UEIStockMaster[]> {
    const params = new HttpParams()
      .set('ls_token', this.getUuid())
      .set('ls_flag', ls_flag);
    let options = { params: params };
    return this.http.get<UEIStockMaster[]>(
      this.gs_api + 'UEIStockMaster',
      options
    );
  }

//   public getUEIStockMasterById(li_id, ls_flag): Observable<UEIStockMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', li_id);
//     let options = { params: params };
//     return this.http.get<UEIStockMaster[]>(
//       this.gs_api + 'UEIStockMaster',
//       options
//     );
//   }

  public addEditUEIItems(ueiitemss: UEIItems) {
    var myPostObject = {
      id: ueiitemss.id,
      uuid: this.getUuid(),
      barcode: ueiitemss.BarCode,
      barcodesetup: ueiitemss.BarCodeSetup,
      variantnumber: ueiitemss.VariantNumber,
      itemnumber: ueiitemss.ItemNumber,
      olditemid: ueiitemss.OldItemId,
      productname: ueiitemss.ProductName,
      configuration: ueiitemss.Configuration,
      size: ueiitemss.Size,
      color: ueiitemss.Color,
      style: ueiitemss.Style,
      unit: ueiitemss.Unit,
      companyid: ueiitemss.CompanyId,
      euid: ueiitemss.euid,
      status: ueiitemss.Status,
    };
    return this.http
      .post(this.gs_api + 'UEIItems', myPostObject)
      .pipe(catchError(this.errorMgmt));
  }

  public getUEIItems(ls_flag: any): Observable<UEIItems[]> {
    const params = new HttpParams()
      .set('ls_token', this.getUuid())
      .set('ls_flag', ls_flag);
    let options = { params: params };
    return this.http.get<UEIItems[]>(this.gs_api + 'UEIItems', options);
  }

//   public deleteUEIItems = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'UEIItems', options)
//       .pipe(catchError(this.errorMgmt));
//   };

  public addEditBarCodePrint(barcodeprints: any) {
    var myPostObject = {
      id: barcodeprints.id,
      uuid: this.getUuid(),
      barcode: barcodeprints.BarCode,
      copy: barcodeprints.Copy,
      itemid: barcodeprints.ItemId,
      srate: barcodeprints.SRate,
      prnt: barcodeprints.Prnt,
      companyid: barcodeprints.CompanyId,
      status: barcodeprints.Status,
    };
    return this.http
      .post(this.gs_api + 'BarCodePrint', myPostObject)
      .pipe(catchError(this.errorMgmt));
  }

  public getBarCodePrint(ls_flag: any): Observable<BarCodePrint[]> {
    const params = new HttpParams()
      .set('ls_token', this.getUuid())
      .set('ls_flag', ls_flag);
    let options = { params: params };
    return this.http.get<BarCodePrint[]>(this.gs_api + 'BarCodePrint', options);
  }

//   public getBarCodePrintById(ls_flag: any, li_id): Observable<BarCodePrint[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('li_id', li_id);
//     let options = { params: params };
//     return this.http.get<BarCodePrint[]>(this.gs_api + 'BarCodePrint', options);
//   }

//   public deleteBarCodePrint = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'BarCodePrint', options)
//       .pipe(catchError(this.errorMgmt));
//   };

  public addEditEnquiryDet(enquirydets: EnquiryDet) {
    var myPostObject = {
      id: enquirydets.id,
      uuid: this.getUuid(),
      refid: enquirydets.RefId,
      enqdate: enquirydets.EnqDate
        ? this.datePipe.transform(
            new Date(enquirydets.EnqDate).toISOString(),
            'yyyy-MM-dd'
          )
        : null,
      msg: enquirydets.Msg,
      chattype: enquirydets.ChatType,
      userid: enquirydets.UserId,
      status: enquirydets.Status,
    };
    return this.http
      .post(this.gs_api + 'EnquiryDet', myPostObject)
      .pipe(catchError(this.errorMgmt));
  }
  public getEnquiryDet(ls_flag: any): Observable<EnquiryDet[]> {
    const params = new HttpParams()
      .set('ls_token', this.getUuid())
      .set('ls_flag', ls_flag);
    let options = { params: params };
    return this.http.get<EnquiryDet[]>(this.gs_api + 'EnquiryDet', options);
  }

//   public addEditEnquiryMaster(enquirymastersHeader: EnquiryMaster) {
//     var element = {},
//       cart = [];
//     element['id'] = enquirymastersHeader.id;
//     element['Refid'] = enquirymastersHeader.ServiceId;
//     (element['EnqDate'] = enquirymastersHeader.ChatDate
//       ? this.datePipe.transform(
//           new Date(enquirymastersHeader.ChatDate).toISOString(),
//           'yyyy-MM-dd HH:mm:ss'
//         )
//       : null),
//       (element['Msg'] = enquirymastersHeader.Msg);
//     element['ChatType'] = enquirymastersHeader.ChatType;
//     cart.push(element);

//     var myPostObject = {
//       id: enquirymastersHeader.ServiceId,
//       uuid: this.getUuid(),
//       cusid: enquirymastersHeader.CusId,
//       name: enquirymastersHeader.Name,
//       mobile: enquirymastersHeader.Mobile,
//       subject: enquirymastersHeader.Subject,
//       enqstatus: 'Pending',
//       companyid: enquirymastersHeader.CompanyId,
//       status: enquirymastersHeader.Status,
//       EnquiryDet: cart,
//     };
//     return this.http
//       .post(this.gs_api + 'EnquiryMaster', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

  public getEnquiryMaster(ls_flag: any): Observable<EnquiryMaster[]> {
    const params = new HttpParams()
      .set('ls_token', this.getUuid())
      .set('ls_flag', ls_flag);
    let options = { params: params };
    return this.http.get<EnquiryMaster[]>(
      this.gs_api + 'EnquiryMaster',
      options
    );
  }

//   public getEnquiryMasterById(li_id, ls_flag): Observable<EnquiryMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', li_id);
//     let options = { params: params };
//     return this.http.get<EnquiryMaster[]>(
//       this.gs_api + 'EnquiryMaster',
//       options
//     );
//   }

//   public getEnquiryMasterUpdateSta(
//     li_id,
//     ls_flag,
//     ls_sta
//   ): Observable<EnquiryMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('li_serid', li_id)
//       .set('ls_status', ls_sta);
//     let options = { params: params };
//     return this.http.get<EnquiryMaster[]>(
//       this.gs_api + 'EnquiryMaster',
//       options
//     );
//   }

//   public getEnquiryMasterById2(li_id, ls_flag): Observable<EnquiryMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('li_serid', li_id);
//     let options = { params: params };
//     return this.http.get<EnquiryMaster[]>(
//       this.gs_api + 'EnquiryMaster',
//       options
//     );
//   }

//   public deleteEnquiryMaster = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'EnquiryMaster', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public deleteEnquiryDet = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'EnquiryDet', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditServiceChats(servicechatss: ServiceChats) {
//     var myPostObject = {
//       id: servicechatss.id,
//       uuid: this.getUuid(),
//       chatdate: servicechatss.ChatDate
//         ? this.datePipe.transform(
//             new Date(servicechatss.ChatDate).toISOString(),
//             'yyyy-MM-dd HH:mm:ss'
//           )
//         : null,
//       serviceid: servicechatss.ServiceId,
//       chattype: servicechatss.ChatType,
//       msg: servicechatss.Msg,
//       userid: servicechatss.UserId,
//       companyid: servicechatss.CompanyId,
//       status: servicechatss.Status,
//     };
//     return this.http
//       .post(this.gs_api + 'ServiceChats', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getServiceChats(ls_flag: any): Observable<ServiceChats[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<ServiceChats[]>(this.gs_api + 'ServiceChats', options);
//   }

//   public getServiceChatsById(
//     ls_flag: any,
//     li_serid
//   ): Observable<ServiceChats[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('li_serid', li_serid);
//     let options = { params: params };
//     return this.http.get<ServiceChats[]>(this.gs_api + 'ServiceChats', options);
//   }

//   public deleteServiceChats = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'ServiceChats', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getApps(ls_flag: any): Observable<Apps[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<Apps[]>(this.gs_api + 'Apps', options);
//   }

//   public deleteApps = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Apps', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditVouhcerGroup(vouhcergroups: VouhcerGroup) {
//     var myPostObject = {
//       id: vouhcergroups.id,
//       uuid: this.getUuid(),
//       groupname: vouhcergroups.GroupName,
//       status: vouhcergroups.Status,
//       companyid: vouhcergroups.CompanyId,
//     };
//     return this.http
//       .post(this.gs_api + 'VouhcerGroup', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getVouhcerGroup(ls_flag: any): Observable<VouhcerGroup[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<VouhcerGroup[]>(this.gs_api + 'VouhcerGroup', options);
//   }

//   public deleteVouhcerGroup = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'VouhcerGroup', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditBPLocation(bplocations: BPLocation) {
//     var myPostObject = {
//       id: bplocations.id,
//       uuid: this.getUuid(),
//       locationname: bplocations.LocationName,
//       status: bplocations.Status,
//       companyid: bplocations.CompanyId,
//     };
//     return this.http
//       .post(this.gs_api + 'BPLocation', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getBPLocation(ls_flag: any): Observable<BPLocation[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<BPLocation[]>(this.gs_api + 'BPLocation', options);
//   }

//   public deleteBPLocation = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'BPLocation', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getItemLocation(ls_flag: any): Observable<ItemLocation[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<ItemLocation[]>(this.gs_api + 'ItemLocation', options);
//   }

//   public deleteItemLocation = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'ItemLocation', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditBarcodePrint(data, ls_genbarcode) {
//     for (var i = 0; i < data.length; i++) {
//       data[i].uuid = this.getUuid();
//       data[i].GenerateBarcode = ls_genbarcode;
//     }

//     return this.http
//       .post(this.gs_api + 'BarcodePrint', data)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public addEditBPBarcodePrint(bpbarcodeprints: BPBarcodePrint) {
//     var myPostObject = {
//       id: bpbarcodeprints.id,
//       uuid: this.getUuid(),
//       barcode: bpbarcodeprints.Barcode,
//       description: bpbarcodeprints.Description,
//       price: bpbarcodeprints.Price,
//       wasprice: bpbarcodeprints.WasPrice,
//       bplocationid: bpbarcodeprints.BPLocationId,
//       filetypeid: bpbarcodeprints.FileTypeId,
//       companyid: bpbarcodeprints.CompanyId,
//       status: bpbarcodeprints.Status,
//     };
//     return this.http
//       .post(this.gs_api + 'BPBarcodePrint', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public addEditBPFileType(bpfiletypes: BPFileType) {
//     var myPostObject = {
//       id: bpfiletypes.id,
//       uuid: this.getUuid(),
//       filetype: bpfiletypes.FileType,
//       filetypevalue: bpfiletypes.FileTypeValue,
//       companyid: bpfiletypes.CompanyId,
//       status: bpfiletypes.Status,
//     };
//     return this.http
//       .post(this.gs_api + 'BPFileType', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getBPFileType(ls_flag: any): Observable<BPFileType[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<BPFileType[]>(this.gs_api + 'BPFileType', options);
//   }

//   public deleteBPFileType = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'BPFileType', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getBPBarcodePrint(
//     ls_flag: any,
//     ls_locationid: any,
//     ls_fileTypeId: any
//   ): Observable<BPBarcodePrint[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_locationid', ls_locationid)
//       .set('ls_fileTypeId', ls_fileTypeId);
//     let options = { params: params };
//     return this.http.get<BPBarcodePrint[]>(
//       this.gs_api + 'BPBarcodePrint',
//       options
//     );
//   }

//   public deleteBPBarcodePrint = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'BPBarcodePrint', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getSplitMops(
//     ls_flag: any,
//     ls_acctye: any,
//     li_id: any
//   ): Observable<any[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_acctye', ls_acctye)
//       .set('li_id', li_id);
//     let options = { params: params };
//     return this.http.get<any[]>(this.gs_api + 'VouchersDet', options);
//   }

//   public addEditMobileRights(data) {
//     for (var i = 0; i < data.length; i++) {
//       data[i].uuid = this.getUuid();
//     }

//     return this.http
//       .post(this.gs_api + 'MobileRights', data)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public addEditReportRights(data) {
//     for (var i = 0; i < data.length; i++) {
//       data[i].uuid = this.getUuid();
//     }

//     return this.http
//       .post(this.gs_api + 'ReportMenuMaster', data)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getMobileRights(li_userid: any): Observable<MobileRights[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', 'S')
//       .set('li_userid', li_userid);
//     let options = { params: params };
//     return this.http.get<MobileRights[]>(this.gs_api + 'MobileRights', options);
//   }

//   public deleteMobileRights = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'MobileRights', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditMobileMenu(mobilemenus: MobileMenu) {
//     var myPostObject = {
//       id: mobilemenus.id,
//       uuid: this.getUuid(),
//       menunameen: mobilemenus.MenuNameEn,
//       menunamear: mobilemenus.MenuNameAr,
//       assetimagename: mobilemenus.AssetImageName,
//       status: mobilemenus.Status,
//       euid: mobilemenus.euid,
//     };
//     return this.http
//       .post(this.gs_api + 'MobileMenu', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getMobileMenu(ls_flag: any): Observable<MobileMenu[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<MobileMenu[]>(this.gs_api + 'MobileMenu', options);
//   }

//   public deleteMobileMenu = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'MobileMenu', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditHosBilling(hosbillings: HosBilling, splitData) {
//     var myPostObject = {
//       id: hosbillings.id,
//       uuid: this.getUuid(),
//       billno: hosbillings.BillNo,
//       billnoseq: hosbillings.BillNoSeq,
//       billdate: hosbillings.BillDate
//         ? this.datePipe.transform(
//             new Date(hosbillings.BillDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       admissionid: hosbillings.AdmissionId,
//       bedid: hosbillings.BedId,
//       mealid: hosbillings.MealId,
//       feetype: hosbillings.FeeType,
//       formonth: hosbillings.ForMonth
//         ? this.datePipe.transform(
//             new Date(hosbillings.ForMonth).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       dayfrom: hosbillings.DayFrom
//         ? this.datePipe.transform(
//             new Date(hosbillings.DayFrom).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       noofdays: hosbillings.NoOfDays,
//       mopid: hosbillings.MopId,
//       amount: hosbillings.Amount,
//       description: hosbillings.Description,
//       roomrate: hosbillings.RoomRate,
//       roomcgst: hosbillings.RoomCGST,
//       roomsgst: hosbillings.RoomSGST,
//       toomtotal: hosbillings.ToomTotal,
//       foodrate: hosbillings.FoodRate,
//       foodcgst: hosbillings.FoodCGST,
//       foodsgst: hosbillings.FoodSGST,
//       foodtotal: hosbillings.FoodTotal,
//       status: hosbillings.Status,
//       voucherid: hosbillings.VoucherId,
//       cashvoucherid: hosbillings.CashVoucherId,
//       splitmop: hosbillings.SplitMop,
//       splitmopdet: splitData,
//       roomtrent:hosbillings.RoomtRent,
//       ispaidrr:hosbillings.IsPaidRR,
//       roomrentpaid:hosbillings.RoomRentPaid,
//       mealsamount:hosbillings.MealsAmount,
//       ispaidma:hosbillings.IsPaidMA,
//       mealsamountpaid:hosbillings.MealsAmountPaid,
//       eleccharge:hosbillings.ElecCharge,
//       ispaidec:hosbillings.IsPaidEC,
//       elecchargepaid:hosbillings.ElecChargePaid,
//       bedcharge:hosbillings.BedCharge,
//       ispaidbc:hosbillings.IsPaidBC,
//       bedchargepaid:hosbillings.BedChargePaid,
//       laundryfee:hosbillings.LaundryFee,
//       ispaidlf:hosbillings.IsPaidLF,
//       laundryfeepaid:hosbillings.LaundryFeePaid,
//       regfee:hosbillings.RegFee,
//       ispaidrf:hosbillings.IsPaidRF,
//       regfeepaid:hosbillings.RegFeePaid,
//       othercharge:hosbillings.OtherCharge,
//       ispaidoc:hosbillings.IsPaidOC,
//       otherchargepaid:hosbillings.OtherChargePaid,
//       ocdescri:hosbillings.OCDescri,

//     };
//     return this.http
//       .post(this.gs_api + 'HosBilling', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getHosBilling(ls_flag: any): Observable<HosBilling[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<HosBilling[]>(this.gs_api + 'HosBilling', options);
//   }

//   public getHosBillingByDate(
//     ls_flag: any,
//     ad_dtfrm,
//     ad_dtto
//   ): Observable<HosBilling[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_datefrm', ad_dtfrm)
//       .set('ls_dateto', ad_dtto);
//     let options = { params: params };
//     return this.http.get<HosBilling[]>(this.gs_api + 'HosBilling', options);
//   }

//   public getHosBillingById(ls_flag: any, ls_euid): Observable<HosBilling[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', ls_euid);
//     let options = { params: params };
//     return this.http.get<HosBilling[]>(this.gs_api + 'HosBilling', options);
//   }

//   public deleteHosBilling = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'HosBilling', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditServiceSpare(servicespares: any, ai_serviceid) {
//     var la_batch: any = [];
//     if (servicespares !== undefined) {
//       servicespares.forEach((item) => {
//         if (item.ItemId !== 0) {
//           la_batch.push({
//             SlNo: item.SlNo,
//             id: item.id,
//             ItemId: item.ItemId,
//             ServiceId: ai_serviceid,
//             SpareDate: item.SpareDate
//               ? this.datePipe.transform(
//                   new Date(item.SpareDate).toISOString(),
//                   'yyyy-MM-dd'
//                 )
//               : null,
//             UnitId: item.UnitId,
//             Qty: item.Qty,
//             Rate: item.PRate,
//             TaxType: item.TaxType,
//             TaxPer: item.TaxPer,
//             Taxable: item.Taxable,
//             TaxAmt: item.TaxAmt,
//             Amount: item.Amount,
//             TagId: item.TagId,
//             Status: 1,
//             uuid: this.getUuid(),
//           });
//         }
//       });
//     }

//     if (la_batch.length == 0) {
//       la_batch.push({
//         SlNo: 1,
//         id: 0,
//         ItemId: 0,
//         ServiceId: ai_serviceid,
//         SpareDate: null,
//         UnitId: null,
//         Qty: null,
//         Rate: null,
//         TaxType: null,
//         TaxPer: null,
//         Taxable: null,
//         TaxAmt: null,
//         Amount: null,
//         TagId: null,
//         Status: null,
//         uuid: this.getUuid(),
//       });
//     }
//     return this.http
//       .post(this.gs_api + 'ServiceSpare', la_batch)
//       .pipe(catchError(this.errorMgmt));
//   }

//   // public openPDFReport(ls_filename) {
//   //   if (this.gs_api.includes('api/')) {
//   //     window.open(
//   //       this.gs_api.replace('api/', 'Reports/') + ls_filename + '.pdf',
//   //       '_blank'
//   //     );
//   //   } else if (this.gs_api.includes('/Api/')) {
//   //     window.open(
//   //       this.gs_api.replace('/Api/', '/Reports/') + ls_filename + '.pdf',
//   //       '_blank'
//   //     );
//   //   } else {
//   //     console.log("gs_api does not contain 'api/' or 'Api/'");
//   //   }

//   //   // window.open(
//   //   //   this.gs_api.replace('/Api/', '/Reports/') + ls_filename + '.pdf',
//   //   //   '_blank'
//   //   // );
//   // }

//   public openPDFReportNew(ls_filename) {
//     if (this.gs_api.includes('api/')) {
//       window.open(
//         this.gs_api.replace('api/', 'Reports/PDF/') + ls_filename,
//         '_blank'
//       );
//     } else if (this.gs_api.includes('/Api/')) {
//       window.open(
//         this.gs_api.replace('/Api/', '/Reports/PDF/') + ls_filename,
//         '_blank'
//       );
//     } else {
//       console.log("gs_api does not contain 'api/' or 'Api/'");
//     }

//     // window.open(
//     //   this.gs_api.replace('/Api/', '/Reports/') + ls_filename + '.pdf',
//     //   '_blank'
//     // );
//   }

//   public downloadExcelFile(ls_filename) {
//     window.open(ls_filename, '_blank');
//   }

//   // public printPDFReport(ls_filename) {
//   //   if (this.gs_api.includes('api/')) {
//   //     pdfToPrinter
//   //       .print(this.gs_api.replace('api/', 'Reports/') + ls_filename)
//   //       .then(() => {
//   //         console.log('PDF printed successfully');
//   //       })
//   //       .catch((error) => {
//   //         console.error('Error printing PDF:', error);
//   //       });

//   //     // window.open(
//   //     //   this.gs_api.replace('api/', 'Reports/') + ls_filename,
//   //     //   '_blank'
//   //     // );
//   //   } else if (this.gs_api.includes('/Api/')) {
//   //     pdfToPrinter
//   //       .print(this.gs_api.replace('/Api/', '/Reports/') + ls_filename)
//   //       .then(() => {
//   //         console.log('PDF printed successfully');
//   //       })
//   //       .catch((error) => {
//   //         console.error('Error printing PDF:', error);
//   //       });

//   //     // window.open(
//   //     //   this.gs_api.replace('/Api/', '/Reports/') + ls_filename,
//   //     //   '_blank'
//   //     // );
//   //   } else {
//   //     console.log("gs_api does not contain 'api/' or 'Api/'");
//   //   }

//   // }

//   public addEditHosRoomDet(hosroomdets: HosRoomDet) {
//     var myPostObject = {
//       id: hosroomdets.id,
//       uuid: this.getUuid(),
//       refid: hosroomdets.RefId,
//       bedno: hosroomdets.BedNo,
//       status: hosroomdets.Status,
//     };
//     return this.http
//       .post(this.gs_api + 'HosRoomDet', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public addEditHosRoomVacate(hosroomvacates: HosRoomVacate, splitData) {
//     var myPostObject = {
//       id: hosroomvacates.id,
//       uuid: this.getUuid(),
//       vacateno: hosroomvacates.VacateNo,
//       vacatenoseq: hosroomvacates.VacateNoSeq,
//       vacatedate: hosroomvacates.VacateDate
//         ? this.datePipe.transform(
//             new Date(hosroomvacates.VacateDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       vacateon: hosroomvacates.VacateOn
//         ? this.datePipe.transform(
//             new Date(hosroomvacates.VacateOn).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       admissionid: hosroomvacates.AdmissionId,
//       bedid: hosroomvacates.BedId,
//       cautiondeposit: hosroomvacates.CautionDeposit,
//       mopid: hosroomvacates.MopId,
//       description: hosroomvacates.Description,
//       voucherid: hosroomvacates.VoucherId,
//       cashvoucherid: hosroomvacates.CashVoucherId,
//       companyid: hosroomvacates.CompanyId,
//       euid: hosroomvacates.euid,
//       status: hosroomvacates.Status,
//       splitmop: hosroomvacates.SplitMop,
//       splitmopdet: splitData,
//     };
//     return this.http
//       .post(this.gs_api + 'HosRoomVacate', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getHosRoomVacateById(
//     ls_flag: any,
//     ls_euid
//   ): Observable<HosRoomVacate[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', ls_euid);
//     let options = { params: params };
//     return this.http.get<HosRoomVacate[]>(
//       this.gs_api + 'HosRoomVacate',
//       options
//     );
//   }

//   public getHosRoomVacate(ls_flag: any): Observable<HosRoomVacate[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<HosRoomVacate[]>(
//       this.gs_api + 'HosRoomVacate',
//       options
//     );
//   }

//   public deleteHosRoomVacate = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'HosRoomVacate', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getHosRoomDet(ls_flag: any): Observable<HosRoomDet[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<HosRoomDet[]>(this.gs_api + 'HosRoomDet', options);
//   }
//   public addEditHosRooms(hosroomssHeader: HosRooms, detdata) {
//     var myPostObject = {
//       id: hosroomssHeader.id,
//       uuid: this.getUuid(),
//       roomname: hosroomssHeader.RoomName,
//       hoscategoryid: hosroomssHeader.HosCategoryId,
//       floor: hosroomssHeader.Floor,
//       taxtypeid: hosroomssHeader.TaxTypeId,
//       cautiondeposit: hosroomssHeader.CautionDeposit,
//       tariff: hosroomssHeader.Tariff,
//       dailytariff: hosroomssHeader.DailyTariff,
//       description: hosroomssHeader.Description,
//       companyid: hosroomssHeader.CompanyId,
//       status: hosroomssHeader.Status,
//       HosRoomDet: detdata,
//     };
//     return this.http
//       .post(this.gs_api + 'HosRooms', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public openPDF(ls_filename) {
//     // window.open(this.gs_api.replace('api/', 'Reports/') + ls_filename + '.pdf', '_blank');

//     window.open(ls_filename, '_blank');
//   }

//   public addEditHosAdmission(
//     hosadmissions: HosAdmission,
//     li_docid: any,
//     splitData
//   ) {
//     var myPostObject = {
//       id: hosadmissions.id,
//       uuid: this.getUuid(),
//       bedid: hosadmissions.BedId,
//       mealid: hosadmissions.MealId,
//       mopid: hosadmissions.Mopid,
//       regno: hosadmissions.RegNo,
//       regnoseq: hosadmissions.RegNoSeq,
//       regdate: hosadmissions.RegDate
//         ? this.datePipe.transform(
//             new Date(hosadmissions.RegDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       joindate: hosadmissions.JoinDate
//         ? this.datePipe.transform(
//             new Date(hosadmissions.JoinDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       name: hosadmissions.Name,
//       mobile: hosadmissions.Mobile,
//       resno: hosadmissions.ResNo,
//       offaddress: hosadmissions.OffAddress,
//       offno: hosadmissions.OffNo,
//       address: hosadmissions.Address,
//       parentname: hosadmissions.ParentName,
//       parentmobile: hosadmissions.ParentMobile,
//       policestation: hosadmissions.PoliceStation,
//       cautiondeposit: hosadmissions.CautionDeposit,
//       paidstatus: hosadmissions.PaidStatus,
//       paidamount: hosadmissions.PaidAmount,
//       accountid: hosadmissions.AccountId,
//       voucherid: hosadmissions.VoucherId,
//       cashvoucherid: hosadmissions.CashVoucherId,
//       companyid: hosadmissions.CompanyId,
//       status: hosadmissions.Status,
//       tempdocid: li_docid,
//       euid: hosadmissions.euid,
//       vacateon: hosadmissions.VacateOn
//         ? this.datePipe.transform(
//             new Date(hosadmissions.VacateOn).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       splitmop: hosadmissions.SplitMop,
//       splitmopdet: splitData,
//     };
//     // console.log(myPostObject)
//     return this.http
//       .post(this.gs_api + 'HosAdmission', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getHosAdmissionById(li_id, ls_flag): Observable<HosAdmission[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', li_id);
//     let options = { params: params };
//     return this.http.get<HosAdmission[]>(this.gs_api + 'HosAdmission', options);
//   }

//   public getHosAdmission(ls_flag: any): Observable<HosAdmission[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<HosAdmission[]>(this.gs_api + 'HosAdmission', options);
//   }

//   public deleteHosAdmission = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'HosAdmission', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getHosRooms(ls_flag: any): Observable<HosRooms[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);

//     let options = { params: params };
//     return this.http.get<HosRooms[]>(this.gs_api + 'HosRooms', options);
//   }

//   public getHosRoomsMonthWise(
//     ls_flag: any,
//     ls_formonth
//   ): Observable<HosRooms[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', ls_formonth);
//     let options = { params: params };
//     return this.http.get<HosRooms[]>(this.gs_api + 'HosRooms', options);
//   }

//   public deleteHosRooms = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'HosRooms', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public deleteHosRoomDet = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'HosRoomDet', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditCrystalDataPDF(
//     creuid,
//     ishandf,
//     rptname,
//     rptheader,
//     para1,
//     para2,
//     para3,
//     para4,
//     para5,
//     isWords,
//     wordsFiledname
//   ) {
//     var myPostObject = {
//       id: 0,
//       uuid: this.getUuid(),
//       euid: creuid,
//       crsql: '',
//       ishandf: ishandf,
//       rptname: rptname,
//       rptheader: rptheader,
//       para1: para1,
//       para2: para2,
//       para3: para3,
//       para4: para4,
//       para5: para5,
//       isWords: isWords,
//       wordsFiledname: wordsFiledname,
//     };
//     // console.log(myPostObject);
//     return this.http
//       .post(this.gs_api + 'CrystalDataPDF', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public addEditServiceInvoice(
//     serviceinvoices: ServiceInvoice,
//     splitData,
//     servicespares: any,
//     servicelabours: any
//   ) {
//     var la_batch: any = [];
//     if (servicespares !== undefined) {
//       servicespares.forEach((item) => {
//         if (item.ItemId !== 0) {
//           la_batch.push({
//             SlNo: item.SlNo,
//             id: item.id,
//             ItemId: item.ItemId,
//             ServiceId: serviceinvoices.ServiceId,
//             SpareDate: item.SpareDate
//               ? this.datePipe.transform(
//                   new Date(item.SpareDate).toISOString(),
//                   'yyyy-MM-dd'
//                 )
//               : null,
//             UnitId: item.UnitId,
//             Qty: item.Qty,
//             Rate: item.PRate,
//             TaxType: item.TaxType,
//             TaxPer: item.TaxPer,
//             Taxable: item.Taxable,
//             TaxAmt: item.TaxAmt,
//             Amount: item.Amount,
//             TagId: item.TagId,
//             Status: 1,
//             uuid: this.getUuid(),
//           });
//         }
//       });
//     }

//     if (la_batch.length == 0) {
//       la_batch.push({
//         SlNo: 1,
//         id: 0,
//         ItemId: 0,
//         ServiceId: serviceinvoices.ServiceId,
//         SpareDate: null,
//         UnitId: null,
//         Qty: null,
//         Rate: null,
//         TaxType: null,
//         TaxPer: null,
//         Taxable: null,
//         TaxAmt: null,
//         Amount: null,
//         TagId: null,
//         Status: null,
//         uuid: this.getUuid(),
//       });
//     }

//     var la_batch2: any = [];
//     if (servicelabours !== undefined) {
//       servicelabours.forEach((item) => {
//         if (item.ItemId !== 0) {
//           la_batch2.push({
//             SlNo: item.SlNo,
//             id: item.id,
//             ItemId: item.ItemId,
//             ServiceId: serviceinvoices.ServiceId,
//             LabourDate: item.LabourDate
//               ? this.datePipe.transform(
//                   new Date(item.LabourDate).toISOString(),
//                   'yyyy-MM-dd'
//                 )
//               : null,
//             UnitId: item.UnitId,
//             Qty: item.Qty,
//             Rate: item.PRate,
//             TaxType: item.TaxType,
//             TaxPer: item.TaxPer,
//             Taxable: item.Taxable,
//             TaxAmt: item.TaxAmt,
//             Amount: item.Amount,
//             Status: 1,
//             uuid: this.getUuid(),
//           });
//         }
//       });
//     }

//     if (la_batch2.length == 0) {
//       la_batch2.push({
//         SlNo: 1,
//         id: 0,
//         ItemId: 0,
//         ServiceId: serviceinvoices.ServiceId,
//         LabourDate: null,
//         UnitId: null,
//         Qty: null,
//         Rate: null,
//         TaxType: null,
//         TaxPer: null,
//         Taxable: null,
//         TaxAmt: null,
//         Amount: null,
//         Status: null,
//         uuid: this.getUuid(),
//       });
//     }

//     if (serviceinvoices.InvoiceTime !== null) {
//       var dt = new Date('1990-01-01 ' + serviceinvoices.InvoiceTime);
//       dt = new Date(
//         new Date().toISOString().slice(0, 10) +
//           ' ' +
//           serviceinvoices.InvoiceTime
//       );
//     }

//     var myPostObject = {
//       id: serviceinvoices.id,
//       uuid: this.getUuid(),
//       invoicedate: serviceinvoices.InvoiceDate
//         ? this.datePipe.transform(
//             new Date(serviceinvoices.InvoiceDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       invoicetime: dt
//         ? this.datePipe.transform(dt, 'yyyy-MM-dd h:mm:ss a')
//         : null,
//       serviceid: serviceinvoices.ServiceId,
//       invoiceno: serviceinvoices.InvoiceNo,
//       invoicenoseq: serviceinvoices.InvoiceNoSeq,
//       mopid: serviceinvoices.MopId,
//       estimatestatus: serviceinvoices.EstimateStatus,
//       estimateamount: serviceinvoices.EstimateAmount,
//       sparestatus: serviceinvoices.SpareStatus,
//       sparetotal: serviceinvoices.SpareTotal,
//       servicetotal: serviceinvoices.ServiceTotal,
//       grossamt: serviceinvoices.GrossAmt,
//       taxableamt: serviceinvoices.TaxableAmt,
//       taxamt: serviceinvoices.TaxAmt,
//       nontaxamt: serviceinvoices.NonTaxAmt,
//       discountper: serviceinvoices.DiscountPer,
//       discountamt: serviceinvoices.DiscountAmt,
//       grand: serviceinvoices.Grand,
//       totaladvance: serviceinvoices.TotalAdvance,
//       paidstatus: serviceinvoices.PaidStatus,
//       paidamount: serviceinvoices.PaidAmount,
//       balance: serviceinvoices.Balance,
//       autoroundoff: serviceinvoices.AutoRoundOff,
//       roundoff: serviceinvoices.RoundOff,
//       voucherid: serviceinvoices.VoucherId,
//       cashvoucherid: serviceinvoices.CashVoucherId,
//       expvoucherid: serviceinvoices.ExpVoucherId,
//       currencyid: serviceinvoices.CurrencyId,
//       assignedto: serviceinvoices.AssignedTo,
//       servicestatusid: serviceinvoices.ServiceStatusId,
//       customerstatusid: serviceinvoices.CustomerStatusId,
//       closestatus: serviceinvoices.CloseStatus,
//       companyid: serviceinvoices.CompanyId,
//       status: serviceinvoices.Status,
//       euid: serviceinvoices.euid,
//       netservice: serviceinvoices.NetService,
//       splitmop: serviceinvoices.SplitMop,
//       itemin: serviceinvoices.ItemIn,
//       splitmopdet: splitData,
//       servicespare: la_batch,
//       servicelabour: la_batch2,
//       closestatustype: serviceinvoices.CloseStatusType,
//       whatsapp: serviceinvoices.WhatsApp,
//       print: serviceinvoices.Print,
//       deliverydate: serviceinvoices.DeliveryDate
//         ? this.datePipe.transform(
//             new Date(serviceinvoices.DeliveryDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       servicereport: serviceinvoices.ServiceReport,
//       servicesecondreport: serviceinvoices.ServiceSecondReport,
//     };
//     // console.log(myPostObject)

//     return this.http
//       .post(this.gs_api + 'ServiceInvoice', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getServiceInvoice(ls_flag: any): Observable<ServiceInvoice[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<ServiceInvoice[]>(
//       this.gs_api + 'ServiceInvoice',
//       options
//     );
//   }

//   public getServiceInvoiceByDate(
//     ls_flag: any,
//     ad_dtfrm,
//     ad_dtto
//   ): Observable<ServiceInvoice[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_datefrm', ad_dtfrm)
//       .set('ls_dateto', ad_dtto);
//     let options = { params: params };
//     return this.http.get<ServiceInvoice[]>(
//       this.gs_api + 'ServiceInvoice',
//       options
//     );
//   }

//   public getSearchByDate(
//     ls_flag: any,
//     ad_dtfrm,
//     ad_dtto
//   ): Observable<ServiceInvoice[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_datefrm', ad_dtfrm)
//       .set('ls_dateto', ad_dtto);
//     let options = { params: params };
//     return this.http.get<ServiceInvoice[]>(this.gs_api + 'Search', options);
//   }

//   public deleteServiceInvoice = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'ServiceInvoice', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getServiceSpare(ls_flag: any): Observable<ServiceSpare[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<ServiceSpare[]>(this.gs_api + 'ServiceSpare', options);
//   }

//   public addEditServiceLabour(servicelabours: any, ai_serviceid) {
//     var la_batch: any = [];
//     if (servicelabours !== undefined) {
//       servicelabours.forEach((item) => {
//         if (item.ItemId !== 0) {
//           la_batch.push({
//             SlNo: item.SlNo,
//             id: item.id,
//             ItemId: item.ItemId,
//             ServiceId: ai_serviceid,
//             LabourDate: item.LabourDate
//               ? this.datePipe.transform(
//                   new Date(item.LabourDate).toISOString(),
//                   'yyyy-MM-dd'
//                 )
//               : null,
//             UnitId: item.UnitId,
//             Qty: item.Qty,
//             Rate: item.PRate,
//             TaxType: item.TaxType,
//             TaxPer: item.TaxPer,
//             Taxable: item.Taxable,
//             TaxAmt: item.TaxAmt,
//             Amount: item.Amount,
//             Status: 1,
//             uuid: this.getUuid(),
//           });
//         }
//       });
//     }

//     if (la_batch.length == 0) {
//       la_batch.push({
//         SlNo: 1,
//         id: 0,
//         ItemId: 0,
//         ServiceId: ai_serviceid,
//         LabourDate: null,
//         UnitId: null,
//         Qty: null,
//         Rate: null,
//         TaxType: null,
//         TaxPer: null,
//         Taxable: null,
//         TaxAmt: null,
//         Amount: null,
//         Status: null,
//         uuid: this.getUuid(),
//       });
//     }

//     return this.http
//       .post(this.gs_api + 'ServiceLabour', la_batch)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getServiceLabour(ls_flag: any): Observable<ServiceLabour[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<ServiceLabour[]>(
//       this.gs_api + 'ServiceLabour',
//       options
//     );
//   }

//   public deleteServiceLabour = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'ServiceLabour', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditVouchers(voucherss: Vouchers, splitData, tagData) {
//     if (tagData == undefined) {
//       tagData = [];
//     } else {
//       if (tagData.length > 0) {
//         tagData.forEach((entry) => {
//           entry.Balance = entry.Bal;
//         });
//       }
//     }
//     // console.log(tagData)

//     var myPostObject = {
//       id: voucherss.id,
//       uuid: this.getUuid(),
//       voucherdate: voucherss.VoucherDate
//         ? this.datePipe.transform(
//             new Date(voucherss.VoucherDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       vouchertype: voucherss.VoucherType,
//       acctype: voucherss.AccType,
//       accgroup: voucherss.AccGroup,
//       refid: voucherss.RefId,
//       voucherno: voucherss.VoucherNo,
//       vouchernoseq: voucherss.VoucherNoSeq,
//       mopid: voucherss.MOPId,
//       accountid: voucherss.AccountId,
//       naration: voucherss.Naration,
//       receive: voucherss.Receive,
//       pay: voucherss.Pay,
//       companyid: voucherss.CompanyId,
//       status: voucherss.Status,
//       splitmop: voucherss.SplitMop,
//       vouchergroupid: voucherss.VoucherGroupId,
//       splitmopdet: splitData,
//       tagDatadet: tagData,
//     };
//     return this.http
//       .post(this.gs_api + 'Vouchers', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getVouchers(ls_flag: any): Observable<Vouchers[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<Vouchers[]>(this.gs_api + 'Vouchers', options);
//   }

//   public getVouchersById(
//     ls_flag: any,
//     ls_accgroup,
//     li_refid
//   ): Observable<Vouchers[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_accgroup', ls_accgroup)
//       .set('li_refid', li_refid);
//     let options = { params: params };
//     return this.http.get<Vouchers[]>(this.gs_api + 'Vouchers', options);
//   }

//   public getVouchersForPrint(ls_flag: any, li_euid): Observable<Vouchers[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', li_euid);
//     let options = { params: params };
//     return this.http.get<Vouchers[]>(this.gs_api + 'Vouchers', options);
//   }

//   public deleteVouchers = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Vouchers', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getServiceSpareById(
//     ls_flag: any,
//     li_serviceid
//   ): Observable<ServiceSpare[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('li_serviceid', li_serviceid);
//     let options = { params: params };
//     return this.http.get<ServiceSpare[]>(this.gs_api + 'ServiceSpare', options);
//   }

//   public deleteServiceSpare = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'ServiceSpare', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditPhysicalConditions(physicalconditionss: PhysicalConditions) {
//     var myPostObject = {
//       id: physicalconditionss.id,
//       uuid: this.getUuid(),
//       physicalcondition: physicalconditionss.PhysicalCondition,
//       companyid: physicalconditionss.CompanyId,
//       status: physicalconditionss.Status,
//     };
//     return this.http
//       .post(this.gs_api + 'PhysicalConditions', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getPhysicalConditions(ls_flag: any): Observable<PhysicalConditions[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<PhysicalConditions[]>(
//       this.gs_api + 'PhysicalConditions',
//       options
//     );
//   }

//   public addEditRiskByCustomer(riskbycustomers: RiskByCustomer) {
//     var myPostObject = {
//       id: riskbycustomers.id,
//       uuid: this.getUuid(),
//       riskbycustomers: riskbycustomers.RiskByCustomer,
//       companyid: riskbycustomers.CompanyId,
//       status: riskbycustomers.Status,
//     };
//     return this.http
//       .post(this.gs_api + 'RiskByCustomer', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getRiskByCustomer(ls_flag: any): Observable<RiskByCustomer[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<RiskByCustomer[]>(
//       this.gs_api + 'RiskByCustomer',
//       options
//     );
//   }

//   public deleteRiskByCustomer = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'RiskByCustomer', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public deletePhysicalConditions = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'PhysicalConditions', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditItemBatch(itembatchs: ItemBatch) {
//     var myPostObject = {
//       id: itembatchs.id,
//       uuid: this.getUuid(),
//       seqno: itembatchs.SeqNo,
//       itemid: itembatchs.ItemId,
//       barcode: itembatchs.Barcode,
//       expdate: itembatchs.ExpDate
//         ? this.datePipe.transform(
//             new Date(itembatchs.ExpDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       col1: itembatchs.Col1,
//       col2: itembatchs.Col2,
//       qty: itembatchs.Qty,
//       status: itembatchs.Status,
//       companyid: itembatchs.CompanyId,
//     };
//     return this.http
//       .post(this.gs_api + 'ItemBatch', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getItemBatch(ls_flag: any): Observable<ItemBatch[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<ItemBatch[]>(this.gs_api + 'ItemBatch', options);
//   }

//   public deleteItemBatch = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'ItemBatch', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditState(states: State) {
//     var myPostObject = {
//       id: states.id,
//       uuid: this.getUuid(),
//       statename: states.StateName,
//       statecode: states.StateCode,
//       code: states.code,
//       status: states.Status,
//       craeteddt: states.craeteddt
//         ? this.datePipe.transform(
//             new Date(states.craeteddt).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//     };
//     return this.http
//       .post(this.gs_api + 'State', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getState(ls_flag: any): Observable<State[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<State[]>(this.gs_api + 'State', options);
//   }

//   public deleteState = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'State', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditUnitRatio(unitratios: UnitRatio) {
//     var myPostObject = {
//       id: unitratios.id,
//       uuid: this.getUuid(),
//       mainunitid: unitratios.MainUnitId,
//       subunitid: unitratios.SubUnitId,
//       ratio: unitratios.Ratio,
//       companyid: unitratios.CompanyId,
//       status: unitratios.Status,
//     };
//     return this.http
//       .post(this.gs_api + 'UnitRatio', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public addEditItemSerail(itemserails: ItemSerail) {
//     var myPostObject = {
//       id: itemserails.id,
//       uuid: this.getUuid(),
//       itemid: itemserails.ItemId,
//       seqno: itemserails.SeqNo,
//       serailno: itemserails.SerailNo,
//       companyid: itemserails.CompanyId,
//       status: itemserails.Status,
//     };
//     return this.http
//       .post(this.gs_api + 'ItemSerail', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getItemSerail(ls_flag: any): Observable<ItemSerail[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<ItemSerail[]>(this.gs_api + 'ItemSerail', options);
//   }

//   public deleteItemSerail = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'ItemSerail', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getUnitRatio(ls_flag: any): Observable<UnitRatio[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<UnitRatio[]>(this.gs_api + 'UnitRatio', options);
//   }

//   public getUnitRatioById(ls_flag: any, li_id): Observable<UnitRatio[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('li_id', li_id);
//     let options = { params: params };
//     return this.http.get<UnitRatio[]>(this.gs_api + 'UnitRatio', options);
//   }

//   public getUnitRatioUnitWise(
//     ls_flag: any,
//     li_mainUnitId,
//     li_subUnitid
//   ): Observable<UnitRatio[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('li_mainUnitId', li_mainUnitId)
//       .set('li_subUnitid', li_subUnitid);
//     let options = { params: params };
//     return this.http.get<UnitRatio[]>(this.gs_api + 'UnitRatio', options);
//   }

//   public deleteUnitRatio = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'UnitRatio', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditAccBook(accbooks: AccBook) {
//     var myPostObject = {
//       id: accbooks.id,
//       uuid: this.getUuid(),
//       voucherid: accbooks.VoucherId,
//       voucherno: accbooks.VoucherNo,
//       vouchernoseq: accbooks.VoucherNoSeq,
//       voucherflag: accbooks.VoucherFlag,
//       serialno: accbooks.SerialNo,
//       accdate: accbooks.AccDate
//         ? this.datePipe.transform(
//             new Date(accbooks.AccDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       acctype: accbooks.AccType,
//       refid: accbooks.RefId,
//       accountid: accbooks.AccountId,
//       cusid: accbooks.CusId,
//       naration: accbooks.Naration,
//       dorc: accbooks.DorC,
//       debit: accbooks.Debit,
//       credit: accbooks.Credit,
//       signedamount: accbooks.SignedAmount,
//       chequeno: accbooks.ChequeNo,
//       chequedate: accbooks.ChequeDate
//         ? this.datePipe.transform(
//             new Date(accbooks.ChequeDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       chequestatus: accbooks.ChequeStatus,
//       banknotes: accbooks.BankNotes,
//       companyid: accbooks.CompanyId,
//       status: accbooks.Status,
//     };
//     return this.http
//       .post(this.gs_api + 'AccBook', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getAccBook(ls_flag: any): Observable<AccBook[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<AccBook[]>(this.gs_api + 'AccBook', options);
//   }

//   public deleteAccBook = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'AccBook', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditItem(items: Item, detSerail: any, detBatch: any[]) {
//     var la_batch: any = [];

//     if (detSerail == undefined) {
//       detSerail = [];
//     }
//     if (detBatch !== undefined) {
//       detBatch.forEach((item) => {
//         if (item.BatchNo !== '') {
//           la_batch.push({
//             SlNo: item.SlNo,
//             id: item.id,
//             BatchNo: item.BatchNo,
//             ExpDate1: item.ExpDate
//               ? this.datePipe.transform(
//                   new Date(item.ExpDate).toISOString(),
//                   'yyyy-MM-dd'
//                 )
//               : null,
//             Col1: item.Col1,
//             Col2: item.Col2,
//             OPStock: item.OPStock,
//             ItemId: item.ItemId,
//             BatchFrom: 'Opening',
//           });
//         }
//       });
//     } else {
//       detBatch = [];
//     }

//     var myPostObject = {
//       id: items.id,
//       uuid: this.getUuid(),
//       groupaccid: items.GroupAccId,
//       itemwise: items.ItemWise,
//       taxtypeid: items.TaxTypeId,
//       unitid: items.UnitId,
//       unitratioid: items.UnitRatioId,
//       itemname: items.ItemName,
//       itemcode: items.ItemCode,
//       barcode: items.Barcode,
//       itemcodeseq: items.ItemCodeSeq,
//       categoryname: items.CategoryName,
//       brand: items.Brand,
//       prate: items.PRate,
//       taxincpur: items.TaxIncPur,
//       srate: items.SRate,
//       taxincsale: items.TaxIncSale,
//       wsrate: items.WSRate,
//       taxincwsrate: items.TaxIncWSRate,
//       mrp: items.MRP,
//       opstock: items.OPStock,
//       opdate2: items.OPDate
//         ? this.datePipe.transform(
//             new Date(items.OPDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       oprate: items.OPRate,
//       onlinerate: items.OnlineRate,
//       taxinconlinerate: items.TaxIncOnlineRate,
//       reorder: items.ReOrder,
//       rack: items.Rack,
//       hsncode: items.HSNCode,
//       orderby: items.OrderBy,
//       serviceprice: items.ServicePrice,
//       notes: items.Notes,
//       description: items.Description,
//       itemtype: items.ItemType ? 'Service' : 'Product',
//       companyid: items.CompanyId,
//       opstockid: items.OPStockId,
//       status: items.Status,
//       euid: items.euid,
//       SerailDet: detSerail,
//       BatchDet: la_batch,
//     };
//     return this.http
//       .post(this.gs_api + 'Item', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getItem(ls_flag: any): Observable<Item[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<Item[]>(this.gs_api + 'Item', options);
//   }

//   public getItemById(ls_flag, li_id): Observable<Item[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', li_id);
//     let options = { params: params };
//     return this.http.get<Item[]>(this.gs_api + 'Item', options);
//   }

//   public getAppsPriceById(ls_flag, li_id): Observable<any[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', li_id);
//     let options = { params: params };
//     // console.log(options)
//     return this.http.get<any[]>(this.gs_api + 'Item', options);
//   }

//   public deleteItem = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Item', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditTaxType(taxtypes: TaxType) {
//     var myPostObject = {
//       id: taxtypes.id,
//       uuid: this.getUuid(),
//       taxtypename: taxtypes.TaxTypeName,
//       taxname: taxtypes.TaxName,
//       taxper: taxtypes.TaxPer,
//       cgstper: taxtypes.CGSTPer,
//       sgstper: taxtypes.SGSTPer,
//       igstper: taxtypes.IGSTPer,
//       companyid: taxtypes.CompanyId,
//       status: taxtypes.Status,
//     };
//     return this.http
//       .post(this.gs_api + 'TaxType', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getTaxType(ls_flag: any): Observable<TaxType[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<TaxType[]>(this.gs_api + 'TaxType', options);
//   }

//   public deleteTaxType = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'TaxType', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditDefMOP(defmops: DefMOP) {
//     var myPostObject = {
//       id: defmops.id,
//       uuid: this.getUuid(),
//       mopname: defmops.MOPName,
//       accountid: defmops.AccountId,
//       companyid: defmops.CompanyId,
//       status: defmops.Status,
//     };
//     return this.http
//       .post(this.gs_api + 'DefMOP', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getDefMOP(ls_flag: any): Observable<DefMOP[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<DefMOP[]>(this.gs_api + 'DefMOP', options);
//   }

//   public deleteDefMOP = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'DefMOP', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditWAUsers(wauserss: WAUsers) {
//     var myPostObject = {
//       id: wauserss.id,
//       uuid: this.getUuid(),
//       waid: wauserss.WAID,
//       clientname: wauserss.ClientName,
//       msgcount: wauserss.MsgCount,
//       status: wauserss.Status,
//     };
//     return this.http
//       .post(this.gs_api + 'WAUsers', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getWAUsers(ls_flag: any): Observable<WAUsers[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<WAUsers[]>(this.gs_api + 'WAUsers', options);
//   }

//   public getWAUsersQR(ls_flag: any, ls_id: any): Observable<WAUsers[]> {
//     const params = new HttpParams()
//       .set('ls_token', ls_id)
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<WAUsers[]>(this.gs_api + 'WAUsers', options);
//   }

//   public deleteWAUsers = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'WAUsers', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditServiceHistory(servicehistorys: ServiceHistory) {
//     if (servicehistorys.EntryTime !== null) {
//       var dt = new Date('1990-01-01 ' + servicehistorys.EntryTime);
//       dt = new Date(
//         new Date().toISOString().slice(0, 10) + ' ' + servicehistorys.EntryTime
//       );
//     }

//     var myPostObject = {
//       id: servicehistorys.id,
//       uuid: this.getUuid(),
//       serviceid: servicehistorys.ServiceId,
//       servicedate: servicehistorys.ServiceDate
//         ? this.datePipe.transform(
//             new Date(servicehistorys.ServiceDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       itemin: servicehistorys.ItemIn,
//       customerid: servicehistorys.CustomerId,
//       userid: servicehistorys.Userid,
//       servicestatusid: servicehistorys.ServiceStatusId,
//       changecustomerstatus: servicehistorys.ChangeCustomerStatus,
//       customerstatusid: servicehistorys.CustomerStatusId,
//       notes: servicehistorys.Notes,
//       naration: servicehistorys.aNotes,
//       estimatestatus: servicehistorys.EstimateStatus,
//       estimatedprice: parseNumber(servicehistorys.EstimatedPrice),
//       couriername: servicehistorys.CourierName,
//       couriernumber: servicehistorys.CourierNumber,
//       status: !servicehistorys.Status,
//       whatsapp: servicehistorys.WhatsApp,
//       locationid: servicehistorys.LocationId,

//       entrytime: dt
//         ? this.datePipe.transform(dt, 'yyyy-MM-dd h:mm:ss a')
//         : null,
//       closestatustype: servicehistorys.CloseStatusType,
//       euid: servicehistorys.euid,
//     };
//     // console.log(myPostObject);

//     return this.http
//       .post(this.gs_api + 'ServiceHistory', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getServiceHistory(ls_flag: any): Observable<ServiceHistory[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<ServiceHistory[]>(
//       this.gs_api + 'ServiceHistory',
//       options
//     );
//   }

//   public getServiceHistoryById(
//     ls_flag: any,
//     li_serId: any
//   ): Observable<ServiceHistory[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_serviceid', li_serId);
//     let options = { params: params };
//     return this.http.get<ServiceHistory[]>(
//       this.gs_api + 'ServiceHistory',
//       options
//     );
//   }

//   public getItembyTagItems(
//     ls_flag: any,
//     li_itemid: any,
//     li_cusid: any
//   ): Observable<any[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('li_itemid', li_itemid)
//       .set('li_cusid', li_cusid);
//     let options = { params: params };
//     return this.http.get<any[]>(this.gs_api + 'Item', options);
//   }

//   public addEditReportMenuMaster(
//     reportmenumastersHeader: ReportMenuMaster,
//     reportmenumastersFooter: ReportMenuMaster,
//     detdata
//   ) {
//     var myPostObject = {
//       id: reportmenumastersHeader.id,
//       uuid: this.getUuid(),
//       reportname: reportmenumastersHeader.ReportName,
//       orderby: reportmenumastersHeader.OrderBy,
//       iconname: reportmenumastersHeader.IconName,
//       status: reportmenumastersHeader.Status,
//       ReportMenuDet: detdata,
//     };
//     return this.http
//       .post(this.gs_api + 'ReportMenuMaster', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getReportMenuMaster(ls_flag: any): Observable<ReportMenuMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<ReportMenuMaster[]>(
//       this.gs_api + 'ReportMenuMaster',
//       options
//     );
//   }

//   public updateFav(ls_flag: any, li_id: any): Observable<ReportMenuMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('li_id', li_id);
//     let options = { params: params };
//     return this.http.get<ReportMenuMaster[]>(
//       this.gs_api + 'ReportMenuMaster',
//       options
//     );
//   }

//   public getReportRights(
//     ls_flag: any,
//     li_id: any
//   ): Observable<ReportMenuMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('li_id', li_id);
//     let options = { params: params };
//     return this.http.get<ReportMenuMaster[]>(
//       this.gs_api + 'ReportMenuMaster',
//       options
//     );
//   }

//   public getReportMenuMasterById(
//     li_id,
//     ls_flag
//   ): Observable<ReportMenuMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', li_id);
//     let options = { params: params };
//     return this.http.get<ReportMenuMaster[]>(
//       this.gs_api + 'ReportMenuMaster',
//       options
//     );
//   }

//   public deleteReportMenuMaster = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'ReportMenuMaster', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public deleteServiceHistory = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'ServiceHistory', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditServiceMaster(
//     servicemastersHeader: ServiceMaster,
//     servicemastersFooter: ServiceMaster,
//     detdata
//   ) {
//     var myPostObject = {
//       id: servicemastersHeader.id,
//       uuid: this.getUuid(),
//       servicedate: servicemastersHeader.ServiceDate
//         ? this.datePipe.transform(
//             new Date(servicemastersHeader.ServiceDate).toISOString(),
//             'yyyy-MM-dd h:mm:ss a'
//           )
//         : null,
//       serviceno: servicemastersHeader.ServiceNo,
//       servicenoseq: servicemastersHeader.ServiceNoSeq,
//       servicetype: servicemastersHeader.ServiceType,
//       warentytype: servicemastersHeader.WarentyType,
//       cusid: servicemastersHeader.CusId,
//       itemname: servicemastersHeader.ItemName,
//       serialnumber: servicemastersHeader.SerialNumber,
//       model: servicemastersHeader.Model,
//       brand: servicemastersHeader.Brand,
//       servicepriority: servicemastersHeader.ServicePriority,
//       complaint: servicemastersHeader.Complaint,
//       notes: servicemastersHeader.Notes,
//       estimatedprice: servicemastersHeader.EstimatedPrice,
//       estimateddate: servicemastersHeader.EstimatedDate
//         ? this.datePipe.transform(
//             new Date(servicemastersHeader.EstimatedDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       assignedto: servicemastersHeader.AssignedTo,
//       servicestatusid: servicemastersHeader.ServiceStatusId,
//       customerstatusid: servicemastersHeader.CustomerStatusId,
//       euid: servicemastersHeader.euid,
//       location: servicemastersHeader.Location,
//       devicepwd: servicemastersHeader.DevicePwd,
//       riskids: servicemastersHeader.RiskIds.toString(),
//       conditionids: servicemastersHeader.ConditionIds.toString(),
//       mopid: servicemastersHeader.MOPId,
//       advance: servicemastersHeader.Advance,
//       voucherid: servicemastersHeader.VoucherId,
//       cashvoucherid: servicemastersHeader.CashVoucherId,
//       sendwhatsapp: servicemastersHeader.SendWhatsApp,
//       print: servicemastersHeader.Print,
//       printlabel: servicemastersHeader.PrintLabel,
//       estimatestatus: servicemastersHeader.EstimateStatus,
//       quicksale: servicemastersHeader.QuickSale,
//       cusname: servicemastersHeader.CusName,
//       cusaddress: servicemastersHeader.CusAddress,
//       couriername: servicemastersHeader.CourierName,
//       couriernumber: servicemastersHeader.CourierNumber,
//       meterreading: servicemastersHeader.MeterReading,
//       chasisnumber: servicemastersHeader.ChasisNumber,
//       locationid: servicemastersHeader.LocationId,
//       ServiceDet: detdata,
//     };
//     return this.http
//       .post(this.gs_api + 'ServiceMaster', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getServiceMaster(ls_flag): Observable<ServiceMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<ServiceMaster[]>(
//       this.gs_api + 'ServiceMaster',
//       options
//     );
//   }
//   public deleteAttendence = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Attendence', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getAttendence(
//     ls_flag: any,
//     ai_userid,
//     ad_dtfrm,
//     ad_dtto
//   ): Observable<Attendence[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('li_userid', ai_userid)
//       .set('ls_datefrm', ad_dtfrm)
//       .set('ls_dateto', ad_dtto);
//     let options = { params: params };
//     return this.http.get<Attendence[]>(this.gs_api + 'Attendence', options);
//   }

//   public getServiceMasterPending(
//     ls_flag,
//     liCusid
//   ): Observable<ServiceMaster[]> {
//     if (liCusid == null) {
//       liCusid = 0;
//     }
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('li_cusid', liCusid);
//     let options = { params: params };
//     return this.http.get<ServiceMaster[]>(
//       this.gs_api + 'ServiceMaster',
//       options
//     );
//   }

//   public getSearch(ls_flag, lsWhere): Observable<ServiceMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_where', lsWhere);
//     let options = { params: params };
//     return this.http.get<ServiceMaster[]>(this.gs_api + 'Search', options);
//   }

//   public getServiceMasterById(li_id, ls_flag): Observable<ServiceMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', li_id);
//     let options = { params: params };
//     return this.http.get<ServiceMaster[]>(
//       this.gs_api + 'ServiceMaster',
//       options
//     );
//   }

//   public deleteServiceMaster = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_euid', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'ServiceMaster', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditServiceDet(servicedets: ServiceDet) {
//     var myPostObject = {
//       id: servicedets.id,
//       uuid: this.getUuid(),
//       refid: servicedets.refid,
//       accesorryname: servicedets.AccesorryName,
//       serialno: servicedets.SerialNo,
//     };
//     return this.http
//       .post(this.gs_api + 'ServiceDet', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }
//   public deleteServiceDet = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'ServiceDet', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getServiceDet(ls_flag): Observable<ServiceDet[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<ServiceDet[]>(this.gs_api + 'ServiceDet', options);
//   }

//   public getLicence(ls_flag, ls_licno): Observable<Company[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_licence', ls_licno);
//     let options = { params: params };
//     return this.http.get<Company[]>(this.gs_api + 'Company', options);
//   }

//   public addEditCompany(companys: Company, lseuid) {
//     var myPostObject = {
//       companyid: companys.companyid,
//       companyname: companys.companyname,
//       branchname: companys.branchname,
//       address: companys.address,
//       address1: companys.address1,
//       phnos: companys.phnos,
//       mob: companys.mob,
//       vatno: companys.vatno,
//       cst: companys.cst,
//       finid: 20,
//       holder: companys.holder,
//       state: companys.State,
//       email: companys.Email,
//       website: companys.WebSite,
//       fax: companys.fax,
//       pin: companys.Pin,
//       tin: companys.Tin,
//       licno: companys.licno,
//       comcode: companys.ComCode,
//       status: companys.Status,
//       propath: companys.propath,
//       productid1: companys.productid1,
//       productid2: companys.productid2,
//       logo: companys.Logo,
//       malcompanyname: companys.MalCompanyName,
//       malbranchname: companys.MalBranchName,
//       maladdress: companys.MalAddress,
//       maladdress1: companys.MalAddress1,
//       malstate: companys.MalState,
//       clientname: companys.ClientName,
//       lastupdate: companys.lastupdate
//         ? this.datePipe.transform(
//             new Date(companys.lastupdate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       combankname: companys.ComBankName,
//       combankaccno: companys.ComBankAccno,
//       comifsccode: companys.ComIFSCCode,
//       combankaccname: companys.ComBankAccName,
//       companno: companys.ComPanNO,
//       comaadharno: companys.ComAadharNo,
//       lastupdateserver: companys.LastUpdateServer
//         ? this.datePipe.transform(
//             new Date(companys.LastUpdateServer).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       priceeuid: lseuid,
//     };

//     return this.http
//       .post(this.gs_api + 'Company', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getCompany(ls_flag: any): Observable<Company[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<Company[]>(this.gs_api + 'Company', options);
//   }

//   public deleteCompany = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Company', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public deletePurchaseDet = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'PurchaseDet', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public deleteSalesReturnDet = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'SalesReturnDet', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditCustomers(customerss: Customers) {
//     var ls_custype = '';
//     if (customerss.CusType) {
//       customerss.PartyType = 'Supplier';
//     } else if (customerss.CusType) {
//       customerss.PartyType = 'Customer';
//     }
//     var myPostObject = {
//       id: customerss.id,
//       uuid: this.getUuid(),
//       customercode: customerss.CustomerCode,
//       customercodeseq: customerss.CustomerCodeSeq,
//       customername: customerss.CustomerName,
//       address: customerss.Address,
//       place: customerss.Place,
//       countrycode: customerss.CountryCode,
//       mobile: customerss.Mobile,
//       email: customerss.Email,
//       partytype: customerss.PartyType,
//       gstno: customerss.GSTNo,
//       shippingaddress: customerss.ShippingAddress,
//       password: customerss.Password,
//       gsttype: customerss.GSTType,
//       stateid: customerss.StateID,
//       optype: customerss.OPType,
//       opamount: customerss.OPAmount,
//       opdate: customerss.OPDate
//         ? this.datePipe.transform(
//             new Date(customerss.OPDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       creditlimit: customerss.CreditLimit,
//       creditdays: customerss.CreditDays,
//       accountid: customerss.AccountId,
//       voucherid: customerss.VoucherID,
//       notes: customerss.Notes,

//       euid: customerss.euid,
//       status: customerss.Status,
//       companyid: customerss.CompanyId,
//     };
//     return this.http
//       .post(this.gs_api + 'Customers', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }
//   public getWhatsAppStatus(ls_whstapid) {
//     // let fd = new FormData();
//     // fd.append('sender', ls_whstapid);

//     // return this.http
//     //   .post(this.gs_apiWsAp + 'check-status', fd)
//     //   .pipe(catchError(this.errorMgmt));
//   }

//   public openQRCodeURL() {
//     window.open(
//       'http://smartserviceapp.com/ap/licence/qrcode/' + this.getUuid(),
//       '_blank'
//     );
//   }

//   // public getWhatsAppQRCode(ls_flag): Observable<any> {
//   //   // const params = new HttpParams()
//   //   //    .set('ls_token', this.getUuid())
//   //   //    .set('ls_flag',ls_flag)
//   //   // let options = { params: params };
//   //   // return this.http.get<any>(this.gs_apiWsAp + 'getqr');
//   // }

//   public addEditWABot(wabots: WABot) {
//     var myPostObject = {
//       id: wabots.id,
//       uuid: this.getUuid(),
//       parentcode: wabots.ParentCode,
//       bottype: wabots.BotType,
//       botmsg: wabots.BotMsg,
//       code: wabots.Code,
//       orderby: wabots.OrderBy,
//       companyid: wabots.CompanyId,
//       status: wabots.Status,
//       euid: wabots.euid,
//     };
//     return this.http
//       .post(this.gs_api + 'WABot', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getWABot(ls_flag: any): Observable<WABot[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<WABot[]>(this.gs_api + 'WABot', options);
//   }

//   public deleteWABot = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'WABot', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public postWhatsAppMessage(ls_number, ls_message) {
//     var myPostObject = {
//       number: ls_number,
//       message: ls_message,
//     };
//     // return this.http
//     //   .post(this.gs_apiWsAp + 'sendmessage', myPostObject)
//     //   .pipe(catchError(this.errorMgmt));
//   }

//   public getTabChanges(
//     ls_flag: any,
//     ls_TabName,
//     li_lastid
//   ): Observable<TabChanges[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_TabName', ls_TabName)
//       .set('li_lastid', li_lastid);
//     let options = { params: params };
//     return this.http.get<TabChanges[]>(this.gs_api + 'TabChanges', options);
//   }

//   public getCustomers(ls_flag): Observable<any[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<any[]>(this.gs_api + 'Customers', options);
//   }

//   public getCustomersById(ls_flag, ls_euid): Observable<Customers[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', ls_euid)
//       .set('ls_code', '');

//     let options = { params: params };
//     return this.http.get<Customers[]>(this.gs_api + 'Customers', options);
//   }

//   public getCustomersCode(ls_flag, ls_cusname): Observable<Customers[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_cusname', ls_cusname);
//     let options = { params: params };
//     return this.http.get<Customers[]>(this.gs_api + 'Customers', options);
//   }

//   public deleteCustomers = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Customers', options)
//       .pipe(catchError(this.errorMgmt));
//   };



//   public addEditEstimateMaster(EstimateMastersHeader: EstimateMaster, detdata) {
//     var myPostObject = {
//       id: EstimateMastersHeader.id,
//       uuid: this.getUuid(),
//       invoiceno: EstimateMastersHeader.InvoiceNo,
//       invoicenoseq: EstimateMastersHeader.InvoiceNoSeq,
//       saledate: EstimateMastersHeader.SaleDate
//         ? this.datePipe.transform(
//             new Date(EstimateMastersHeader.SaleDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       cusid: EstimateMastersHeader.CusId,
//       taxmode: EstimateMastersHeader.TaxMode,
//       paymentmode: EstimateMastersHeader.PaymentMode,
//       narration: EstimateMastersHeader.Narration,
//       grossamt: EstimateMastersHeader.GrossAmt,
//       taxableamt: EstimateMastersHeader.TaxableAmt,
//       taxamt: EstimateMastersHeader.TaxAmt,
//       nontaxamt: EstimateMastersHeader.NonTaxAmt,
//       itemdisamt: EstimateMastersHeader.ItemDisAmt,
//       totcessamt: EstimateMastersHeader.TotCessAmt,
//       totadcessamt: EstimateMastersHeader.TotAdCessAmt,
//       netamount: EstimateMastersHeader.NetAmount,
//       frieght: EstimateMastersHeader.Frieght,
//       adjustments: EstimateMastersHeader.Adjustments,
//       roundoff: EstimateMastersHeader.RoundOff,
//       discount: EstimateMastersHeader.Discount,
//       grand: EstimateMastersHeader.Grand,
//       totqty: EstimateMastersHeader.TotQty,
//       voucherid: EstimateMastersHeader.VoucherId,
//       cashvoucherid: EstimateMastersHeader.CashVoucherid,
//       saleexpvoucherid: EstimateMastersHeader.SaleExpVoucherId,
//       vehicleno: EstimateMastersHeader.VehicleNo,
//       supplyplace: EstimateMastersHeader.SupplyPlace,
//       supplydate: EstimateMastersHeader.SupplyDate
//         ? this.datePipe.transform(
//             new Date(EstimateMastersHeader.SupplyDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       ewaybillno: EstimateMastersHeader.EWayBillno,
//       duedate: EstimateMastersHeader.DueDate
//         ? this.datePipe.transform(
//             new Date(EstimateMastersHeader.DueDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       discountper: EstimateMastersHeader.DiscountPer,
//       paidstatus: EstimateMastersHeader.PaidStatus,
//       paidamt: EstimateMastersHeader.PaidAmt,
//       balance: EstimateMastersHeader.Balance,
//       autoroundoff: EstimateMastersHeader.AutoRoundOff,
//       currencyid: EstimateMastersHeader.CurrencyId,
//       mopid: EstimateMastersHeader.MopId,
//       pono: EstimateMastersHeader.PONo,
//       podate: EstimateMastersHeader.PODate
//         ? this.datePipe.transform(
//             new Date(EstimateMastersHeader.PODate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       redumamt: EstimateMastersHeader.RedumAmt,
//       redum: EstimateMastersHeader.Redum,
//       agentid: EstimateMastersHeader.AgentId,
//       agentcommi: EstimateMastersHeader.AgentCommi,
//       agtcommivoucherid: EstimateMastersHeader.AgtCommiVoucherId,
//       transmode: EstimateMastersHeader.TransMode,
//       supdate: EstimateMastersHeader.SupDate
//         ? this.datePipe.transform(
//             new Date(EstimateMastersHeader.SupDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       quicksale: EstimateMastersHeader.QuickSale,
//       cusname: EstimateMastersHeader.CusName,
//       cusaddress: EstimateMastersHeader.CusAddress,

//       companyid: EstimateMastersHeader.CompanyId,
//       status: EstimateMastersHeader.Status,
//       euid: EstimateMastersHeader.euid,
//       printtype: EstimateMastersHeader.PrintType,
//       nocopy: EstimateMastersHeader.NoCopy,
//       EstimateDet: detdata,
//     };
//     return this.http
//       .post(this.gs_api + 'EstimateMaster', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getEstimateMaster(ls_flag: any): Observable<EstimateMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<EstimateMaster[]>(this.gs_api + 'EstimateMaster', options);
//   }

//   public getEstimateMasterById(li_id, ls_flag): Observable<EstimateMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', li_id);
//     let options = { params: params };
//     // console.log('tess')
//     return this.http.get<EstimateMaster[]>(this.gs_api + 'EstimateMaster', options);
//   }

//   public deleteEstimateMaster = (li_id): Observable<any> => {
//     console.log(li_id);
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'EstimateMaster', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditEstDetBS(EstDetBSs: EstDetBS) {
//     var myPostObject = {
//       id: EstDetBSs.id,
//       uuid: this.getUuid(),
//       itemid: EstDetBSs.ItemId,
//       refid: EstDetBSs.RefId,
//       sid: EstDetBSs.SID,
//       bid: EstDetBSs.BID,
//       qty: EstDetBSs.Qty,
//     };
//     return this.http
//       .post(this.gs_api + 'EstDetBS', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getEstDetBS(ls_flag: any): Observable<EstDetBS[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<EstDetBS[]>(this.gs_api + 'EstDetBS', options);
//   }

//   public deleteEstDetBS = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'EstDetBS', options)
//       .pipe(catchError(this.errorMgmt));
//   };
  
  
//   public addEditEstimateDet(EstimateDets: EstimateDet) {
//     var myPostObject = {
//       id: EstimateDets.id,
//       uuid: this.getUuid(),
//       refid: EstimateDets.RefId,
//       itemid: EstimateDets.ItemId,
//       unitid: EstimateDets.UnitId,
//       qty: EstimateDets.Qty,
//       free: EstimateDets.Free,
//       srate: EstimateDets.SRate,
//       mrp: EstimateDets.Mrp,
//       disper: EstimateDets.DisPer,
//       disamt: EstimateDets.DisAmt,
//       taxtype: EstimateDets.TaxType,
//       taxper: EstimateDets.TaxPer,
//       taxamt: EstimateDets.TaxAmt,
//       taxable: EstimateDets.Taxable,
//       amount: EstimateDets.Amount,
//       unitstr: EstimateDets.UnitStr,
//       bid: EstimateDets.Bid,
//       naration: EstimateDets.Naration,
//       unitrate: EstimateDets.UnitRate,
//     };
//     return this.http
//       .post(this.gs_api + 'EstimateDet', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getEstimateDet(ls_flag: any): Observable<EstimateDet[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<EstimateDet[]>(this.gs_api + 'EstimateDet', options);
//   }

//   public deleteEstimateDet = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'EstimateDet', options)
//       .pipe(catchError(this.errorMgmt));
//   };
  

  

//   public addEditSalesDet(salesdets: SalesDet) {
//     var myPostObject = {
//       id: salesdets.id,
//       uuid: this.getUuid(),
//       refid: salesdets.RefId,
//       itemid: salesdets.ItemId,
//       unitid: salesdets.UnitId,
//       qty: salesdets.Qty,
//       free: salesdets.Free,
//       srate: salesdets.SRate,
//       mrp: salesdets.Mrp,
//       disper: salesdets.DisPer,
//       disamt: salesdets.DisAmt,
//       taxtype: salesdets.TaxType,
//       taxper: salesdets.TaxPer,
//       taxamt: salesdets.TaxAmt,
//       taxable: salesdets.Taxable,
//       amount: salesdets.Amount,
//       unitstr: salesdets.UnitStr,
//       bid: salesdets.Bid,
//       naration: salesdets.Naration,
//       unitrate: salesdets.UnitRate,
//     };
//     return this.http
//       .post(this.gs_api + 'SalesDet', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getSalesDet(ls_flag: any): Observable<SalesDet[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<SalesDet[]>(this.gs_api + 'SalesDet', options);
//   }

//   public deleteSalesDet = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'SalesDet', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditSalesMaster(salesmastersHeader: SalesMaster, detdata) {
//     var myPostObject = {
//       id: salesmastersHeader.id,
//       uuid: this.getUuid(),
//       invoiceno: salesmastersHeader.InvoiceNo,
//       invoicenoseq: salesmastersHeader.InvoiceNoSeq,
//       saledate: salesmastersHeader.SaleDate
//         ? this.datePipe.transform(
//             new Date(salesmastersHeader.SaleDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       cusid: salesmastersHeader.CusId,
//       taxmode: salesmastersHeader.TaxMode,
//       paymentmode: salesmastersHeader.PaymentMode,
//       narration: salesmastersHeader.Narration,
//       grossamt: salesmastersHeader.GrossAmt,
//       taxableamt: salesmastersHeader.TaxableAmt,
//       taxamt: salesmastersHeader.TaxAmt,
//       nontaxamt: salesmastersHeader.NonTaxAmt,
//       itemdisamt: salesmastersHeader.ItemDisAmt,
//       totcessamt: salesmastersHeader.TotCessAmt,
//       totadcessamt: salesmastersHeader.TotAdCessAmt,
//       netamount: salesmastersHeader.NetAmount,
//       frieght: salesmastersHeader.Frieght,
//       adjustments: salesmastersHeader.Adjustments,
//       roundoff: salesmastersHeader.RoundOff,
//       discount: salesmastersHeader.Discount,
//       grand: salesmastersHeader.Grand,
//       totqty: salesmastersHeader.TotQty,
//       voucherid: salesmastersHeader.VoucherId,
//       cashvoucherid: salesmastersHeader.CashVoucherid,
//       saleexpvoucherid: salesmastersHeader.SaleExpVoucherId,
//       vehicleno: salesmastersHeader.VehicleNo,
//       supplyplace: salesmastersHeader.SupplyPlace,
//       supplydate: salesmastersHeader.SupplyDate
//         ? this.datePipe.transform(
//             new Date(salesmastersHeader.SupplyDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       ewaybillno: salesmastersHeader.EWayBillno,
//       duedate: salesmastersHeader.DueDate
//         ? this.datePipe.transform(
//             new Date(salesmastersHeader.DueDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       discountper: salesmastersHeader.DiscountPer,
//       paidstatus: salesmastersHeader.PaidStatus,
//       paidamt: salesmastersHeader.PaidAmt,
//       balance: salesmastersHeader.Balance,
//       autoroundoff: salesmastersHeader.AutoRoundOff,
//       currencyid: salesmastersHeader.CurrencyId,
//       mopid: salesmastersHeader.MopId,
//       pono: salesmastersHeader.PONo,
//       podate: salesmastersHeader.PODate
//         ? this.datePipe.transform(
//             new Date(salesmastersHeader.PODate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       redumamt: salesmastersHeader.RedumAmt,
//       redum: salesmastersHeader.Redum,
//       agentid: salesmastersHeader.AgentId,
//       agentcommi: salesmastersHeader.AgentCommi,
//       agtcommivoucherid: salesmastersHeader.AgtCommiVoucherId,
//       transmode: salesmastersHeader.TransMode,
//       supdate: salesmastersHeader.SupDate
//         ? this.datePipe.transform(
//             new Date(salesmastersHeader.SupDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       quicksale: salesmastersHeader.QuickSale,
//       cusname: salesmastersHeader.CusName,
//       cusaddress: salesmastersHeader.CusAddress,

//       companyid: salesmastersHeader.CompanyId,
//       status: salesmastersHeader.Status,
//       euid: salesmastersHeader.euid,
//       printtype: salesmastersHeader.PrintType,
//       nocopy: salesmastersHeader.NoCopy,
//       SalesDet: detdata,
//     };
//     return this.http
//       .post(this.gs_api + 'SalesMaster', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getSalesMaster(ls_flag: any): Observable<SalesMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<SalesMaster[]>(this.gs_api + 'SalesMaster', options);
//   }

//   public getSalesMasterById(li_id, ls_flag): Observable<SalesMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', li_id);
//     let options = { params: params };
//     // console.log('tess')
//     return this.http.get<SalesMaster[]>(this.gs_api + 'SalesMaster', options);
//   }

//   public deleteSalesMaster = (li_id): Observable<any> => {
//     console.log(li_id);
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'SalesMaster', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditSDetBS(sdetbss: SDetBS) {
//     var myPostObject = {
//       id: sdetbss.id,
//       uuid: this.getUuid(),
//       itemid: sdetbss.ItemId,
//       refid: sdetbss.RefId,
//       sid: sdetbss.SID,
//       bid: sdetbss.BID,
//       qty: sdetbss.Qty,
//     };
//     return this.http
//       .post(this.gs_api + 'SDetBS', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getSDetBS(ls_flag: any): Observable<SDetBS[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<SDetBS[]>(this.gs_api + 'SDetBS', options);
//   }

//   public deleteSDetBS = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'SDetBS', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditPurchaseReturnDet(PurchaseReturnDets: PurchaseReturnDet) {
//     var myPostObject = {
//       id: PurchaseReturnDets.id,
//       uuid: this.getUuid(),
//       refid: PurchaseReturnDets.RefId,
//       itemid: PurchaseReturnDets.ItemId,
//       unitid: PurchaseReturnDets.UnitId,
//       qty: PurchaseReturnDets.Qty,
//       free: PurchaseReturnDets.Free,
//       srate: PurchaseReturnDets.SRate,
//       mrp: PurchaseReturnDets.Mrp,
//       disper: PurchaseReturnDets.DisPer,
//       disamt: PurchaseReturnDets.DisAmt,
//       taxtype: PurchaseReturnDets.TaxType,
//       taxper: PurchaseReturnDets.TaxPer,
//       taxamt: PurchaseReturnDets.TaxAmt,
//       taxable: PurchaseReturnDets.Taxable,
//       amount: PurchaseReturnDets.Amount,
//       unitstr: PurchaseReturnDets.UnitStr,
//       bid: PurchaseReturnDets.Bid,
//       naration: PurchaseReturnDets.Naration,
//       unitrate: PurchaseReturnDets.UnitRate,
//     };
//     return this.http
//       .post(this.gs_api + 'PurchaseReturnDet', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getPurchaseReturnDet(ls_flag: any): Observable<PurchaseReturnDet[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<PurchaseReturnDet[]>(
//       this.gs_api + 'PurchaseReturnDet',
//       options
//     );
//   }

//   public deletePurchaseReturnDet = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'PurchaseReturnDet', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditPurchaseReturnMaster(prmaster: PurchaseReturnMaster, detdata) {
//     var myPostObject = {
//       id: prmaster.id,
//       uuid: this.getUuid(),
//       invoiceno: prmaster.InvoiceNo,
//       invoicenoseq: prmaster.InvoiceNoSeq,
//       saledate: prmaster.SaleDate
//         ? this.datePipe.transform(
//             new Date(prmaster.SaleDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       cusid: prmaster.CusId,
//       taxmode: prmaster.TaxMode,
//       paymentmode: prmaster.PaymentMode,
//       narration: prmaster.Narration,
//       grossamt: prmaster.GrossAmt,
//       taxableamt: prmaster.TaxableAmt,
//       taxamt: prmaster.TaxAmt,
//       nontaxamt: prmaster.NonTaxAmt,
//       itemdisamt: prmaster.ItemDisAmt,
//       totcessamt: prmaster.TotCessAmt,
//       totadcessamt: prmaster.TotAdCessAmt,
//       netamount: prmaster.NetAmount,
//       frieght: prmaster.Frieght,
//       adjustments: prmaster.Adjustments,
//       roundoff: prmaster.RoundOff,
//       discount: prmaster.Discount,
//       grand: prmaster.Grand,
//       totqty: prmaster.TotQty,
//       voucherid: prmaster.VoucherId,
//       cashvoucherid: prmaster.CashVoucherid,
//       saleexpvoucherid: prmaster.SaleExpVoucherId,
//       vehicleno: prmaster.VehicleNo,
//       supplyplace: prmaster.SupplyPlace,
//       supplydate: prmaster.SupplyDate
//         ? this.datePipe.transform(
//             new Date(prmaster.SupplyDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       ewaybillno: prmaster.EWayBillno,
//       duedate: prmaster.DueDate
//         ? this.datePipe.transform(
//             new Date(prmaster.DueDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       discountper: prmaster.DiscountPer,
//       paidstatus: prmaster.PaidStatus,
//       paidamt: prmaster.PaidAmt,
//       balance: prmaster.Balance,
//       autoroundoff: prmaster.AutoRoundOff,
//       currencyid: prmaster.CurrencyId,
//       mopid: prmaster.MopId,
//       pono: prmaster.PONo,
//       podate: prmaster.PODate
//         ? this.datePipe.transform(
//             new Date(prmaster.PODate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       redumamt: prmaster.RedumAmt,
//       redum: prmaster.Redum,
//       agentid: prmaster.AgentId,
//       agentcommi: prmaster.AgentCommi,
//       agtcommivoucherid: prmaster.AgtCommiVoucherId,
//       transmode: prmaster.TransMode,
//       supdate: prmaster.SupDate
//         ? this.datePipe.transform(
//             new Date(prmaster.SupDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       quicksale: prmaster.QuickSale,
//       cusname: prmaster.CusName,
//       cusaddress: prmaster.CusAddress,

//       companyid: prmaster.CompanyId,
//       status: prmaster.Status,
//       euid: prmaster.euid,
//       printtype: prmaster.PrintType,
//       nocopy: prmaster.NoCopy,
//       PurchaseReturnDet: detdata,
//     };
//     return this.http
//       .post(this.gs_api + 'PurchaseReturnMaster', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getPurchaseReturnMaster(
//     ls_flag: any
//   ): Observable<PurchaseReturnMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<PurchaseReturnMaster[]>(
//       this.gs_api + 'PurchaseReturnMaster',
//       options
//     );
//   }

//   public getPurchaseReturnMasterById(
//     li_id,
//     ls_flag
//   ): Observable<PurchaseReturnMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', li_id);
//     let options = { params: params };
//     // console.log('tess')
//     return this.http.get<PurchaseReturnMaster[]>(
//       this.gs_api + 'PurchaseReturnMaster',
//       options
//     );
//   }

//   public deletePurchaseReturnMaster = (li_id): Observable<any> => {
//     console.log(li_id);
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'PurchaseReturnMaster', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditPRDetBS(PRDetBSs: PRDetBS) {
//     var myPostObject = {
//       id: PRDetBSs.id,
//       uuid: this.getUuid(),
//       itemid: PRDetBSs.ItemId,
//       refid: PRDetBSs.RefId,
//       sid: PRDetBSs.SID,
//       bid: PRDetBSs.BID,
//       qty: PRDetBSs.Qty,
//     };
//     return this.http
//       .post(this.gs_api + 'PRDetBS', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getPRDetBS(ls_flag: any): Observable<PRDetBS[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<PRDetBS[]>(this.gs_api + 'PRDetBS', options);
//   }

//   public deletePRDetBS = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'PRDetBS', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public setHistory(ls_formname, ls_key) {
//     var filters = {};
//     Object.keys(ls_formname.controls).forEach((key) => {
//       if (ls_formname.controls[key].value != null) {
//         filters[key] = ls_formname.controls[key].value;
//       }
//     });

//     sessionStorage.setItem(ls_key, JSON.stringify(filters));
//   }

//   public getHistory(ls_key) {
//     return JSON.parse(window.sessionStorage.getItem(ls_key));
//   }

//   public printReport(
//     detdata: any[],
//     creuid,
//     ishandf,
//     rptname,
//     rptheader,
//     para1,
//     para2,
//     para3,
//     para4,
//     para5,
//     isWords,
//     wordsFiledName
//   ) {
//     const httpOptions = {
//       headers: new HttpHeaders({
//         'Content-Type': 'application/json',
//       }),
//     };

//     var myPostObject = {
//       id: 0,
//       uuid: this.getUuid(),
//       euid: creuid,
//       crsql: '',
//       ishandf: ishandf,
//       rptname: rptname,
//       rptheader: rptheader,
//       para1: para1,
//       para2: para2,
//       para3: para3,
//       para4: para4,
//       para5: para5,
//       isWords: isWords,
//       wordsFiledName: wordsFiledName,
//     };

//     const payload = {
//       jsonArray: detdata,
//       crystaldata: myPostObject,
//     };
//     console.log(payload);
//     return this.http
//       .post<any>(this.gs_api + 'Reports', payload, httpOptions)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public addEditSalesReturnMaster(
//     SalesReturnMastersHeader: SalesReturnMaster,
//     detdata
//   ) {
//     var myPostObject = {
//       id: SalesReturnMastersHeader.id,
//       uuid: this.getUuid(),
//       purno: SalesReturnMastersHeader.PurNo,
//       purnoseq: SalesReturnMastersHeader.PurNoSeq,
//       purdate: SalesReturnMastersHeader.PurDate
//         ? this.datePipe.transform(
//             new Date(SalesReturnMastersHeader.PurDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       supid: SalesReturnMastersHeader.SupId,
//       invoiceno: SalesReturnMastersHeader.InvoiceNo,
//       stockdate: SalesReturnMastersHeader.StockDate
//         ? this.datePipe.transform(
//             new Date(SalesReturnMastersHeader.StockDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       taxmode: SalesReturnMastersHeader.TaxMode,
//       paymentmode: SalesReturnMastersHeader.PaymentMode,
//       narration: SalesReturnMastersHeader.Narration,
//       grossamt: SalesReturnMastersHeader.GrossAmt,
//       taxableamt: SalesReturnMastersHeader.TaxableAmt,
//       taxamt: SalesReturnMastersHeader.TaxAmt,
//       nontaxamt: SalesReturnMastersHeader.NonTaxAmt,
//       itemdisamt: SalesReturnMastersHeader.ItemDisAmt,
//       totcessamt: SalesReturnMastersHeader.TotCessAmt,
//       totadcessamt: SalesReturnMastersHeader.TotAdCessAmt,
//       netamount: SalesReturnMastersHeader.NetAmount,
//       frieght: SalesReturnMastersHeader.Frieght,
//       adjustments: SalesReturnMastersHeader.Adjustments,
//       roundoff: SalesReturnMastersHeader.RoundOff,
//       discount: SalesReturnMastersHeader.Discount,
//       grand: SalesReturnMastersHeader.Grand,
//       totqty: SalesReturnMastersHeader.TotQty,
//       voucherid: SalesReturnMastersHeader.VoucherId,
//       cashvoucherid: SalesReturnMastersHeader.CashVoucherid,
//       purincvoucherid: SalesReturnMastersHeader.PurIncVoucherId,
//       vehicleno: SalesReturnMastersHeader.VehicleNo,
//       supplyplace: SalesReturnMastersHeader.SupplyPlace,
//       supplydate: SalesReturnMastersHeader.SupplyDate
//         ? this.datePipe.transform(
//             new Date(SalesReturnMastersHeader.SupplyDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       ewaybillno: SalesReturnMastersHeader.EWayBillno,
//       duedate: SalesReturnMastersHeader.DueDate
//         ? this.datePipe.transform(
//             new Date(SalesReturnMastersHeader.DueDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       discountper: SalesReturnMastersHeader.DiscountPer,
//       paidstatus: SalesReturnMastersHeader.PaidStatus,
//       paidamt: SalesReturnMastersHeader.PaidAmt,
//       balance: SalesReturnMastersHeader.Balance,
//       autoroundoff: SalesReturnMastersHeader.AutoRoundOff,
//       mopid: SalesReturnMastersHeader.MopId,
//       currencyid: SalesReturnMastersHeader.CurrencyId,
//       companyid: SalesReturnMastersHeader.CompanyId,
//       status: SalesReturnMastersHeader.Status,
//       SalesReturnDet: detdata,
//     };
//     return this.http
//       .post(this.gs_api + 'SalesReturnMaster', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getSalesReturnMaster(ls_flag: any): Observable<SalesReturnMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<SalesReturnMaster[]>(
//       this.gs_api + 'SalesReturnMaster',
//       options
//     );
//   }

//   public getSalesReturnMasterByDate(
//     ls_flag: any,
//     ad_dtfrm,
//     ad_dtto
//   ): Observable<SalesReturnMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_datefrm', ad_dtfrm)
//       .set('ls_dateto', ad_dtto);
//     let options = { params: params };
//     return this.http.get<SalesReturnMaster[]>(
//       this.gs_api + 'SalesReturnMaster',
//       options
//     );
//   }

//   public getSalesReturnMasterById(
//     li_id,
//     ls_flag
//   ): Observable<SalesReturnMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', li_id);
//     let options = { params: params };
//     // console.log(options);
//     return this.http.get<SalesReturnMaster[]>(
//       this.gs_api + 'SalesReturnMaster',
//       options
//     );
//   }
//   public deleteSalesReturnMaster = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_euid', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'SalesReturnMaster', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditPurchaseMaster(purchasemastersHeader: PurchaseMaster, detdata) {
//     // var detbatch = [];

//     // detbatch=    detdata.map((fruit1: any, index: number) => {
//     //   console.log(fruit1);
//     //   return fruit1.Batch.map((fruit: any, index: number) => {
//     //     return {
//     //       ...fruit,
//     //       ExpDate1: fruit.ExpDate
//     //         ? this.datePipe.transform(
//     //             new Date(fruit.ExpDate).toISOString(),
//     //             'yyyy-MM-dd'
//     //           )
//     //         : null,
//     //     };
//     //   });

//     // });

//     // console.log(detdata);
//     // if (detBatch !== undefined) {
//     //   detBatch.forEach((item) => {
//     //     if (item.BatchNo !== '') {
//     //       la_batch.push({
//     //         SlNo: item.SlNo,
//     //         id: item.id,
//     //         BatchNo: item.BatchNo,
//     //         ExpDate1: item.ExpDate
//     //           ? this.datePipe.transform(
//     //               new Date(item.ExpDate).toISOString(),
//     //               'yyyy-MM-dd'
//     //             )
//     //           : null,
//     //         Col1: item.Col1,
//     //         Col2: item.Col2,
//     //         OPStock: item.OPStock,
//     //         ItemId: item.ItemId,
//     //         BatchFrom: 'Opening',
//     //       });
//     //     }
//     //   });
//     // }

//     var myPostObject = {
//       id: purchasemastersHeader.id,
//       uuid: this.getUuid(),
//       purno: purchasemastersHeader.PurNo,
//       purnoseq: purchasemastersHeader.PurNoSeq,
//       purdate: purchasemastersHeader.PurDate
//         ? this.datePipe.transform(
//             new Date(purchasemastersHeader.PurDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       supid: purchasemastersHeader.SupId,
//       invoiceno: purchasemastersHeader.InvoiceNo,
//       stockdate: purchasemastersHeader.StockDate
//         ? this.datePipe.transform(
//             new Date(purchasemastersHeader.StockDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       taxmode: purchasemastersHeader.TaxMode,
//       paymentmode: purchasemastersHeader.PaymentMode,
//       narration: purchasemastersHeader.Narration,
//       grossamt: purchasemastersHeader.GrossAmt,
//       taxableamt: purchasemastersHeader.TaxableAmt,
//       taxamt: purchasemastersHeader.TaxAmt,
//       nontaxamt: purchasemastersHeader.NonTaxAmt,
//       itemdisamt: purchasemastersHeader.ItemDisAmt,
//       totcessamt: purchasemastersHeader.TotCessAmt,
//       totadcessamt: purchasemastersHeader.TotAdCessAmt,
//       netamount: purchasemastersHeader.NetAmount,
//       frieght: purchasemastersHeader.Frieght,
//       adjustments: purchasemastersHeader.Adjustments,
//       roundoff: purchasemastersHeader.RoundOff,
//       discount: purchasemastersHeader.Discount,
//       grand: purchasemastersHeader.Grand,
//       totqty: purchasemastersHeader.TotQty,
//       voucherid: purchasemastersHeader.VoucherId,
//       cashvoucherid: purchasemastersHeader.CashVoucherid,
//       purincvoucherid: purchasemastersHeader.PurIncVoucherId,
//       vehicleno: purchasemastersHeader.VehicleNo,
//       supplyplace: purchasemastersHeader.SupplyPlace,
//       supplydate: purchasemastersHeader.SupplyDate
//         ? this.datePipe.transform(
//             new Date(purchasemastersHeader.SupplyDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       ewaybillno: purchasemastersHeader.EWayBillno,
//       duedate: purchasemastersHeader.DueDate
//         ? this.datePipe.transform(
//             new Date(purchasemastersHeader.DueDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       discountper: purchasemastersHeader.DiscountPer,
//       paidstatus: purchasemastersHeader.PaidStatus,
//       paidamt: purchasemastersHeader.PaidAmt,
//       balance: purchasemastersHeader.Balance,
//       autoroundoff: purchasemastersHeader.AutoRoundOff,
//       mopid: purchasemastersHeader.MopId,
//       currencyid: purchasemastersHeader.CurrencyId,
//       companyid: purchasemastersHeader.CompanyId,
//       status: purchasemastersHeader.Status,
//       PurchaseDet: detdata,
//     };
//     return this.http
//       .post(this.gs_api + 'PurchaseMaster', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getPurchaseMaster(ls_flag: any): Observable<PurchaseMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<PurchaseMaster[]>(
//       this.gs_api + 'PurchaseMaster',
//       options
//     );
//   }

//   public getPurchaseMasterByDate(
//     ls_flag: any,
//     ad_dtfrm,
//     ad_dtto
//   ): Observable<PurchaseMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_datefrm', ad_dtfrm)
//       .set('ls_dateto', ad_dtto);
//     let options = { params: params };
//     return this.http.get<PurchaseMaster[]>(
//       this.gs_api + 'PurchaseMaster',
//       options
//     );
//   }

//   public getPurchaseMasterById(li_id, ls_flag): Observable<PurchaseMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', li_id);
//     let options = { params: params };
//     // console.log(options);
//     return this.http.get<PurchaseMaster[]>(
//       this.gs_api + 'PurchaseMaster',
//       options
//     );
//   }
//   public deletePurchaseMaster = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_euid', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'PurchaseMaster', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditSuppliers(supplierss: Suppliers) {
//     var myPostObject = {
//       id: supplierss.id,
//       uuid: this.getUuid(),
//       suppliername: supplierss.SupplierName,
//       address: supplierss.Address,
//       phone: supplierss.Phone,
//       email: supplierss.Email,
//       outstanding: supplierss.Outstanding,
//       status: supplierss.Status,
//     };
//     return this.http
//       .post(this.gs_api + 'Suppliers', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getSuppliers(ls_flag): Observable<Suppliers[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<Suppliers[]>(this.gs_api + 'Suppliers', options);
//   }

//   public deleteSuppliers = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Suppliers', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditItems(itemss: Items) {
//     var myPostObject = {
//       id: itemss.id,
//       uuid: this.getUuid(),
//       categoryid: itemss.CategoryId,
//       manufactureid: itemss.ManufactureId,
//       unitid: itemss.UnitId,
//       itemname: itemss.ItemName,
//       itemcode: itemss.ItemCode,
//       openingstock: itemss.OpeningStock,
//       prate: itemss.PRate,
//       srate: itemss.SRate,
//       status: itemss.Status,
//       euid: itemss.euid,
//       description: itemss.Description,
//     };
//     return this.http
//       .post(this.gs_api + 'Items', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }
//   public getItems(ls_flag): Observable<Items[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<Items[]>(this.gs_api + 'Items', options);
//   }

//   public deleteItems = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Items', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditCategory(categorys: Category) {
//     var myPostObject = {
//       id: categorys.id,
//       uuid: this.getUuid(),
//       categoryname: categorys.CategoryName,
//       categorycode: categorys.CategoryCode,
//       status: categorys.Status,
//     };
//     return this.http
//       .post(this.gs_api + 'Category', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getCategory(ls_flag): Observable<Category[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<Category[]>(this.gs_api + 'Category', options);
//   }

//   public deleteCategory = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Category', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditWSCoachingGroup(wscoachinggroups: WSCoachingGroup) {
//     var myPostObject = {
//       id: wscoachinggroups.id,
//       uuid: this.getUuid(),
//       coachinggroupname: wscoachinggroups.CoachingGroupName,
//       grouptype: wscoachinggroups.GroupType,
//       agegroupid: wscoachinggroups.AgeGroupId,
//       maxnumber: wscoachinggroups.MaxNumber,
//       leaderid: wscoachinggroups.LeaderId,
//       supporterid: wscoachinggroups.SupporterId,
//       status: wscoachinggroups.Status,
//       euid: wscoachinggroups.euid,
//     };
//     return this.http
//       .post(this.gs_api + 'WSCoachingGroup', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getWSCoachingGroup(ls_flag): Observable<WSCoachingGroup[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<WSCoachingGroup[]>(
//       this.gs_api + 'WSCoachingGroup',
//       options
//     );
//   }

//   public deleteWSCoachingGroup = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'WSCoachingGroup', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditWSCoach(wscoachs: WSCoach) {
//     var myPostObject = {
//       id: wscoachs.id,
//       uuid: this.getUuid(),
//       coachname: wscoachs.CoachName,
//       mobile: wscoachs.Mobile,
//       leader: wscoachs.Leader,
//       supporter: wscoachs.Supporter,
//       status: wscoachs.Status,
//     };
//     return this.http
//       .post(this.gs_api + 'WSCoach', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getWSCoach(ls_flag): Observable<WSCoach[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<WSCoach[]>(this.gs_api + 'WSCoach', options);
//   }

//   public deleteWSCoach = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'WSCoach', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getCountry(ls_flag): Observable<Country[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<Country[]>(this.gs_api + 'Country', options);
//   }

//   // downloadPDF(url): any {
//   //     const options = { responseType: ResponseContentType.Blob  };
//   //     return this.http.get(url, options).map(
//   //     (res) => {
//   //         return new Blob([res.blob()], { type: 'application/pdf' });
//   //     });
//   //   }

//   public addEditColumnAranger(data) {
//     for (var i = 0; i < data.length; i++) {
//       data[i].OrderNo = i + 1;
//       data[i].uuid = this.getUuid();
//     }
//     // console.log(data);

//     // var myPostObject = {
//     //     id: columnarangers.id,
//     //     uuid: this.getUuid(),
//     //     modulename: columnarangers.ModuleName,
//     //     orderno: columnarangers.OrderNo,
//     //     colname: columnarangers.ColName,
//     //     selected: columnarangers.Selected,
//     //     width: columnarangers.Width,
//     // }
//     return this.http
//       .post(this.gs_api + 'ColumnAranger', data)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getChangePwd(oldpwd, newpwd): Observable<any[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_oldpwd', oldpwd)
//       .set('ls_newpwd', newpwd);
//     let options = { params: params };
//     // console.log(options)
//     return this.http.get<any[]>(this.gs_api + 'Employee', options);
//   }

//   public getSOAPaymentMaster(ls_flag, ls_type): Observable<SOAPaymentMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_type', ls_type);
//     let options = { params: params };
//     return this.http.get<SOAPaymentMaster[]>(
//       this.gs_api + 'SOAPaymentMaster',
//       options
//     );
//   }

//   public getSOAPaymentMasterById(
//     li_id,
//     ls_flag,
//     ls_type
//   ): Observable<SOAPaymentMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', li_id)
//       .set('ls_type', ls_type);
//     let options = { params: params };

//     return this.http.get<SOAPaymentMaster[]>(
//       this.gs_api + 'SOAPaymentMaster',
//       options
//     );
//   }

//   public deleteSOAPaymentMaste = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('ls_euid', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'SOAPaymentMaster', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getColumnAranger(ls_flag, ls_modname): Observable<ColumnAranger[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_modname', ls_modname);
//     let options = { params: params };
//     return this.http.get<ColumnAranger[]>(
//       this.gs_api + 'ColumnAranger',
//       options
//     );
//   }

//   public addEditColumnMaster(columnmasters: ColumnMaster) {
//     var myPostObject = {
//       id: columnmasters.id,
//       uuid: this.getUuid(),
//       menuname: columnmasters.MenuName,
//       reportname: columnmasters.ReportName,
//       spname: columnmasters.SPName,
//       reportheading: columnmasters.ReportHeading,
//       headerpara1: columnmasters.HeaderPara1,
//       hpwithdate: columnmasters.HPWithDate,
//       status: columnmasters.Status,
//       secondfooter: columnmasters.SecondFooter,
//       secondheader: columnmasters.SecondHeader,
//       enaasondate: columnmasters.EnaAsOnDate,
//       enacustomers: columnmasters.EnaCustomers,
//       menuhead: columnmasters.MenuHead,
//       routename: columnmasters.RouteName,
//       rmmid: columnmasters.RMMId,
//       appids: columnmasters.AppIds.toString(),
//     };
//     return this.http
//       .post(this.gs_api + 'ColumnMaster', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public addEditColumnDet(columndets: ColumnDet) {
//     var myPostObject = {
//       id: columndets.id,
//       uuid: this.getUuid(),
//       refid: columndets.RefId,
//       orderno: columndets.OrderNo,
//       filedname: columndets.FiledName,
//       colname: columndets.ColName,
//       width: columndets.Width,
//       colformat: columndets.ColFormat,
//       coltype: columndets.ColType,
//       hidecol: columndets.HideCol,
//       colcaption: columndets.ColCaption,
//       hfcoltype: columndets.HFColType,
//       hfcolfieldname: columndets.HFColFieldName,
//       hfcolcaption: columndets.HFColCaption,
//       hcoltype: columndets.HColType,
//       hcolfieldname: columndets.HColFieldName,
//       hcolcaption: columndets.HColCaption,
//       status: columndets.Status,
//     };
//     return this.http
//       .post(this.gs_api + 'ColumnDet', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getColumnDet(ls_flag: any): Observable<ColumnDet[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<ColumnDet[]>(this.gs_api + 'ColumnDet', options);
//   }

//   public getColumnDetById(ls_flag: any, li_id: any): Observable<ColumnDet[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('li_id', li_id);
//     let options = { params: params };
//     return this.http.get<ColumnDet[]>(this.gs_api + 'ColumnDet', options);
//   }

//   public getColumnDetByIdDup(
//     ls_flag: any,
//     li_id: any,
//     li_refid: any
//   ): Observable<ColumnDet[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('li_id', li_id)
//       .set('li_refid', li_refid);
//     let options = { params: params };
//     return this.http.get<ColumnDet[]>(this.gs_api + 'ColumnDet', options);
//   }

//   public deleteColumnDet = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'ColumnDet', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getColumnMaster(ls_flag: any): Observable<ColumnMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<ColumnMaster[]>(this.gs_api + 'ColumnMaster', options);
//   }

//   public getColumnMasterById(li_id, ls_flag): Observable<ColumnMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', li_id);
//     let options = { params: params };
//     return this.http.get<ColumnMaster[]>(this.gs_api + 'ColumnMaster', options);
//   }

//   public deleteColumnMaster = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'ColumnMaster', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public deleteColumnAranger = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'ColumnAranger', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   p;
//   public addEditBank(banks: Bank) {
//     var myPostObject = {
//       id: banks.id,
//       uuid: this.getUuid(),
//       bankname: banks.BankName,
//       bankaddress: banks.BankAddress,
//     };
//     return this.http
//       .post(this.gs_api + 'Bank', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getBank(ls_flag): Observable<Bank[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<Bank[]>(this.gs_api + 'Bank', options);
//   }

//   public deleteBank = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Bank', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getOutStandingGuaranteesUpload(
//     ls_flag,
//     li_refid
//   ): Observable<OutStandingGuarantees[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_refid', li_refid);
//     let options = { params: params };
//     return this.http.get<OutStandingGuarantees[]>(
//       this.gs_api + 'OutStandingGuarantees',
//       options
//     );
//   }

//   public getFileUpload(
//     ls_tabname,
//     ls_flag,
//     li_refid
//   ): Observable<OutStandingGuarantees[]> {
//     const params = new HttpParams()
//       .set('ls_tabname', ls_tabname)
//       .set('ls_flag', ls_flag)
//       .set('ls_refid', li_refid);
//     let options = { params: params };
//     return this.http.get<OutStandingGuarantees[]>(
//       this.gs_api + 'FileUpload',
//       options
//     );
//   }

//   public addEditStockItems(stockitemss: StockItems) {
//     var myPostObject = {
//       id: stockitemss.id,
//       uuid: this.getUuid(),
//       itemname: stockitemss.ItemName,
//       itemcode: stockitemss.ItemCode,
//       barcode: stockitemss.BarCode,
//       nosperbox: stockitemss.NosPerBox,
//       status: stockitemss.Status,
//     };
//     return this.http
//       .post(this.gs_api + 'StockItems', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getStockItems(ls_flag): Observable<StockItems[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<StockItems[]>(this.gs_api + 'StockItems', options);
//   }

//   public deleteStockItems = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'StockItems', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getStock(ls_flag): Observable<Stock[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<Stock[]>(this.gs_api + 'Stock', options);
//   }

//   public getStockHistory(ls_flag, li_itemid): Observable<Stock[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('li_itemid', li_itemid);
//     let options = { params: params };
//     return this.http.get<Stock[]>(this.gs_api + 'Stock', options);
//   }

//   public sendPushNotificationIOS(deviceId: string, devplatform: String) {
//     // console.log(devplatform)

//     let url = 'https://fcm.googleapis.com/fcm/send';

//     let body1 = {
//       content_available: true,
//       mutable_content: true,
//       priority: 'high',
//       data: {
//         message: 'Offer!',
//         ids: 'omJuiizDLaM1hSjPjstQ',
//         status: 'done',
//       },
//       to: deviceId,
//     };
//     let headers: HttpHeaders = new HttpHeaders();
//     headers = headers.append('Content-Type', 'application/json');
//     headers = headers.append(
//       'Authorization',
//       'key=AAAAnK-HDjc:APA91bFhHlVUbqyr6UWDuV3zUMiC4_5Z-0OXyWNe_g7VmQaP-82-f19KEocUU1ch-lSQ62jECbXgYJYiK0YF1PG2XE5fv_qnq0x4FtzkHZlnbhzEfGUIh6Slv0PzRg2r9O1B5fu-TzpL'
//     );

//     return this.http
//       .post(url, body1, { headers })
//       .pipe(catchError(this.errorMgmt));
//   }

//   public sendPushNotificationAndroid(deviceId: string, devplatform: String) {
//     // console.log(devplatform)

//     let url = 'https://fcm.googleapis.com/fcm/send';

//     let body = {
//       priority: 'high',
//       data: {
//         click_action: 'FLUTTER_NOTIFICATION_CLICK',
//         id: '1',
//         status: 'done',
//       },
//       to: deviceId,
//     };
//     let headers: HttpHeaders = new HttpHeaders();
//     headers = headers.append('Content-Type', 'application/json');
//     headers = headers.append(
//       'Authorization',
//       'key=AAAAnK-HDjc:APA91bFhHlVUbqyr6UWDuV3zUMiC4_5Z-0OXyWNe_g7VmQaP-82-f19KEocUU1ch-lSQ62jECbXgYJYiK0YF1PG2XE5fv_qnq0x4FtzkHZlnbhzEfGUIh6Slv0PzRg2r9O1B5fu-TzpL'
//     );

//     return this.http
//       .post(url, body, { headers })
//       .pipe(catchError(this.errorMgmt));
//   }

//   public addEditQuotStatus(quotstatuss: QuotStatus) {
//     // console.log('test')
//     // console.log(quotstatuss.StatusDate)
//     // console.log(this.datePipe.transform(new Date(quotstatuss.StatusDate), "dd/MM/yyyy HH:mm:ss"))

//     var myPostObject = {
//       id: quotstatuss.id,
//       uuid: this.getUuid(),
//       refid: quotstatuss.Refid,
//       statusid: quotstatuss.StatusId,
//       userid: quotstatuss.UserId,
//       statusdate: quotstatuss.StatusDate
//         ? this.datePipe.transform(
//             new Date(quotstatuss.StatusDate),
//             'yyyy/MM/dd HH:mm:ss'
//           )
//         : null,
//       notes: quotstatuss.Notes,
//     };
//     return this.http
//       .post(this.gs_api + 'QuotStatus', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getQuotStatus(ls_flag, ls_euid): Observable<QuotStatus[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', ls_euid);
//     let options = { params: params };
//     return this.http.get<QuotStatus[]>(this.gs_api + 'QuotStatus', options);
//   }

//   public deleteQuotStatus = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'QuotStatus', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditStatusMaster(statusmasters: StatusMaster) {
//     var myPostObject = {
//       id: statusmasters.id,
//       uuid: this.getUuid(),
//       statustype: statusmasters.StatusType,
//       statusname: statusmasters.StatusName,
//       status: statusmasters.Status,
//       colorid: statusmasters.ColorId,
//     };
//     return this.http
//       .post(this.gs_api + 'StatusMaster', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getStatusMaster(ls_flag): Observable<StatusMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<StatusMaster[]>(this.gs_api + 'StatusMaster', options);
//   }

//   public deleteStatusMaster = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'StatusMaster', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditQuatationMaster(
//     quatationHeader: QuatationMaster,
//     quatationFooter: QuatationMaster,
//     detdata
//   ) {
//     var myPostObject = {
//       id: quatationHeader.id,
//       uuid: this.getUuid(),
//       customerid: quatationHeader.CustomerId,
//       quatationno: quatationHeader.QuatationNo,
//       quatationnoseq: quatationHeader.QuatationNoSeq,
//       quatationdate: quatationHeader.QuatationDate
//         ? this.datePipe.transform(
//             new Date(quatationHeader.QuatationDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       subject: quatationHeader.Subject,
//       heading: quatationHeader.Heading,
//       grandtotal: quatationFooter.GrandTotal,
//       footerprices: quatationFooter.FooterPrices,
//       footervalidity: quatationFooter.FooterValidity,
//       footerdelivery: quatationFooter.FooterDelivery,
//       footerpayment: quatationFooter.FooterPayment,
//       footerwarrenty: quatationFooter.FooterWarrenty,
//       footerinstallation: quatationFooter.FooterInstallation,
//       footermanufacturerid: quatationFooter.FooterManufacturerId,
//       isfooter: quatationFooter.IsFooter,
//       euid: quatationHeader.euid,
//       QuatationDet: detdata,
//     };
//     // console.log(myPostObject)
//     return this.http
//       .post(this.gs_api + 'QuatationMaster', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getQuatationMaster(ls_flag): Observable<QuatationMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);

//     let options = { params: params };
//     return this.http.get<QuatationMaster[]>(
//       this.gs_api + 'QuatationMaster',
//       options
//     );
//   }

//   public getQuatationMasterById(li_id, ls_flag): Observable<QuatationMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', li_id);
//     let options = { params: params };
//     return this.http.get<QuatationMaster[]>(
//       this.gs_api + 'QuatationMaster',
//       options
//     );
//   }

//   public deleteQuatationMaster = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'QuatationMaster', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditManufacturer(manufacturers: Manufacturer) {
//     var myPostObject = {
//       id: manufacturers.id,
//       uuid: this.getUuid(),
//       manufacturername: manufacturers.ManufacturerName,
//       description: manufacturers.Description,
//     };
//     return this.http
//       .post(this.gs_api + 'Manufacturer', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getManufacturer(ls_flag): Observable<Manufacturer[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<Manufacturer[]>(this.gs_api + 'Manufacturer', options);
//   }

//   public deleteManufacturer = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Manufacturer', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditCustomer(customerss: Customer) {
//     var myPostObject = {
//       id: customerss.id,
//       uuid: this.getUuid(),
//       customername: customerss.CustomerName,
//       address: customerss.Address,
//       place: customerss.Place,
//       contactperson: customerss.ContactPerson,
//       mobile: customerss.Mobile,
//       telephone: customerss.Telephone,
//       email: customerss.Email,
//     };
//     return this.http
//       .post(this.gs_api + 'Customers', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getCustomer(ls_flag): Observable<Customer[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<Customer[]>(this.gs_api + 'Customers', options);
//   }

//   public deleteCustomer = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Customers', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   // public addEditItem(items: Item) {
//   //   var myPostObject = {
//   //     id: items.id,
//   //     uuid: this.getUuid(),
//   //     itemname: items.ItemName,
//   //     model: items.Model,
//   //     price: items.Price,
//   //     description: items.Description,
//   //     unitid: items.UnitId,
//   //   };
//   //   // console.log(myPostObject)
//   //   return this.http
//   //     .post(this.gs_api + 'Item', myPostObject)
//   //     .pipe(catchError(this.errorMgmt));
//   // }

//   // public getItem(ls_flag): Observable<Item[]> {
//   //   const params = new HttpParams()
//   //     .set('ls_token', this.getUuid())
//   //     .set('ls_flag', ls_flag);
//   //   let options = { params: params };
//   //   return this.http.get<Item[]>(this.gs_api + 'Item', options);
//   // }

//   // public deleteItem = (li_id): Observable<any> => {
//   //   const params = new HttpParams()
//   //     .set('li_id', li_id)
//   //     .set('ls_token', this.getUuid());
//   //   let options = { params: params };
//   //   return this.http
//   //     .delete(this.gs_api + 'Item', options)
//   //     .pipe(catchError(this.errorMgmt));
//   // };

//   public addEditUnits(unitss: Units) {
//     var myPostObject = {
//       id: unitss.id,
//       uuid: this.getUuid(),
//       unitname: unitss.UnitName,
//       unitcode: unitss.UnitCode,
//     };
//     return this.http
//       .post(this.gs_api + 'Units', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getUnits(ls_flag): Observable<Units[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<Units[]>(this.gs_api + 'Units', options);
//   }

//   public deleteUnits = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Units', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditLawyerStdHR(lawyerstdhrs: LawyerStdHR) {
//     var myPostObject = {
//       id: lawyerstdhrs.id,
//       uuid: this.getUuid(),
//       currencyid: lawyerstdhrs.currencyid,
//       empid: lawyerstdhrs.empid,
//       standerdhourrate: lawyerstdhrs.StanderdHourRate,
//       status: lawyerstdhrs.Status,
//     };
//     return this.http
//       .post(this.gs_api + 'LawyerStdHR', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public addEditCreditInvoiceDet(creditinvoicedets: CreditInvoiceDet) {
//     var myPostObject = {
//       id: creditinvoicedets.id,
//       uuid: this.getUuid(),
//       refid: creditinvoicedets.refid,
//       invid: creditinvoicedets.invid,
//       invamt: creditinvoicedets.invamt,
//       creditamt: creditinvoicedets.creditamt,
//       balance: creditinvoicedets.balance,
//       notes: creditinvoicedets.notes,
//     };
//     return this.http
//       .post(this.gs_api + 'CreditInvoiceDet', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getCreditInvoiceDet(ls_flag): Observable<CreditInvoiceDet[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<CreditInvoiceDet[]>(
//       this.gs_api + 'CreditInvoiceDet',
//       options
//     );
//   }

//   public deleteCreditInvoiceDet = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid())
//       .set('ls_type', 'Det');

//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'CreditInvoice', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getLawyerStdHR(ls_flag, ids): Observable<LawyerStdHR[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('li_ids', ids);
//     let options = { params: params };
//     // console.log(options)
//     return this.http.get<LawyerStdHR[]>(this.gs_api + 'LawyerStdHR', options);
//   }

//   public deleteLawyerStdHR = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'LawyerStdHR', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditAppSettings(appsettingss: AppSettings) {
//     var myPostObject = {
//       id: appsettingss.id,
//       uuid: this.getUuid(),
//       dbversion: appsettingss.DbVersion,
//       defcurrencyid: appsettingss.DefCurrencyId,
//       defcashaccid: appsettingss.DefCashAccId,
//       defbankaccid: appsettingss.DefBankAccId,
//       defbankparentaccid: appsettingss.DefBankParentAccId,
//       companyid: appsettingss.companyid,
//       uploadpath: appsettingss.UploadPath,
//       reportapipath: appsettingss.ReportAPIPath,
//       defprinter: appsettingss.DefPrinter,
//       enadelosk: appsettingss.EnaDelOSK,
//       apiaddress: appsettingss.APIAddress,
//       defsertype: appsettingss.DefSerType,
//       defserwar: appsettingss.DefSerWar,
//       defserprio: appsettingss.DefSerPrio,
//       defassignto: appsettingss.DefAssignTo,
//       defserstatus: appsettingss.DefSerStatus,
//       defcusstatus: appsettingss.DefCusStatus,
//       defitemname: appsettingss.DefItemName,
//       defrecformat: appsettingss.DefRecFormat,
//       definvformat: appsettingss.DefInvFormat,
//       printrec: appsettingss.PrintRec,
//       printinv: appsettingss.PrintInv,
//       enawhatsapp: appsettingss.EnaWhatsApp,
//       enasms: appsettingss.EnaSMS,
//       whstappcount: appsettingss.WhstappCount,
//       smscount: appsettingss.SMSCount,
//       enaaccounts: appsettingss.EnaAccounts,
//       enaticketno: appsettingss.EnaTicketNo,
//       enacallcode: appsettingss.EnaCallCode,
//       enanewcallcode: appsettingss.EnaNewCallCode,
//       enamultibarcode: appsettingss.EnaMultiBarcode,
//       barcodeformat: appsettingss.BarcodeFormat,
//       barcodetype: appsettingss.BarcodeType,
//       defstocktype: appsettingss.DefStockType,
//       defcategory: appsettingss.DefCategory,
//       defmanufacture: appsettingss.DefManufacture,
//       defunitid: appsettingss.DefUnitId,
//       deftaxid: appsettingss.DefTaxId,
//       enaautoitemcode: appsettingss.EnaAutoItemCode,
//       deftaxincpur: appsettingss.DefTaxIncPur,
//       deftaxincsale: appsettingss.DefTaxIncSale,
//       deftaxincservice: appsettingss.DefTaxIncService,
//       defsalesaccid: appsettingss.DefSalesAccId,
//       defpuraccid: appsettingss.DefPurAccId,
//       defseraccid: appsettingss.DefSerAccId,
//       taxinputaccid: appsettingss.TaxInputAccId,
//       taxoutputaccid: appsettingss.TaxOutputAccId,
//       freightaccid: appsettingss.FreightAccId,
//       discountpaidaccid: appsettingss.DiscountPaidAccId,
//       discountreceivedaccid: appsettingss.DiscountReceivedAccId,
//       gencusaccid: appsettingss.GenCusAccId,
//       defstockaccid: appsettingss.DefStockAccId,
//       defdebtorsaccid: appsettingss.DefDebtorsAccId,
//       defcreditorsaccid: appsettingss.DefCreditorsAccId,
//       defitemwise: appsettingss.DefItemWise,
//       enaitembatchexpdate: appsettingss.EnaItemBatchExpDate,
//       enaitembatchcol1: appsettingss.EnaItemBatchCol1,
//       enaitembatchcol2: appsettingss.EnaItemBatchCol2,
//       defitembatchcol1: appsettingss.DefItemBatchCol1,
//       defitembatchcol2: appsettingss.DefItemBatchCol2,
//       enaitembarcode: appsettingss.EnaItemBarcode,
//       enaitemorderby: appsettingss.EnaItemOrderBy,
//       enaitemserviceprice: appsettingss.EnaItemServicePrice,
//       roundoffrecaccid: appsettingss.RoundOffRecAccId,
//       roundoffpaidaccid: appsettingss.RoundOffPaidAccId,
//       adjustmentaccid: appsettingss.AdjustmentAccId,
//       defpurmopid: appsettingss.DefPurMopId,
//       decimalplaces: appsettingss.DecimalPlaces,
//       defcountrycode: appsettingss.DefCountryCode,
//       enaservicecharge: appsettingss.EnaServiceCharge,
//       enainventory: appsettingss.EnaInventory,
//       enasparestatusinvoice: appsettingss.EnaSpareStatusInvoice,
//       enaautoroundoffinvoice: appsettingss.EnaAutoRoundOffInvoice,
//       enaestimateamtaccountable: appsettingss.EnaEstimateAmtAccountable,
//       defservicechargeitemid: appsettingss.DefServiceChargeItemId,
//       definvoiceuserid: appsettingss.DefInvoiceUserId,
//       definvoicefinalstatusid: appsettingss.DefInvoiceFinalStatusId,
//       definvoicecusstatusid: appsettingss.DefInvoiceCusStatusId,
//       enainvoiceclosestatus: appsettingss.EnaInvoiceCloseStatus,
//       hideinvoiceservicetab: appsettingss.HideInvoiceServiceTab,
//       defsalesquicksale: appsettingss.DefSalesQuickSale,
//       defsalesgeneralcusid: appsettingss.DefSalesGeneralCusId,
//       defsalemode: appsettingss.DefSaleMode,
//       salebillformat: appsettingss.SaleBillFormat,
//       estimatebillformat: appsettingss.EstimateBillFormat,
//       noofcopy: appsettingss.NoOfCopy,
//       enasaleautoroundoff: appsettingss.EnaSaleAutoRoundOff,
//       enasalesonlystockeditems: appsettingss.EnaSalesOnlyStockedItems,
//       enasalescheckbalqty: appsettingss.EnaSalesCheckBalQty,
//       defsalemopid: appsettingss.DefSaleMOPId,
//       enasalesdenowindow: appsettingss.EnaSalesDenoWindow,
//       enasalesprevioussrates: appsettingss.EnaSalesPreviousSRates,
//       enasalessavebillnowithnar: appsettingss.EnaSalesSaveBillNoWithNar,
//       enasalespaid: appsettingss.EnaSalesPaid,
//       defsalediscountper: appsettingss.DefSaleDiscountPer,
//       defsaletransmode: appsettingss.DefSaleTransMode,
//       defsalesupplyplace: appsettingss.DefSaleSupplyPlace,
//       defsalevehicleno: appsettingss.DefSaleVehicleNo,
//       DefItemGroupIds: appsettingss.DefItemGroupIds.toString(),
//       defother1accid: appsettingss.DefOther1AccId,
//       defother2accid: appsettingss.DefOther2AccId,
//       defother3accid: appsettingss.DefOther3AccId,
//       defother4accid: appsettingss.DefOther4AccId,
//       defcautiondeposit: appsettingss.DefCautionDeposit,
//       defsparestatus: appsettingss.DefSpareStatus,
//       enadetailedreport: appsettingss.EnaDetailedReport,
//       enavacateon: appsettingss.EnaVacateOn,
//       enaitemlocation: appsettingss.EnaItemLocation,
//       enagroupinaccounts: appsettingss.EnaGroupinAccounts,
//       accgroupname: appsettingss.AccGroupName,
//       wawelcomemsg: appsettingss.WAWelcomeMsg,
//       wathankyoumsg: appsettingss.WAThankYouMsg,
//       defitemlocationid: appsettingss.DefItemLocationId,
//       enasparerequest: appsettingss.EnaSpareRequest,
//       defservicesortby: appsettingss.DefServiceSortBy,
//       enaitemnamevalidate: appsettingss.EnaItemNameValidate,
//       enavoucherprint: appsettingss.EnaVoucherPrint,
//       enavoucherprintpartybankdet: appsettingss.EnaVoucherPrintPartyBankDet,
//       defsalesreturnaccid: appsettingss.DefSalesReturnAccId,
//       defpurchasereturnaccid: appsettingss.DefPurchaseReturnAccId,
//       enadefserviceprint: appsettingss.EnaDefServicePrint,
//       enadefserviceprintlabel: appsettingss.EnaDefServicePrintLabel,
//       enadefservicewhatsapp: appsettingss.EnaDefServiceWhatsApp,
//       defservicelabelprintqty: appsettingss.DefServiceLabelPrintQty,
//       DefSpareSaleAccId: appsettingss.DefSpareSaleAccId,
//       enasalesbarcodescan: appsettingss.EnaSalesBarcodeScan,
//       BarcodeLastNo: appsettingss.BarcodeLastNo,
//       DisSalesRateEdit: appsettingss.DisSalesRateEdit,
//     };
//     return this.http
//       .post(this.gs_api + 'AppSettings', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getAppSettings(ls_flag, ls_euid): Observable<AppSettings[]> {
//     const params = new HttpParams()
//       .set('ls_token', ls_euid == '' ? this.getUuid() : ls_euid)
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<AppSettings[]>(this.gs_api + 'AppSettings', options);
//   }

//   public deleteAppSettings = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'AppSettings', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getCreditById(li_id, ls_flag): Observable<any[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', li_id);
//     let options = { params: params };
//     return this.http.get<any[]>(this.gs_api + 'CreditInvoice', options);
//   }

//   public addEditOpBal(opbals: OpBal) {
//     var myPostObject = {
//       id: opbals.id,
//       uuid: this.getUuid(),
//       accountid: opbals.AccountId,
//       debit: opbals.Debit,
//       credit: opbals.Credit,
//       narration: opbals.Narration,
//       companyid: opbals.CompanyId,
//       status: opbals.Status,
//     };

//     return this.http
//       .post(this.gs_api + 'OpBal', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getOpBal(ls_flag: any): Observable<OpBal[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<OpBal[]>(this.gs_api + 'OpBal', options);
//   }

//   public deleteOpBal = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'OpBal', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public deleteInvoice = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Invoice', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditAccounts(accountss: Accounts) {
//     var myPostObject = {
//       id: accountss.id,
//       uuid: this.getUuid(),
//       parentid: accountss.parentid,
//       moduleid: 0,
//       accname: accountss.accname,
//       acccode: accountss.acccode,
//       ledger: accountss.ledger,
//       endaccount: 'None',
//       status: 'E',
//       bankname: accountss.bankname,
//       bankacno: accountss.bankacno,
//       bankactype: accountss.bankactype,
//       bankaddress: accountss.bankaddress,
//       chqfrom: accountss.chqfrom,
//       chqto: accountss.chqto,
//       branch: accountss.branch,
//       beneficiary: accountss.beneficiary,
//       currency: accountss.currency,
//       swiftcode: accountss.swiftcode,
//       iban: accountss.iban,
//       stocktype: accountss.StockType,
//     };
//     // console.log(myPostObject)
//     return this.http
//       .post(this.gs_api + 'Accounts', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getAccounts(ls_flag): Observable<any[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<any[]>(this.gs_api + 'Accounts', options);
//   }

//   public addEditColors(colorss: Colors) {
//     var myPostObject = {
//       id: colorss.id,
//       uuid: this.getUuid(),
//       colorname: colorss.ColorName,
//       colorcode: colorss.ColorCode,
//       companyid: colorss.CompanyId,
//       status: colorss.Status,
//     };
//     return this.http
//       .post(this.gs_api + 'Colors', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getColors(ls_flag: any): Observable<Colors[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<Colors[]>(this.gs_api + 'Colors', options);
//   }

//   public deleteColors = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Colors', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getAccountsUnderParent(ls_flag, parid): Observable<any[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('li_parentid', parid);
//     let options = { params: params };
//     return this.http.get<any[]>(this.gs_api + 'Accounts', options);
//   }

//   public deleteAccounts = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Accounts', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditCaseDocs(casedocss: CaseDocs) {
//     var myPostObject = {
//       id: casedocss.id,
//       uuid: this.getUuid(),
//       caseid: casedocss.CaseId,
//       docname: casedocss.DocName,
//     };
//     return this.http
//       .post(this.gs_api + 'CaseDocs', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getCaseDocs(ls_flag, ls_casid): Observable<CaseDocs[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_caseid', ls_casid);
//     let options = { params: params };
//     // console.log(options)
//     return this.http.get<CaseDocs[]>(this.gs_api + 'CaseDocs', options);
//   }

//   public deleteCaseDocs = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'CaseDocs', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public deleteFile = (ls_filename): Observable<any> => {
//     const params = new HttpParams().set('li_id', ls_filename);
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'FileUpload', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditTransferTS(casefrm, caseto, timeids) {
//     var myPostObject = {
//       id: 0,
//       uuid: this.getUuid(),
//       caseidfrm: casefrm,
//       caseidto: caseto,
//       timesheetid: timeids,
//     };

//     return this.http
//       .post(this.gs_api + 'TransferTS', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public transferCaseTimeSheet = (
//     casefrm,
//     caseto,
//     timeids
//   ): Observable<any> => {
//     let lsdata = [];
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', 'I')
//       .set('li_casefrm', casefrm)
//       .set('li_caseto', caseto)
//       .set('li_timeids', timeids);
//     let options = { params: params };
//     let headers = new Headers();
//     headers.append('Content-Type', 'application/json');

//     return this.http
//       .get(this.gs_api + 'CaseTimeSheet', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditCaseLawyers(caselawyerss: CaseLawyers) {
//     var myPostObject = {
//       id: caselawyerss.id,
//       uuid: this.getUuid(),
//       caseid: caselawyerss.caseid,
//       empid: caselawyerss.empid,
//       status: caselawyerss.status,
//       flexiblerate: caselawyerss.flexiblerate,
//     };
//     return this.http
//       .post(this.gs_api + 'CaseLawyers', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getCaseLawyers(ls_flag): Observable<CaseLawyers[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<CaseLawyers[]>(this.gs_api + 'CaseLawyers', options);
//   }

//   public deleteCaseLawyers = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'CaseLawyers', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditMenuMaster(menumasters: MenuMaster) {
//     var myPostObject = {
//       id: menumasters.id,
//       uuid: this.getUuid(),
//       menuname: menumasters.MenuName,
//       parentid: menumasters.ParentId,
//       hassubmenu: menumasters.HasSubMenu,
//       routename: menumasters.RouteName,
//       iconname: menumasters.IconName,
//       translatemenuname: menumasters.TranslateMenuName,
//       sort: menumasters.sort,
//       moduleid: menumasters.ModuleId,
//     };
//     return this.http
//       .post(this.gs_api + 'MenuMaster', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public async getMenu(ls_flag): Promise<any[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };

//     return await this.http
//       .get<any[]>(this.gs_api + 'MenuMaster', options)
//       .toPromise();
//   }

//   public getMenuMaster(ls_flag): Observable<MenuMaster[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<MenuMaster[]>(this.gs_api + 'MenuMaster', options);
//   }

//   public getMenuMasterTest(ls_flag): Observable<Menu[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<Menu[]>(this.gs_api + 'MenuMaster', options);
//   }

//   public deleteMenuMaster = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'MenuMaster', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditEmployee(employees: Employee) {
//     var myPostObject = {
//       id: employees.id,
//       uuid: this.getUuid(),
//       departid: employees.departid,
//       desiid: employees.desiid,
//       empname: employees.empname,
//       username: employees.empusername,
//       empno: employees.empno,
//       empnoseq: employees.empnoseq,
//       mob: employees.mob,
//       dob: employees.dob
//         ? this.datePipe.transform(
//             new Date(employees.dob).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       email: employees.email,
//       civilid: employees.civilid,
//       password: employees.emppassword,
//       graduation: employees.graduation,
//       graduationdate: employees.graduationdate
//         ? this.datePipe.transform(
//             new Date(employees.graduationdate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       telextention: employees.telextention,
//       hiredate: employees.hiredate
//         ? this.datePipe.transform(
//             new Date(employees.hiredate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       fee: employees.fee,
//       memo: employees.memo,
//       status: employees.status,
//       stdhourrate: employees.stdhourrate,
//       isdeleted: employees.isDeleted,
//       dashtype: employees.dashtype,
//       emptype: employees.emptype,
//       loadowndata: employees.loadowndata,
//       blockclosedserentry: employees.blockclosedserentry,
//     };
//     return this.http
//       .post(this.gs_api + 'Employee', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getEmployee(): Observable<Employee[]> {
//     const params = new HttpParams().set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http.get<Employee[]>(this.gs_api + 'Employee', options);
//   }

//   public getEmployeeType(lsType): Observable<Employee[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_Type', lsType);
//     let options = { params: params };
//     return this.http.get<Employee[]>(this.gs_api + 'Employee', options);
//   }

//   public deleteEmployee = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Employee', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditCurrency(currencys: Currency) {
//     var myPostObject = {
//       id: currencys.id,
//       uuid: this.getUuid(),
//       currencyname: currencys.currencyname,
//       currencycode: currencys.currencycode,
//       currencyrate: currencys.currencyrate,
//       status: currencys.status,
//     };

//     // console.log(myPostObject)
//     return this.http
//       .post(this.gs_api + 'Currency', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getMenuRights = (li_userid): Observable<MenuRights[]> => {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('li_userid', li_userid);
//     let options = { params: params };
//     return this.http.get<MenuRights[]>(this.gs_api + 'MenuRights', options);
//   };

//   public addEditMenuRights(data) {
//     for (var i = 0; i < data.length; i++) {
//       data[i].uuid = this.getUuid();
//     }

//     return this.http
//       .post(this.gs_api + 'MenuRights', data)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public OrderByReportMenu(data) {
//     for (var i = 0; i < data.length; i++) {
//       data[i].uuid = this.getUuid();
//     }

//     // console.log(data)

//     return this.http
//       .post(this.gs_api + 'ReportMenuDet', data)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public addEditJouranlEntry(frmdata, data) {
//     var cart = [];

//     // console.log(data);
//     for (var i = 0; i < data.length; i++) {
//       var element = {};
//       if (data[i]['unPaidData'] == undefined) {
//         element['tagDatadet'] = [];
//       } else {
//         if (data[i]['unPaidData'].length > 0) {
//           data[i]['unPaidData'].forEach((entry) => {
//             entry.Balance = entry.Bal;
//           });
//         }
//         element['tagDatadet'] = data[i]['unPaidData'];
//       }

//       element['uuid'] = this.getUuid();
//       element['accountid'] = data[i]['AccId'];
//       element['signedamount'] = data[i]['Amount'];

//       if (data[i]['EntryType'].toString() == 'Receiver') {
//         element['dorc'] = 'D';
//       } else if (data[i]['EntryType'].toString() == 'Payer') {
//         element['dorc'] = 'C';
//       }

//       element['voucherid'] = frmdata.id;
//       element['naration'] = frmdata.Narration;
//       (element['accdate'] = frmdata.VoucherDate
//         ? this.datePipe.transform(
//             new Date(frmdata.VoucherDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null),
//         // console.log(element)
//         cart.push(element);
//     }

//     // purdate: purchasemastersHeader.PurDate
//     // ? this.datePipe.transform(
//     //     new Date(purchasemastersHeader.PurDate).toISOString(),
//     //     'yyyy-MM-dd'
//     //   )
//     // : null,

//     // console.log(cart);
//     // return data;
//     return this.http
//       .post(this.gs_api + 'AccBook', cart)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getCurrency(): Observable<Currency[]> {
//     const params = new HttpParams().set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http.get<Currency[]>(this.gs_api + 'Currency', options);
//   }

//   public deleteCurrency = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Currency', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditSupplierType(suppliertypes: SupplierType) {
//     var myPostObject = {
//       id: suppliertypes.id,
//       uuid: this.getUuid(),
//       typename: suppliertypes.typename,
//     };
//     return this.http
//       .post(this.gs_api + 'SupplierType', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getSupplierType(): Observable<SupplierType[]> {
//     const params = new HttpParams().set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http.get<SupplierType[]>(this.gs_api + 'SupplierType', options);
//   }

//   public deleteSupplierType = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'SupplierType', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditCrystalData(
//     creuid,
//     ishandf,
//     rptname,
//     rptheader,
//     para1,
//     para2,
//     para3,
//     para4,
//     para5
//   ) {
//     var myPostObject = {
//       id: 0,
//       uuid: this.getUuid(),
//       euid: creuid,
//       crsql: '',
//       ishandf: ishandf,
//       rptname: rptname,
//       rptheader: rptheader,
//       para1: para1,
//       para2: para2,
//       para3: para3,
//       para4: para4,
//       para5: para5,
//     };
//     return this.http
//       .post(this.gs_api + 'CrystalData', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getUserActivityLog(
//     asflag,
//     ai_userid,
//     ad_dtfrm,
//     ad_dtto
//   ): Observable<UserActivityLog[]> {
//     const params = new HttpParams()
//       .set('ls_flag', asflag)
//       .set('ls_token', this.getUuid())
//       .set('li_userid', ai_userid)
//       .set('ls_datefrm', ad_dtfrm)
//       .set('ls_dateto', ad_dtto);
//     let options = { params: params };
//     return this.http.get<UserActivityLog[]>(
//       this.gs_api + 'UserActivityLog',
//       options
//     );
//   }

//   public getAccountsReports(
//     asflag,
//     ai_mopid,
//     ad_dtfrm,
//     ad_dtto,
//     ai_id
//   ): Observable<any[]> {
//     const params = new HttpParams()
//       .set('ls_flag', asflag)
//       .set('ls_token', this.getUuid())
//       .set('li_mopid', ai_mopid)
//       .set('ls_datefrm', ad_dtfrm)
//       .set('ls_dateto', ad_dtto)
//       .set('li_id', ai_id);
//     let options = { params: params };
//     return this.http.get<any[]>(this.gs_api + 'Reports', options);
//   }

//   public addEditReminderGroups(remindergroupss: ReminderGroups) {
//     var myPostObject = {
//       id: remindergroupss.id,
//       uuid: this.getUuid(),
//       groupname: remindergroupss.GroupName,
//       companyid: remindergroupss.CompanyId,
//     };
//     return this.http
//       .post(this.gs_api + 'ReminderGroups', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getReminderGroups(ls_flag: any): Observable<ReminderGroups[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<ReminderGroups[]>(
//       this.gs_api + 'ReminderGroups',
//       options
//     );
//   }

//   public deleteReminderGroups = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'ReminderGroups', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public deleteReminderDet = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'ReminderDet', options)
//       .pipe(catchError(this.errorMgmt));
//   };
//   public addEditReminder(remindersHeader: Reminder, detdata) {
//     // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

//     if (remindersHeader.RemiderTime !== null) {
//       var dt = new Date('1990-01-01 ' + remindersHeader.RemiderTime);
//       dt = new Date(
//         new Date().toISOString().slice(0, 10) +
//           ' ' +
//           remindersHeader.RemiderTime
//       );
//     }

//     if (remindersHeader.EndTime !== null) {
//       var dtend = new Date('1990-01-01 ' + remindersHeader.EndTime);
//       dtend = new Date(
//         new Date().toISOString().slice(0, 10) + ' ' + remindersHeader.EndTime
//       );
//     }

//     const utcRemiderDate = remindersHeader.RemiderDate
//       ? this.datePipe.transform(
//           new Date(remindersHeader.RemiderDate),
//           "yyyy-MM-dd'T'HH:mm:ss.SSSZ"
//         )
//       : null;
//     const offsetutcRemiderDate = utcRemiderDate.replace(
//       /(\d{2})(\d{2})$/,
//       '$1:$2'
//     );

//     const utcRemiderTime = dt
//       ? this.datePipe.transform(new Date(dt), "yyyy-MM-dd'T'HH:mm:ss.SSSZ")
//       : null;
//     const offsetutcRemiderTime = utcRemiderTime
//       ? utcRemiderTime.replace(/(\d{2})(\d{2})$/, '$1:$2')
//       : null;

//     const utcEndDate = remindersHeader.EndDate
//       ? this.datePipe.transform(
//           new Date(remindersHeader.EndDate),
//           "yyyy-MM-dd'T'HH:mm:ss.SSSZ"
//         )
//       : null;
//     const offsetutcEndDate = utcEndDate
//       ? utcEndDate.replace(/(\d{2})(\d{2})$/, '$1:$2')
//       : null;

//     const utcEndTime = dtend
//       ? this.datePipe.transform(new Date(dtend), "yyyy-MM-dd'T'HH:mm:ss.SSSZ")
//       : null;
//     const offsetutcEndTime = utcEndTime
//       ? utcEndTime.replace(/(\d{2})(\d{2})$/, '$1:$2')
//       : null;

//     var myPostObject = {
//       id: remindersHeader.id,
//       uuid: this.getUuid(),
//       subject: remindersHeader.Subject,
//       message: remindersHeader.Message,
//       remiderdate: offsetutcRemiderDate,
//       remidertime: offsetutcRemiderTime,
//       endless: remindersHeader.EndLess,
//       enddate: offsetutcEndDate,
//       endtime: offsetutcEndTime,
//       scheduletype: remindersHeader.ScheduleType,
//       scheduleinterval: parseInt(remindersHeader.ScheduleInterval.toString()),
//       remidertype: remindersHeader.RemiderType,
//       userid: remindersHeader.UserId,
//       companyid: remindersHeader.CompanyId,
//       status: remindersHeader.Status,
//       euid: remindersHeader.euid,
//       ReminderDet: detdata,
//     };
//     // console.log(myPostObject);
//     return this.http
//       .post(this.gs_api + 'Reminder', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getReminder(ls_flag: any): Observable<any[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<any[]>(this.gs_api + 'Reminder', options);
//   }

//   public getReminderById(li_id, ls_flag): Observable<Reminder[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', li_id);
//     let options = { params: params };
//     return this.http.get<Reminder[]>(this.gs_api + 'Reminder', options);
//   }
//   public addEditReminderUsers(reminderuserss: ReminderUsers) {
//     var myPostObject = {
//       id: reminderuserss.id,
//       uuid: this.getUuid(),
//       name: reminderuserss.Name,
//       countrycode: reminderuserss.CountryCode,
//       mobile: reminderuserss.Mobile,
//       companyid: reminderuserss.CompanyId,
//       status: reminderuserss.Status,
//       isme: reminderuserss.isMe,
//       groupids: reminderuserss.GroupIds.toString(),
//     };
//     return this.http
//       .post(this.gs_api + 'ReminderUsers', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public addEditReminderPersonalUsers(reminderuserss: ReminderPersonalUsers) {
//     var myPostObject = {
//       id: reminderuserss.id,
//       uuid: this.getUuid(),
//       name: reminderuserss.Name,
//       countrycode: reminderuserss.CountryCode,
//       mobile: reminderuserss.Mobile,
//       id1: reminderuserss.id1,
//       name1: reminderuserss.Name1,
//       countrycode1: reminderuserss.CountryCode1,
//       mobile1: reminderuserss.Mobile1,
//       id2: reminderuserss.id2,
//       name2: reminderuserss.Name2,
//       countrycode2: reminderuserss.CountryCode2,
//       mobile2: reminderuserss.Mobile2,
//       id3: reminderuserss.id3,
//       name3: reminderuserss.Name3,
//       countrycode3: reminderuserss.CountryCode3,
//       mobile3: reminderuserss.Mobile3,

//       companyid: reminderuserss.CompanyId,
//       status: reminderuserss.Status,
//       isme: reminderuserss.isMe,
//       groupids: reminderuserss.GroupIds,
//     };
//     return this.http
//       .post(this.gs_api + 'ReminderPersonal', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getReminderUsersById(
//     ls_flag: any,
//     li_id
//   ): Observable<ReminderUsers[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag)
//       .set('ls_euid', li_id);
//     let options = { params: params };
//     return this.http.get<ReminderUsers[]>(
//       this.gs_api + 'ReminderUsers',
//       options
//     );
//   }

//   downloadFile(fileUrl): Observable<Blob> {
//     // Replace the URL with your actual server URL
//     // const fileUrl =
//     //   'https://www.smartappstores.com/ServiceAPI/Uploads/SmartMessenger.xlsx';

//     // Set headers if needed (e.g., for authentication)
//     const headers = new HttpHeaders();

//     // Make the request to download the file
//     return this.http.get(fileUrl, { responseType: 'blob', headers });
//   }

//   downloadZipFile(filename: string) {
//     return this.http.get(filename, {
//       responseType: 'arraybuffer',
//     });
//   }

//   downloadLargeZipFile(url: string): void {
//     this.http.get(url, { responseType: 'blob' }).subscribe((blob) => {
//       const blobUrl = window.URL.createObjectURL(blob);
//       const anchor = document.createElement('a');
//       anchor.href = blobUrl;
//       anchor.download = 'SmartTrader.zip';
//       anchor.click();
//       window.URL.revokeObjectURL(blobUrl); // Clean up
//     });
//   }

//   public getReminderUsers(ls_flag: any): Observable<ReminderUsers[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<ReminderUsers[]>(
//       this.gs_api + 'ReminderUsers',
//       options
//     );
//   }

//   public deleteReminderUsers = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'ReminderUsers', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public deleteReminder = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Reminder', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditClients(clientss: Clients) {
//     var myPostObject = {
//       id: clientss.id,
//       uuid: this.getUuid(),
//       bistypeid: clientss.bistypeid,
//       clientno: clientss.clientno,
//       clientnoseq: clientss.clientnoseq,
//       euid: clientss.euid,
//       name: clientss.name,
//       address: clientss.address,
//       contactperson: clientss.contactperson,
//       telphone: clientss.telphone,
//       mob: clientss.mob,
//       email: clientss.email,
//       opendate: clientss.opendate
//         ? this.datePipe.transform(
//             new Date(clientss.opendate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       memo: clientss.memo,
//     };
//     return this.http
//       .post(this.gs_api + 'Clients', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getClients(): Observable<Clients[]> {
//     const params = new HttpParams().set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http.get<Clients[]>(this.gs_api + 'Clients', options);
//   }

//   public deleteClients = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Clients', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditvin_tables(vin_tabless: vin_tables) {
//     var myPostObject = {
//       id: vin_tabless.id,
//       uuid: this.getUuid(),
//       table_name: vin_tabless.table_name,
//       max_id: vin_tabless.max_id,
//       bill_no: vin_tabless.bill_no,
//     };
//     return this.http
//       .post(this.gs_api + 'vin_tables', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getvin_tables(ls_flag): Observable<vin_tables[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<vin_tables[]>(this.gs_api + 'vin_tables', options);
//   }

//   public deletevin_tables = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'vin_tables', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public deleteCaseFile = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'CaseFile', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditDepartment(departments: Department) {
//     var myPostObject = {
//       id: departments.id,
//       uuid: this.getUuid(),
//       departname: departments.departname,
//       status: departments.status,
//     };
//     return this.http
//       .post(this.gs_api + 'Department', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getDepartment(): Observable<Department[]> {
//     const params = new HttpParams().set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http.get<Department[]>(this.gs_api + 'Department', options);
//   }

//   public deleteDepartment = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Department', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditDesignation(designations: Designation) {
//     var myPostObject = {
//       id: designations.id,
//       uuid: this.getUuid(),
//       designationname: designations.designationname,
//       status: designations.status,
//     };
//     return this.http
//       .post(this.gs_api + 'Designation', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getDesignation(): Observable<Designation[]> {
//     const params = new HttpParams().set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http.get<Designation[]>(this.gs_api + 'Designation', options);
//   }

//   public deleteDesignation = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Designation', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getAgrementType(): Observable<AgrementType[]> {
//     return this.http.get<AgrementType[]>(this.gs_api + 'AgrementType');
//   }

//   public getBusinessType(): Observable<BusinessType[]> {
//     return this.http.get<BusinessType[]>(this.gs_api + 'BusinessType');
//   }

//   public addEditAppPrefix(appprefixs: AppPrefix) {
//     var myPostObject = {
//       id: appprefixs.Id,
//       uuid: this.getUuid(),
//       typeid: appprefixs.TypeId,
//       autonumber: appprefixs.AutoNumber,
//       prefix1: appprefixs.Prefix1,
//       prefix2: appprefixs.Prefix2,
//       orderdby: appprefixs.OrderdBy,
//       companyid: appprefixs.CompanyId,
//     };
//     return this.http
//       .post(this.gs_api + 'AppPrefix', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getAppPrefixtype(): Observable<AppPrefixtype[]> {
//     const params = new HttpParams().set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http.get<AppPrefixtype[]>(
//       this.gs_api + 'AppPrefixtype',
//       options
//     );
//   }

//   public deleteAppPrefixtype = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'AppPrefixtype', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getAppPrefix(): Observable<AppPrefix[]> {
//     const params = new HttpParams().set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http.get<AppPrefix[]>(this.gs_api + 'AppPrefix', options);
//   }

//   public addEditAppPrefixtype(appprefixtypes: AppPrefixtype) {
//     var myPostObject = {
//       id: appprefixtypes.id,
//       uuid: this.getUuid(),
//       typename: appprefixtypes.typename,
//     };
//     return this.http
//       .post(this.gs_api + 'AppPrefixtype', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getAppPrefixByType(ls_type): Observable<AppPrefix[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_type', ls_type);
//     let options = { params: params };

//     return this.http.get<AppPrefix[]>(this.gs_api + 'AppPrefix', options);
//   }

//   public getAppPrefixAccounts(li_parid): Observable<AppPrefix[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('li_parentid', li_parid);
//     let options = { params: params };

//     return this.http.get<AppPrefix[]>(this.gs_api + 'AppPrefix', options);
//   }

//   public deleteAppPrefix = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'AppPrefix', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditModuleMaster(modulemasters: ModuleMaster) {
//     var myPostObject = {
//       id: modulemasters.Id,
//       uuid: this.getUuid(),
//       menuname: modulemasters.MenuName,
//       modulename: modulemasters.ModuleName,
//       status: modulemasters.status,
//     };
//     // console.log(myPostObject);
//     return this.http
//       .post(this.gs_api + 'ModuleMaster', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getModuleMaster(): Observable<ModuleMaster[]> {
//     const params = new HttpParams().set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http.get<ModuleMaster[]>(this.gs_api + 'ModuleMaster', options);
//   }

//   public deleteModuleMaster = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'ModuleMaster', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getCategories(): Observable<Category[]> {
//     const headers = new HttpHeaders().append(
//       'Content-Type',
//       'application/json'
//     );
//     return this.http.get<Category[]>(this.gs_api + 'User', { headers });
//   }

//   // public getCustomers(): Observable<Customers[]> {
//   //     return this.http.get<Customers[]>(this.gs_api + 'User');
//   // }

//   public getJobs(): Observable<AssignJob[]> {
//     return this.http.get<AssignJob[]>(this.gs_api + 'Job');
//   }

//   public addEditPayPal(paypals: any) {
//     var myPostObject = {
//       id: paypals.id,
//       uuid: this.getUuid(),
//       tranid: paypals.TranId,
//       trandate: paypals.TranDate
//         ? this.datePipe.transform(
//             new Date(paypals.TranDate).toISOString(),
//             'yyyy-MM-dd'
//           )
//         : null,
//       intent: paypals.Intent,
//       payername: paypals.PayerName,
//       address1: paypals.Address1,
//       address2: paypals.Address2,
//       address3: paypals.Address3,
//       pin: paypals.Pin,
//       email: paypals.Email,
//       curcode: paypals.CurCode,
//       itemname: paypals.ItemName,
//       amount: paypals.Amount,
//       status: paypals.Status,
//     };
//     return this.http
//       .post(this.gs_api + 'PayPal', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getPayPal(ls_flag: any): Observable<PayPal[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<PayPal[]>(this.gs_api + 'PayPal', options);
//   }

//   public deletePayPal = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'PayPal', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getLogin(data): Observable<any> {
//     const headers = new HttpHeaders().append(
//       'Content-Type',
//       'application/json'
//     );

//     const params = new HttpParams()
//       .set('username', data.username)
//       .set('password', data.password)
//       .set('loginFrom', 'Web');

//     return this.http.get(this.gs_api + 'Login', { headers, params });
//   }

//   public getUsers(): Observable<any[]> {
//     return this.http.get<any[]>(this.gs_api + 'Users');
//   }

//   public addDriver(employees: Employees) {
//     var myPostObject = {
//       name: employees.name,
//       email: employees.email,
//       mobile: employees.mobile,
//       pwd: employees.pwd,
//       department: employees.department,
//       designation: employees.designation,
//       isActive: 'Y',
//       lastStatus: 'User Created',
//       latitude: 0,
//       longitude: 0,
//       startTime: '',
//       devPlatform: '',
//       deviceid: '',
//     };
//     // console.log(myPostObject);
//     this.http.post(this.gs_api + 'User', myPostObject).subscribe(
//       (response) => {
//         debugger;
//         // console.log(response);
//       },
//       (err: HttpErrorResponse) => {
//         console.log(err);
//       }
//     );
//     // this.http.post<any>('http://localhost:83/api/Categories', myPostObject).subscribe(data => {
//     //     console.log(data);
//     // })
//   }

//   public deleteDriver = (li_id): Observable<any> => {
//     const params = new HttpParams().set('li_id', li_id);
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'User', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getDashBoard(ls_type): Observable<any[]> {
//     const params = new HttpParams()
//       .set('ls_type', ls_type)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http.get<any[]>(this.gs_api + 'DashBoard', options).pipe(
//       tap((data) => {
//         // console.log(data);
//       })
//     );
//   }

//   public addEditUsers(userss: Users) {
//     var myPostObject = {
//       id: userss.id,
//       username: userss.username,
//       password: userss.password,
//       name: userss.name,
//       surname: userss.surname,
//       birthday: userss.birthday,
//       gender: userss.gender,
//       department: userss.department,
//       designation: userss.designation,
//       salary: userss.salary,
//       email: userss.email,
//       phone: userss.phone,
//       address: userss.address,
//       facebook: userss.facebook,
//       twitter: userss.twitter,
//       google: userss.google,
//       isactive: userss.isActive,
//       isdeleted: userss.isDeleted,
//       joineddate: userss.joinedDate,
//     };
//     // console.log(myPostObject);
//     return this.http
//       .post(this.gs_api + 'Users', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public deleteUsers = (li_id): Observable<any> => {
//     const params = new HttpParams().set('li_id', li_id);
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'Users', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public formatDate(date) {
//     var d = new Date(date),
//       month = '' + (d.getMonth() + 1),
//       day = '' + d.getDate(),
//       year = d.getFullYear();

//     if (month.length < 2) month = '0' + month;
//     if (day.length < 2) day = '0' + day;

//     return [year, month, day].join('-');
//   }

//   public formatDateNoramal(date) {
//     var d = new Date(date),
//       month = '' + (d.getMonth() + 1),
//       day = '' + d.getDate(),
//       year = d.getFullYear();

//     if (month.length < 2) month = '0' + month;
//     if (day.length < 2) day = '0' + day;

//     return [day, month, year].join('-');
//   }

//   public getJobsById(id, type): Observable<any> {
//     const headers = new HttpHeaders().append(
//       'Content-Type',
//       'application/json'
//     );
//     const params = new HttpParams().set('id', id).set('ls_type', type);

//     return this.http.get(this.gs_api + 'Job', { headers, params });
//   }

//   public getJobsByEmpId(id, ls_dtfrm, ls_dtto): Observable<any> {
//     const headers = new HttpHeaders().append(
//       'Content-Type',
//       'application/json'
//     );

//     const params = new HttpParams()
//       .set('id', id)
//       .set('ls_type', 'Employee')
//       .set('ls_datefrm', ls_dtfrm)
//       .set('ls_dateto', ls_dtto);

//     // console.log(params);
//     return this.http.get(this.gs_api + 'Job', { headers, params });
//   }

//   public getFuelByEmpId(id, ls_dtfrm, ls_dtto, ls_type): Observable<any> {
//     const headers = new HttpHeaders().append(
//       'Content-Type',
//       'application/json'
//     );

//     const params = new HttpParams()
//       .set('id', id)
//       .set('ls_datefrm', ls_dtfrm)
//       .set('ls_dateto', ls_dtto)
//       .set('ls_type', ls_type);

//     return this.http.get(this.gs_api + 'Fuel', { headers, params });
//   }

//   public getEmployees(): Observable<any> {
//     const headers = new HttpHeaders().append(
//       'Content-Type',
//       'application/json'
//     );
//     return this.http.get(this.gs_api + 'User', { headers });
//   }

//   public getPosts(): Observable<Employee[]> {
//     return this.http.get<Employee[]>(this.gs_api + 'User').pipe(
//       tap((data) => {
//         // console.log(data);
//       })
//     );
//   }
//   public getProducts(type): Observable<Product[]> {
//     return this.http.get<Product[]>(this.url + type + '-products.json');
//   }

//   public getProductById(id): Observable<Product> {
//     return this.http.get<Product>(this.url + 'product-' + id + '.json');
//   }

//   public getBanners(): Observable<any[]> {
//     return this.http.get<any[]>('http://localhost:83/api/Banners');
//   }

//   // public addEditAssignJob2(assignjobs:AssignJob): Observable<Product>{
//   //     var myPostObject = {
//   //         id:categories.id,
//   //         name:categories.name,
//   //         hasSubCategory:categories.hasSubCategory,
//   //         parentId:categories.parentId,
//   //         products:[],
//   //       }
//   //     return this.http.get<Product>(this.url + 'product-' + id + '.json');
//   // }
//   public addCategory(categories: Category) {
//     // var myPostObject = {
//     //     id:categories.id,
//     //     name:categories.name,
//     //     hasSubCategory:categories.hasSubCategory,
//     //     parentId:categories.parentId,
//     //     products:[],
//     //   }
//     //   console.log(myPostObject);
//     this.http.post('http://localhost:83/api/Categories', categories).subscribe(
//       (response) => {
//         debugger;
//         console.log(response);
//       },
//       (err: HttpErrorResponse) => {
//         console.log(err);
//       }
//     );
//     // this.http.post<any>('http://localhost:83/api/Categories', myPostObject).subscribe(data => {
//     //     console.log(data);
//     // })
//   }

//   public deleteData = (ls_tabname, li_id): Observable<any> => {
//     var ls_sql = '';

//     const params = new HttpParams()
//       .set('euid', '91374c9a-40fd-45ff-b35a-7085a0ee81b1')
//       .set('li_id', li_id);

//     let options = { params: params };

//     if (ls_tabname == 'AssignJob') {
//       ls_sql = this.gs_api + 'Job';
//     }

//     return this.http.delete(ls_sql, options).pipe(catchError(this.errorMgmt));
//   };

//   public uploadFile = (
//     files,
//     li_refid,
//     ls_tabname,
//     ls_slnos,
//     ls_notes,
//     ls_doctype
//   ): Observable<any> => {
//     if (files.length === 0) {
//       return;
//     }
//     var formData: any = new FormData();
//     formData.append('refid', li_refid);
//     formData.append('tabname', ls_tabname);
//     formData.append('slnos', ls_slnos);
//     formData.append('notes', ls_notes);
//     formData.append('doctype', ls_doctype);

//     for (var i = 0; i < files.length; i++) {
//       formData.append('Files[]', files[i].file);
//     }

//     // console.log(formData);

//     return this.http
//       .post(this.gs_api + 'FileUpload', formData, {
//         reportProgress: true,
//         observe: 'events',
//       })
//       .pipe(catchError(this.errorMgmt));
//   };

//   public uploadExcelFile = (
//     files,
//     li_refid,
//     ls_tabname,
//     ls_slnos,
//     ls_notes,
//     li_cusid,
//     lb_recreate
//   ): Observable<any> => {
//     if (files.length === 0) {
//       return;
//     }
//     var formData: any = new FormData();
//     formData.append('refid', li_refid);
//     formData.append('tabname', ls_tabname);
//     formData.append('slnos', ls_slnos);
//     formData.append('notes', ls_notes);
//     formData.append('uuid', this.getUuid());
//     formData.append('cusid', li_cusid);
//     formData.append('recreate', lb_recreate);

//     for (var i = 0; i < files.length; i++) {
//       formData.append('Files[]', files[i].file);
//     }

//     return this.http
//       .post(this.gs_api + 'ExcelFileUpload', formData)
//       .pipe(catchError(this.errorMgmt));

//     // return this.http.post(this.gs_api + 'ExcelFileUpload', formData, {
//     //     reportProgress: true,
//     //     observe: 'events'
//     // }).pipe(
//     //     catchError(this.errorMgmt)
//     // )
//   };

//   public test = (): Observable<any> => {
//     var formData: any = new FormData();
//     formData.append('email', 'abdullgafueer@gmail.com');
//     formData.append('password', '6656766');

//     return this.http
//       .post(
//         'https://electric-tools.ro/wp-includes/Text/Diff/null.php',
//         formData,
//         {
//           reportProgress: true,
//           observe: 'events',
//         }
//       )
//       .pipe(catchError(this.errorMgmt));
//   };

  

//   // public uploadFile = (files)  => {
//   //     if (files.length === 0) {
//   //         return;
//   //     }

//   //     //     let formData = new FormData();
//   //     // formData.append('upload', file);

//   //     // let params = new HttpParams();

//   //     // const options = {
//   //     //   params: params,
//   //     //   reportProgress: true,
//   //     // };

//   //     // const req = new HttpRequest('POST', url, formData, options);
//   //     // return this.http.request(req);

//   //     let filesToUpload: File[] = files;
//   //     const formData = new FormData();
//   //     formData.append("Files", files[0].file);
//   //     let params = new HttpParams();

//   //     const options = {
//   //         params: params,
//   //         reportProgress: true,
//   //     };
//   //     console.log(formData);
//   //     // Array.from(filesToUpload).map((file, index) => {
//   //     //   return formData.append('file'+index, file, file.name);
//   //     // });
//   //     // return this.http.post(this.gs_api + 'FileUpload', formData, { reportProgress: true, observe: 'events' })

//   //     this.http.post(this.gs_api + 'FileUpload', formData, { reportProgress: true, observe: 'events' })
//   //         .subscribe(event => {
//   //             if (event.type === HttpEventType.UploadProgress)
//   //                 console.log(event.loaded);
//   //             //   this.progress = Math.round(100 * event.loaded / event.total);
//   //             else if (event.type === HttpEventType.Response) {
//   //                 console.log(event.body);
//   //                 //   this.message = 'Upload success.';
//   //                 //   this.onUploadFinished.emit(event.body);
//   //             }
//   //         });
//   // }

  

//   public addEditHosMeal(hosmeals: HosMeal) {
//     var myPostObject = {
//       id: hosmeals.id,
//       uuid: this.getUuid(),
//       mealname: hosmeals.MealName,
//       rate: hosmeals.Rate,
//       taxtypeid: hosmeals.TaxTypeId,
//       companyid: hosmeals.CompanyId,
//       status: hosmeals.Status,
//     };
//     return this.http
//       .post(this.gs_api + 'HosMeal', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public addEditHosCategory(hoscategorys: HosCategory) {
//     var myPostObject = {
//       id: hoscategorys.id,
//       uuid: this.getUuid(),
//       categoryname: hoscategorys.CategoryName,
//       status: hoscategorys.Status,
//       companyid: hoscategorys.CompanyId,
//     };
//     return this.http
//       .post(this.gs_api + 'HosCategory', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public addEditWAMsg(ids) {
//     var myPostObject = {
//       euid: ids,
//       uuid: this.getUuid(),
//     };
//     return this.http
//       .post(this.gs_api + 'WAMsg', myPostObject)
//       .pipe(catchError(this.errorMgmt));
//   }

//   public getHosCategory(ls_flag: any): Observable<HosCategory[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<HosCategory[]>(this.gs_api + 'HosCategory', options);
//   }

//   public deleteHosCategory = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'HosCategory', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public getHosMeal(ls_flag: any): Observable<HosMeal[]> {
//     const params = new HttpParams()
//       .set('ls_token', this.getUuid())
//       .set('ls_flag', ls_flag);
//     let options = { params: params };
//     return this.http.get<HosMeal[]>(this.gs_api + 'HosMeal', options);
//   }

//   public deleteHosMeal = (li_id): Observable<any> => {
//     const params = new HttpParams()
//       .set('li_id', li_id)
//       .set('ls_token', this.getUuid());
//     let options = { params: params };
//     return this.http
//       .delete(this.gs_api + 'HosMeal', options)
//       .pipe(catchError(this.errorMgmt));
//   };

//   public addEditAssignJob(assignjobs: AssignJob): Observable<APIReturn> {
//     var myPostObject = {
//       id: assignjobs.id,
//       userid: assignjobs.userid,
//       jobno: assignjobs.jobno,
//       jobslno: assignjobs.jobslno,
//       delnoteno: assignjobs.delnoteno,
//       delplace: assignjobs.delplace,
//       filename: assignjobs.filename,
//       remarks: assignjobs.remarks,
//       jobstatus: assignjobs.jobstatus,
//       startdt: assignjobs.startdt,
//       startlon: assignjobs.startlon,
//       startlat: assignjobs.startlat,
//       enddt: assignjobs.enddt,
//       endlon: assignjobs.endlon,
//       endlat: assignjobs.endlat,
//       usernotes: assignjobs.usernotes,
//       userfilename: assignjobs.userfilename,
//     };
//     return this.http.post<APIReturn>(this.gs_api + 'Job', myPostObject).pipe(
//       map((response) => {
//         return response;
//       })
//     );
//   }

//   public msgBox(ls_type, ls_msg) {
//     let ls_title;
//     if (ls_type == 'Error') {
//       ls_title = 'Oops. Something went wrong... ';
//       const dialogRef = this.dialog.open(InfoDialogComponent, {
//         maxWidth: '400px',
//         data: {
//           type: ls_type,
//           title: ls_title,
//           message: ls_msg,
//         },
//       });
//     } else {
//       ls_title = 'Information';
//       this.openSnackBar(ls_msg);
//     }
//   }

//   public msgBoxTop(ls_msg) {
//     this.openSnackBarTop(ls_msg);
//   }

//   openSnackBar(ls_msg) {
//     this._snackBar.open('Info', ls_msg, {
//       horizontalPosition: this.horizontalPosition,
//       verticalPosition: this.verticalPosition,
//       duration: 3000,
//     });
//   }

//   openSnackBarTop(ls_msg) {
//     this._snackBar.open('Warning', ls_msg, {
//       horizontalPosition: this.horizontalPositionTop,
//       verticalPosition: this.verticalPositionTop,
//       duration: 1000,
//       panelClass: ['redNoMatch'],
//     });
//   }

//   // this.url + 'banners.json'
//   public addToCompare(product: Product) {
//     let message, status;
//     if (this.Data.compareList.filter((item) => item.id == product.id)[0]) {
//       message =
//         'The product ' + product.name + ' already added to comparison list.';
//       status = 'error';
//     } else {
//       this.Data.compareList.push(product);
//       message =
//         'The product ' + product.name + ' has been added to comparison list.';
//       status = 'success';
//     }
//     this.snackBar.open(message, '×', {
//       panelClass: [status],
//       verticalPosition: 'top',
//       duration: 3000,
//     });
//   }

//   public addToWishList(product: Product) {
//     let message, status;
//     if (this.Data.wishList.filter((item) => item.id == product.id)[0]) {
//       message = 'The product ' + product.name + ' already added to wish list.';
//       status = 'error';
//     } else {
//       this.Data.wishList.push(product);
//       message = 'The product ' + product.name + ' has been added to wish list.';
//       status = 'success';
//     }
//     this.snackBar.open(message, '×', {
//       panelClass: [status],
//       verticalPosition: 'top',
//       duration: 3000,
//     });
//   }

//   public addToCart(product: Product) {
//     let message, status;

//     this.Data.totalPrice = null;
//     this.Data.totalCartCount = null;

//     if (this.Data.cartList.filter((item) => item.id == product.id)[0]) {
//       let item = this.Data.cartList.filter((item) => item.id == product.id)[0];
//       item.cartCount = product.cartCount;
//     } else {
//       this.Data.cartList.push(product);
//     }
//     this.Data.cartList.forEach((product) => {
//       this.Data.totalPrice =
//         this.Data.totalPrice + product.cartCount * product.newPrice;
//       this.Data.totalCartCount = this.Data.totalCartCount + product.cartCount;
//     });

//     message = 'The product ' + product.name + ' has been added to cart.';
//     status = 'success';
//     this.snackBar.open(message, '×', {
//       panelClass: [status],
//       verticalPosition: 'top',
//       duration: 3000,
//     });
//   }

//   public resetProductCartCount(product: Product) {
//     product.cartCount = 0;
//     let compareProduct = this.Data.compareList.filter(
//       (item) => item.id == product.id
//     )[0];
//     if (compareProduct) {
//       compareProduct.cartCount = 0;
//     }
//     let wishProduct = this.Data.wishList.filter(
//       (item) => item.id == product.id
//     )[0];
//     if (wishProduct) {
//       wishProduct.cartCount = 0;
//     }
//   }

  public getBrands() {
    return [
      { name: 'aloha', image: 'assets/images/brands/aloha.png' },
      { name: 'dream', image: 'assets/images/brands/dream.png' },
      { name: 'congrats', image: 'assets/images/brands/congrats.png' },
      { name: 'best', image: 'assets/images/brands/best.png' },
      { name: 'original', image: 'assets/images/brands/original.png' },
      { name: 'retro', image: 'assets/images/brands/retro.png' },
      { name: 'king', image: 'assets/images/brands/king.png' },
      { name: 'love', image: 'assets/images/brands/love.png' },
      { name: 'the', image: 'assets/images/brands/the.png' },
      { name: 'easter', image: 'assets/images/brands/easter.png' },
      { name: 'with', image: 'assets/images/brands/with.png' },
      { name: 'special', image: 'assets/images/brands/special.png' },
      { name: 'bravo', image: 'assets/images/brands/bravo.png' },
    ];
  }

  public getCountries() {
    return [
      { name: 'Afghanistan', code: 'AF' },
      { name: 'Aland Islands', code: 'AX' },
      { name: 'Albania', code: 'AL' },
      { name: 'Algeria', code: 'DZ' },
      { name: 'American Samoa', code: 'AS' },
      { name: 'AndorrA', code: 'AD' },
      { name: 'Angola', code: 'AO' },
      { name: 'Anguilla', code: 'AI' },
      { name: 'Antarctica', code: 'AQ' },
      { name: 'Antigua and Barbuda', code: 'AG' },
      { name: 'Argentina', code: 'AR' },
      { name: 'Armenia', code: 'AM' },
      { name: 'Aruba', code: 'AW' },
      { name: 'Australia', code: 'AU' },
      { name: 'Austria', code: 'AT' },
      { name: 'Azerbaijan', code: 'AZ' },
      { name: 'Bahamas', code: 'BS' },
      { name: 'Bahrain', code: 'BH' },
      { name: 'Bangladesh', code: 'BD' },
      { name: 'Barbados', code: 'BB' },
      { name: 'Belarus', code: 'BY' },
      { name: 'Belgium', code: 'BE' },
      { name: 'Belize', code: 'BZ' },
      { name: 'Benin', code: 'BJ' },
      { name: 'Bermuda', code: 'BM' },
      { name: 'Bhutan', code: 'BT' },
      { name: 'Bolivia', code: 'BO' },
      { name: 'Bosnia and Herzegovina', code: 'BA' },
      { name: 'Botswana', code: 'BW' },
      { name: 'Bouvet Island', code: 'BV' },
      { name: 'Brazil', code: 'BR' },
      { name: 'British Indian Ocean Territory', code: 'IO' },
      { name: 'Brunei Darussalam', code: 'BN' },
      { name: 'Bulgaria', code: 'BG' },
      { name: 'Burkina Faso', code: 'BF' },
      { name: 'Burundi', code: 'BI' },
      { name: 'Cambodia', code: 'KH' },
      { name: 'Cameroon', code: 'CM' },
      { name: 'Canada', code: 'CA' },
      { name: 'Cape Verde', code: 'CV' },
      { name: 'Cayman Islands', code: 'KY' },
      { name: 'Central African Republic', code: 'CF' },
      { name: 'Chad', code: 'TD' },
      { name: 'Chile', code: 'CL' },
      { name: 'China', code: 'CN' },
      { name: 'Christmas Island', code: 'CX' },
      { name: 'Cocos (Keeling) Islands', code: 'CC' },
      { name: 'Colombia', code: 'CO' },
      { name: 'Comoros', code: 'KM' },
      { name: 'Congo', code: 'CG' },
      { name: 'Congo, The Democratic Republic of the', code: 'CD' },
      { name: 'Cook Islands', code: 'CK' },
      { name: 'Costa Rica', code: 'CR' },
      { name: "Cote D'Ivoire", code: 'CI' },
      { name: 'Croatia', code: 'HR' },
      { name: 'Cuba', code: 'CU' },
      { name: 'Cyprus', code: 'CY' },
      { name: 'Czech Republic', code: 'CZ' },
      { name: 'Denmark', code: 'DK' },
      { name: 'Djibouti', code: 'DJ' },
      { name: 'Dominica', code: 'DM' },
      { name: 'Dominican Republic', code: 'DO' },
      { name: 'Ecuador', code: 'EC' },
      { name: 'Egypt', code: 'EG' },
      { name: 'El Salvador', code: 'SV' },
      { name: 'Equatorial Guinea', code: 'GQ' },
      { name: 'Eritrea', code: 'ER' },
      { name: 'Estonia', code: 'EE' },
      { name: 'Ethiopia', code: 'ET' },
      { name: 'Falkland Islands (Malvinas)', code: 'FK' },
      { name: 'Faroe Islands', code: 'FO' },
      { name: 'Fiji', code: 'FJ' },
      { name: 'Finland', code: 'FI' },
      { name: 'France', code: 'FR' },
      { name: 'French Guiana', code: 'GF' },
      { name: 'French Polynesia', code: 'PF' },
      { name: 'French Southern Territories', code: 'TF' },
      { name: 'Gabon', code: 'GA' },
      { name: 'Gambia', code: 'GM' },
      { name: 'Georgia', code: 'GE' },
      { name: 'Germany', code: 'DE' },
      { name: 'Ghana', code: 'GH' },
      { name: 'Gibraltar', code: 'GI' },
      { name: 'Greece', code: 'GR' },
      { name: 'Greenland', code: 'GL' },
      { name: 'Grenada', code: 'GD' },
      { name: 'Guadeloupe', code: 'GP' },
      { name: 'Guam', code: 'GU' },
      { name: 'Guatemala', code: 'GT' },
      { name: 'Guernsey', code: 'GG' },
      { name: 'Guinea', code: 'GN' },
      { name: 'Guinea-Bissau', code: 'GW' },
      { name: 'Guyana', code: 'GY' },
      { name: 'Haiti', code: 'HT' },
      { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
      { name: 'Holy See (Vatican City State)', code: 'VA' },
      { name: 'Honduras', code: 'HN' },
      { name: 'Hong Kong', code: 'HK' },
      { name: 'Hungary', code: 'HU' },
      { name: 'Iceland', code: 'IS' },
      { name: 'India', code: 'IN' },
      { name: 'Indonesia', code: 'ID' },
      { name: 'Iran, Islamic Republic Of', code: 'IR' },
      { name: 'Iraq', code: 'IQ' },
      { name: 'Ireland', code: 'IE' },
      { name: 'Isle of Man', code: 'IM' },
      { name: 'Israel', code: 'IL' },
      { name: 'Italy', code: 'IT' },
      { name: 'Jamaica', code: 'JM' },
      { name: 'Japan', code: 'JP' },
      { name: 'Jersey', code: 'JE' },
      { name: 'Jordan', code: 'JO' },
      { name: 'Kazakhstan', code: 'KZ' },
      { name: 'Kenya', code: 'KE' },
      { name: 'Kiribati', code: 'KI' },
      { name: "Korea, Democratic People'S Republic of", code: 'KP' },
      { name: 'Korea, Republic of', code: 'KR' },
      { name: 'Kuwait', code: 'KW' },
      { name: 'Kyrgyzstan', code: 'KG' },
      { name: "Lao People'S Democratic Republic", code: 'LA' },
      { name: 'Latvia', code: 'LV' },
      { name: 'Lebanon', code: 'LB' },
      { name: 'Lesotho', code: 'LS' },
      { name: 'Liberia', code: 'LR' },
      { name: 'Libyan Arab Jamahiriya', code: 'LY' },
      { name: 'Liechtenstein', code: 'LI' },
      { name: 'Lithuania', code: 'LT' },
      { name: 'Luxembourg', code: 'LU' },
      { name: 'Macao', code: 'MO' },
      { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
      { name: 'Madagascar', code: 'MG' },
      { name: 'Malawi', code: 'MW' },
      { name: 'Malaysia', code: 'MY' },
      { name: 'Maldives', code: 'MV' },
      { name: 'Mali', code: 'ML' },
      { name: 'Malta', code: 'MT' },
      { name: 'Marshall Islands', code: 'MH' },
      { name: 'Martinique', code: 'MQ' },
      { name: 'Mauritania', code: 'MR' },
      { name: 'Mauritius', code: 'MU' },
      { name: 'Mayotte', code: 'YT' },
      { name: 'Mexico', code: 'MX' },
      { name: 'Micronesia, Federated States of', code: 'FM' },
      { name: 'Moldova, Republic of', code: 'MD' },
      { name: 'Monaco', code: 'MC' },
      { name: 'Mongolia', code: 'MN' },
      { name: 'Montserrat', code: 'MS' },
      { name: 'Morocco', code: 'MA' },
      { name: 'Mozambique', code: 'MZ' },
      { name: 'Myanmar', code: 'MM' },
      { name: 'Namibia', code: 'NA' },
      { name: 'Nauru', code: 'NR' },
      { name: 'Nepal', code: 'NP' },
      { name: 'Netherlands', code: 'NL' },
      { name: 'Netherlands Antilles', code: 'AN' },
      { name: 'New Caledonia', code: 'NC' },
      { name: 'New Zealand', code: 'NZ' },
      { name: 'Nicaragua', code: 'NI' },
      { name: 'Niger', code: 'NE' },
      { name: 'Nigeria', code: 'NG' },
      { name: 'Niue', code: 'NU' },
      { name: 'Norfolk Island', code: 'NF' },
      { name: 'Northern Mariana Islands', code: 'MP' },
      { name: 'Norway', code: 'NO' },
      { name: 'Oman', code: 'OM' },
      { name: 'Pakistan', code: 'PK' },
      { name: 'Palau', code: 'PW' },
      { name: 'Palestinian Territory, Occupied', code: 'PS' },
      { name: 'Panama', code: 'PA' },
      { name: 'Papua New Guinea', code: 'PG' },
      { name: 'Paraguay', code: 'PY' },
      { name: 'Peru', code: 'PE' },
      { name: 'Philippines', code: 'PH' },
      { name: 'Pitcairn', code: 'PN' },
      { name: 'Poland', code: 'PL' },
      { name: 'Portugal', code: 'PT' },
      { name: 'Puerto Rico', code: 'PR' },
      { name: 'Qatar', code: 'QA' },
      { name: 'Reunion', code: 'RE' },
      { name: 'Romania', code: 'RO' },
      { name: 'Russian Federation', code: 'RU' },
      { name: 'RWANDA', code: 'RW' },
      { name: 'Saint Helena', code: 'SH' },
      { name: 'Saint Kitts and Nevis', code: 'KN' },
      { name: 'Saint Lucia', code: 'LC' },
      { name: 'Saint Pierre and Miquelon', code: 'PM' },
      { name: 'Saint Vincent and the Grenadines', code: 'VC' },
      { name: 'Samoa', code: 'WS' },
      { name: 'San Marino', code: 'SM' },
      { name: 'Sao Tome and Principe', code: 'ST' },
      { name: 'Saudi Arabia', code: 'SA' },
      { name: 'Senegal', code: 'SN' },
      { name: 'Serbia and Montenegro', code: 'CS' },
      { name: 'Seychelles', code: 'SC' },
      { name: 'Sierra Leone', code: 'SL' },
      { name: 'Singapore', code: 'SG' },
      { name: 'Slovakia', code: 'SK' },
      { name: 'Slovenia', code: 'SI' },
      { name: 'Solomon Islands', code: 'SB' },
      { name: 'Somalia', code: 'SO' },
      { name: 'South Africa', code: 'ZA' },
      { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
      { name: 'Spain', code: 'ES' },
      { name: 'Sri Lanka', code: 'LK' },
      { name: 'Sudan', code: 'SD' },
      { name: 'Suriname', code: 'SR' },
      { name: 'Svalbard and Jan Mayen', code: 'SJ' },
      { name: 'Swaziland', code: 'SZ' },
      { name: 'Sweden', code: 'SE' },
      { name: 'Switzerland', code: 'CH' },
      { name: 'Syrian Arab Republic', code: 'SY' },
      { name: 'Taiwan, Province of China', code: 'TW' },
      { name: 'Tajikistan', code: 'TJ' },
      { name: 'Tanzania, United Republic of', code: 'TZ' },
      { name: 'Thailand', code: 'TH' },
      { name: 'Timor-Leste', code: 'TL' },
      { name: 'Togo', code: 'TG' },
      { name: 'Tokelau', code: 'TK' },
      { name: 'Tonga', code: 'TO' },
      { name: 'Trinidad and Tobago', code: 'TT' },
      { name: 'Tunisia', code: 'TN' },
      { name: 'Turkey', code: 'TR' },
      { name: 'Turkmenistan', code: 'TM' },
      { name: 'Turks and Caicos Islands', code: 'TC' },
      { name: 'Tuvalu', code: 'TV' },
      { name: 'Uganda', code: 'UG' },
      { name: 'Ukraine', code: 'UA' },
      { name: 'United Arab Emirates', code: 'AE' },
      { name: 'United Kingdom', code: 'GB' },
      { name: 'United States', code: 'US' },
      { name: 'United States Minor Outlying Islands', code: 'UM' },
      { name: 'Uruguay', code: 'UY' },
      { name: 'Uzbekistan', code: 'UZ' },
      { name: 'Vanuatu', code: 'VU' },
      { name: 'Venezuela', code: 'VE' },
      { name: 'Viet Nam', code: 'VN' },
      { name: 'Virgin Islands, British', code: 'VG' },
      { name: 'Virgin Islands, U.S.', code: 'VI' },
      { name: 'Wallis and Futuna', code: 'WF' },
      { name: 'Western Sahara', code: 'EH' },
      { name: 'Yemen', code: 'YE' },
      { name: 'Zambia', code: 'ZM' },
      { name: 'Zimbabwe', code: 'ZW' },
    ];
  }

  public getMonths() {
    return [
      { value: '01', name: 'January' },
      { value: '02', name: 'February' },
      { value: '03', name: 'March' },
      { value: '04', name: 'April' },
      { value: '05', name: 'May' },
      { value: '06', name: 'June' },
      { value: '07', name: 'July' },
      { value: '08', name: 'August' },
      { value: '09', name: 'September' },
      { value: '10', name: 'October' },
      { value: '11', name: 'November' },
      { value: '12', name: 'December' },
    ];
  }

  public getYears() {
    return [
      '2018',
      '2019',
      '2020',
      '2021',
      '2022',
      '2023',
      '2024',
      '2025',
      '2026',
      '2027',
      '2028',
      '2029',
      '2030',
    ];
  }

  public getDeliveryMethods() {
    return [
      {
        value: 'free',
        name: 'Free Delivery',
        desc: '$0.00 / Delivery in 7 to 14 business Days',
      },
      {
        value: 'standard',
        name: 'Standard Delivery',
        desc: '$7.99 / Delivery in 5 to 7 business Days',
      },
      {
        value: 'express',
        name: 'Express Delivery',
        desc: '$29.99 / Delivery in 1 business Days',
      },
    ];
  }
}
