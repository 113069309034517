<div class="container">
    <h2>Calorie Calculator</h2>
  
    <form (ngSubmit)="calculateCalories()">
      <div class="form-group">
        <label for="gender">Gender:</label>
        <select id="gender" [(ngModel)]="gender" name="gender" class="form-control">
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
      </div>
  
      <div class="form-group">
        <label for="weight">Weight (kg):</label>
        <input type="number" id="weight" [(ngModel)]="weight" name="weight" class="form-control" required />
      </div>
  
      <div class="form-group">
        <label for="height">Height (cm):</label>
        <input type="number" id="height" [(ngModel)]="height" name="height" class="form-control" required />
      </div>
  
      <div class="form-group">
        <label for="age">Age (years):</label>
        <input type="number" id="age" [(ngModel)]="age" name="age" class="form-control" required />
      </div>
  
      <div class="form-group">
        <label for="activityLevel">Activity Level:</label>
        <select id="activityLevel" [(ngModel)]="activityLevel" name="activityLevel" class="form-control">
          <option value="sedentary">Sedentary (little or no exercise)</option>
          <option value="lightlyActive">Lightly active (light exercise/sports 1-3 days a week)</option>
          <option value="moderatelyActive">Moderately active (moderate exercise/sports 3-5 days a week)</option>
          <option value="veryActive">Very active (hard exercise/sports 6-7 days a week)</option>
          <option value="extraActive">Extra active (very hard exercise, physical job, or training twice a day)</option>
        </select>
      </div>
  
      <button type="submit" class="btn btn-primary">Calculate</button>
    </form>
  
    <div *ngIf="calories !== null">
      <h3>Calories Needed: {{ calories | number:'1.0-0' }} kcal/day</h3>
    </div>
  </div>
  