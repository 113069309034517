import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bmi-calc',
  templateUrl: './bmi-calc.component.html',
  styleUrls: ['./bmi-calc.component.scss']
})
export class BmiCalcComponent implements OnInit {
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }
  weight: number | null = null;
  feet: number | null = null;
  inches: number | null = null;
  centimeters: number | null = null;
  bmi: number | null = null;
  bmiCategory: string = '';

  

  onFeetInchChange(): void {
    if (this.feet !== null && this.inches !== null) {
      const totalInches = (this.feet * 12) + this.inches;
      this.centimeters = totalInches * 2.54;
    }
  }

  onCmChange(): void {
    if (this.centimeters !== null) {
      const totalInches = this.centimeters / 2.54;
      this.feet = Math.floor(totalInches / 12);
      this.inches = Math.round(totalInches % 12);
    }
  }

  calculateBMI(): void {
    let heightInMeters: number | null = null;
  
    // Calculate height in meters based on which field is filled
    if (this.centimeters !== null) {
      heightInMeters = this.centimeters / 100;
    } else if (this.feet !== null && this.inches !== null) {
      const totalInches = (this.feet * 12) + this.inches;
      heightInMeters = totalInches * 0.0254;
    }
  
    if (this.weight && heightInMeters) {
      // Calculate BMI and round it to two decimal places
      this.bmi = +(this.weight / (heightInMeters * heightInMeters)).toFixed(2);
      this.bmiCategory = this.getBMICategory(this.bmi);
    } else {
      this.bmi = null;
      this.bmiCategory = '';
    }
  }
  
  getBMICategory(bmi: number): string {
    // Ensure that BMI is rounded to two decimal places
    if (bmi < 18.5) return 'Underweight';
    if (bmi >= 18.5 && bmi < 25) return 'Normal weight';
    if (bmi >= 25 && bmi < 30) return 'Overweight';
    return 'Obesity';
  }
  
}