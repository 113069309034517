import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-ppp',
  templateUrl: './ppp.component.html',
  styleUrls: ['./ppp.component.scss']
})
export class PppComponent implements OnInit {
  public dtTabHosAdmission: any[] = [];
  public filteredHosAdmission: any[] = [];
  public searchQuery: string = '';
  public form!: FormGroup;
  public salaryPlaceholder: string = "Salary in Local Currency";
  public dynamicMessage: string = ""; // Property for dynamic message

  private sourcePPP: number = 0.204475956836398; // Example value for Kuwait
  private targetPPP: number = 20.2166267502145; // Example value for India

  constructor(
    public appService: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.comboData();
    this.form = this.fb.group({
      TragetId: [null, Validators.required],
      Salary: ['', Validators.required],
      SourceId: ['United States', Validators.required],
      Output: [{ value: '', disabled: true }, Validators.required],
    });
  }

  comboData(): void {
    this.appService.getBlogData('Country').subscribe((data) => {
      this.dtTabHosAdmission = data;
      this.filteredHosAdmission = data;
      this.onChangeHosAdmission('United Kingdom');
    });
  }

  filterOptions(): void {
    const query = this.searchQuery.toLowerCase();
    this.filteredHosAdmission = this.dtTabHosAdmission.filter(option =>
      option.CountryValue.toLowerCase().includes(query)
    );
  }

  onChangeHosAdmission(event: any): void {
    var result = this.dtTabHosAdmission.find((o) => o.CountryValue === event);
    if (result) {
      this.salaryPlaceholder = `Salary in ${result.CountryValue}'s Local Currency`; // Update placeholder

      // Update source PPP value
      this.sourcePPP = result.PPPValue;
    }
  }

  onChangeHosAdmission2(event: any): void {
    var result = this.dtTabHosAdmission.find((o) => o.CountryValue === event);
    if (result) {
      this.targetPPP = result.PPPValue;
      // console.log(result)
      // this.calculatePPP();
    }
  }

  calculatePPP(): void {
    const salary = parseFloat(this.form.get('Salary')?.value || '0');
    
    if (this.targetPPP && this.sourcePPP) {
      const output = (salary / this.sourcePPP) * this.targetPPP;
      
      this.form.get('Output')?.setValue(output.toFixed(2));

      this.dynamicMessage = `You require a salary of ${output.toFixed(2)} in ${this.form.get('TragetId')?.value}'s local currency to live a similar quality of life as you would with a salary of ${salary.toFixed(2)} in ${this.form.get('SourceId')?.value}'s local currency.`;
    }
  }
}
