<div class="scroll-progress bg-grey-900"></div>
    <!-- Start Preloader -->
    <div class="preloader text-center">
        <div class="loader"></div>
    </div>
    <!--Offcanvas sidebar-->
    <aside id="sidebar-wrapper" class="custom-scrollbar offcanvas-sidebar">
        <button class="off-canvas-close"><img class="svg-icon-24" src="assets/imgs/theme/svg/close.svg" alt=""></button>
        <div class="sidebar-inner">
            <div class="sidebar-widget widget-creative-menu">
                <ul>
                    <li><a href="category.html">General<sup>235</sup></a></li>
                    <li><a href="category-big.html">Technology<sup>140</sup></a></li>
                    <li><a href="category-big.html">Music<sup>186</sup></a></li>
                    <li><a href="category-grid.html">Travel Tips<sup>98</sup></a></li>
                    <li><a href="category-list.html">Health Tips<sup>247</sup></a></li>
                    <li><a href="category-list.html">Politics<sup>95</sup></a></li>
                    <li><a href="category-list.html">News<sup>101</sup></a></li>
                </ul>
            </div>
            <div class="offcanvas-copyright mt-65">
                <h6 class="text-muted text-uppercase mb-20 font-heading text-white">B-ill.com</h6>
                <p>
                    Bills of Wisdom, Stories of Life
                </p>
                <!-- <p><strong class="color-black">Address</strong><br>
                    123 Main Street<br>
                    New York, NY 10001</p>
                <p><strong class="color-black">Phone</strong><br>
                    (+01) 234 567 89</p> -->
            </div>
        </div>
    </aside>
    <!-- Start Header -->
    <header class="main-header header-sticky bg-brand-4">
        <div class="position-relative">
            <div class="container align-self-center">
                <div class="header-style-1">
                    <div class="logo">
                        <a href="index.html"><img src="assets/imgs/theme/logo.svg" alt=""></a>
                    </div>
                    <div class="main-nav d-none d-lg-block">
                        <nav>
                            <!--Desktop menu-->
                            <ul class="main-menu d-none d-lg-inline">
                                <!-- <li class="menu-item-has-children">
                                    <a href="index.html">Home</a>
                                    <ul class="sub-menu">
                                        <li><a href="index.html">Home default</a></li>
                                        <li><a href="home-2.html">Homepage 2</a></li>
                                        <li><a href="home-3.html">Homepage 3</a></li>
                                    </ul>
                                </li> -->
                                <li> <a href="page-about.html">Home</a> </li>
                                <li class="menu-item-has-children">
                                    <a  [routerLink]="['/calculaters']">Calculaters</a>
                                    <!-- <ul class="sub-menu font-small">
                                        <li><a [routerLink]="['/ppp_calculater']">PPP Calculater</a></li>
                                        <li><a [routerLink]="['/sip_calculater']">SIP Calculater</a></li>
                                        <li><a [routerLink]="['/exchange_rate']">Currency Converter</a></li>
                                        <li><a [routerLink]="['/bmi_calculater']">BMI Calculator</a></li>
                                        <li><a [routerLink]="['/calorie_calculater']">Calorie Calculator</a></li>
                                        
                                    </ul> -->
                                </li>
                                <!-- <li> <a href="page-design.html">Design</a> </li> -->
                                <li> <a [routerLink]="['/contact']">Contact</a> </li>
                            </ul>
                            <!--Mobile menu-->
                            <ul id="mobile-menu" class="d-block d-lg-none text-muted">
                                <li class="menu-item-has-children">
                                    <a href="index.html">Home</a>
                                    <!-- <ul class="sub-menu font-small">
                                        <li><a href="index.html">Home default</a></li>
                                        <li><a href="home-2.html">Homepage 2</a></li>
                                        <li><a href="home-3.html">Homepage 3</a></li>
                                    </ul> -->
                                </li>
                                <li class="menu-item-has-children">
                                    <a  [routerLink]="['/calculaters']">Calculaters</a>
                                    <!-- <ul class="sub-menu font-small">
                                        <li><a href="index.html">Home default</a></li>
                                        <li><a href="home-2.html">Homepage 2</a></li>
                                        <li><a href="home-3.html">Homepage 3</a></li>
                                    </ul> -->
                                </li>
                                <li class="menu-item-has-children">
                                     <a [routerLink]="['/contact']">Contact</a>
                                    <!-- <ul class="sub-menu font-small">
                                        <li><a href="index.html">Home default</a></li>
                                        <li><a href="home-2.html">Homepage 2</a></li>
                                        <li><a href="home-3.html">Homepage 3</a></li>
                                    </ul> -->
                                </li>

                                <!-- <li class="menu-item-has-children"><a href="#">Pages</a>
                                    <ul class="sub-menu font-small">
                                        <li><a href="page-about.html">About</a></li>
                                        <li><a [routerLink]="['/contact']">Contact</a></li>
                                        <li><a href="page-design.html">Typography</a></li>
                                        <li><a href="page-register.html">Register</a></li>
                                        <li><a href="page-login.html">Login</a></li>
                                        <li><a href="page-search.html">Search</a></li>
                                        <li><a href="page-author.html">Author</a></li>
                                        <li><a href="page-404.html">404 page</a></li>
                                    </ul>
                                </li> -->
                                <!-- <li class="menu-item-has-children"><a href="#">Category</a>
                                    <ul class="sub-menu font-small">
                                        <li><a href="category-list.html">List layout</a></li>
                                        <li><a href="category-grid.html">Grid layout</a></li>
                                        <li><a href="category-list-2.html">List 2 layout</a></li>
                                        <li><a href="category-big.html">Big layout</a></li>
                                    </ul>
                                </li>
                                <li class="menu-item-has-children"><a href="#">Single post</a>
                                    <ul class="sub-menu font-small">
                                        <li><a href="single.html">Default</a></li>
                                        <li><a href="single-2.html">Big image</a></li>
                                        <li><a href="single-3.html">Right sidebar</a></li>                                        
                                    </ul>
                                </li> -->
                            </ul>
                        </nav>
                    </div>
                    <!--end: main-nav-->
                    <div class="header-right">
                        <button class="search-icon d-md-inline">
                            <img src="assets/imgs/theme/svg/search.svg" alt="">
                        </button>
                        <button class="btn btn-md bg-dark text-white ml-15 box-shadow d-none d-lg-inline"><a [routerLink]="['/contact']">Get in Touch</a></button>
                    </div>
                </div>
                <div class="mobile_menu d-lg-none d-block"></div>
            </div>
            <div class="off-canvas-toggle-cover d-inline-block">
                <div class="off-canvas-toggle hidden d-inline-block" id="off-canvas-toggle">
                    <img class="svg-icon-24" src="assets/imgs/theme/svg/menu.svg" alt="">
                </div>
            </div>
        </div>
    </header>
    <div class="main-search-form bg-brand-4">
        <div class="container">
            <div class=" pt-50 pb-50 ">
                <div class="row mb-20">
                    <div class="col-12 align-self-center main-search-form-cover m-auto">
                        <p class="text-center"><span class="display-1">Search</span></p>
                        <form action="#" class="search-header">
                            <div class="input-group w-100">
                                <input type="text" class="form-control" placeholder="Search articles, places and people">
                                <div class="input-group-append">
                                    <button class="btn btn-search bg-white" type="submit">
                                        <img class="svg-icon-24" src="assets/imgs/theme/svg/search.svg" alt="">
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row mt-80 text-center">
                    <div class="col-12 font-small suggested-area">
                        <h5 class="suggested font-heading mb-20 text-grey-400"> <strong>Suggested keywords:</strong></h5>
                        <ul class="list-inline d-inline-block">
                            <li class="list-inline-item"><a href="category.html">Health</a></li>
                            <li class="list-inline-item"><a href="category.html">Travel tips</a></li>
                            <li class="list-inline-item"><a href="category.html">Lifestyle</a></li>
                            <li class="list-inline-item"><a href="category.html">Technology</a></li>
                            <li class="list-inline-item"><a href="category.html">Food</a></li>
                            <li class="list-inline-item"><a href="category.html">Books</a></li>
                            <li class="list-inline-item"><a href="category.html">Drink</a></li>
                            <li class="list-inline-item"><a href="category.html">Fashion</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>