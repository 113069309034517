import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AppService } from './app.service';
import { DatePipe } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpClientModule } from '@angular/common/http';
import { MainComponent } from './main/main.component';
import { CategoryComponent } from './category/category.component';
import { CatDetComponent } from './category/cat-det/cat-det.component';
import { ContactComponent } from './contact/contact.component';
import { PppComponent } from './ppp/ppp.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SipComponent } from './calc/sip/sip.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSliderModule } from '@angular/material/slider';
import { ExRateComponent } from './calc/ex-rate/ex-rate.component';
import { BmiCalcComponent } from './calc/bmi-calc/bmi-calc.component';
import { CalorieCalcComponent } from './calc/calorie-calc/calorie-calc.component';
import { CalcMenuComponent } from './calc/calc-menu/calc-menu.component';
import { FdCalculaterComponent } from './calc/fd-calculater/fd-calculater.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    MainComponent,
    CategoryComponent,
    CatDetComponent,
    ContactComponent,
    PppComponent,
    SipComponent,
    ExRateComponent,
    BmiCalcComponent,
    CalorieCalcComponent,
    CalcMenuComponent,
    FdCalculaterComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    
    MatNativeDateModule,
    ReactiveFormsModule,
    
    
    AppRoutingModule,
    
    NgSelectModule,
    MatSelectModule,
    MatFormFieldModule,        
    MatInputModule,
    MatSelectModule,

    MatSliderModule
 
  ],
  providers: [DatePipe,AppService],
  bootstrap: [AppComponent]
})
export class AppModule { }
