<main>
  <section class="pt-65 pb-65">
    <div class="container">
      <div class="hr"></div>
    </div>
  </section>
  <div class="recent-posts pb-65">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="loop-list loop-list-style-1 mb-md-30">
            <article
              *ngFor="let post of postsArray"
              class="hover-up-3 border-radius-10 overflow-hidden wow fadeIn animated"
            >
              <div class="row">
                <div class="col-md-5">
                  <div
                    class="post-thumb position-relative"
                    [style.background-image]="'url(' + post.imgPath + ')'"
                  >
                    <a
                      class="img-link"
                      [routerLink]="['/home', post.urlPara]"
                    ></a>
                  </div>
                </div>
                <div class="col-md-7 align-self-center">
                  <div class="post-content pr-30">
                    <div class="post-meta-1 mb-20">
                      <a
                        [href]="post.categoryLink"
                        class="tag-category bg-brand-1 shadown-1 text-dark button-shadow hover-up-3"
                        tabindex="0"
                        >{{ post.category }}</a
                      >
                    </div>
                    <h4 class="post-title mb-40">
                      <a class="" [routerLink]="['/home', post.urlPara]">{{ post.title }}</a>
                    </h4>
                    <div class="d-flex">
                      <div class="post-meta-2 font-md d-flex w-70">
                        <a
                          class="align-self-center"
                           
                          tabindex="0"
                        >
                          <img src="assets/imgs/authors/author.png" alt="" />
                        </a>
                        <div class="mb-0">
                          <a href="page-author.html" tabindex="0">
                            <strong class="author-name">Blog Admin</strong>
                          </a>
                          <p class="post-on font-sm text-grey-400 mb-0">
                            {{ post.postDate }}
                          </p>
                        </div>
                      </div>
                      <div
                        class="text-right post-list-icon align-self-center w-30"
                      >
                        <a><i class="elegant-icon icon_ribbon_alt"></i></a>
                        <a><i class="elegant-icon icon_heart_alt"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
        <div class="col-lg-4 primary-sidebar sticky-sidebar">
          <div class="widget-area">
            <div
              class="sidebar-widget widget-latest-posts mb-50 wow fadeIn animated"
            >
              <div class="widget-header-1 position-relative mb-30">
                <h5 class="mt-5 mb-30 font-heading">Most popular</h5>
              </div>
              <div class="post-block-list post-module-1">
                <ul class="list-post">
                  <li
                    *ngFor="let post of popularPostsArray"
                    class="wow fadeIn animated"
                  >
                    <div class="d-flex latest-small-thumb">
                      <div
                        class="post-thumb d-flex mr-15 border-radius-10 img-hover-scale overflow-hidden"
                      >
                        <a
                          [routerLink]="['/home', post.urlPara]"
                          class="color-white"
                        >
                          <img [src]="post.imgPath" alt="{{ post.title }}" />
                        </a>
                      </div>
                      <div class="post-content media-body align-self-center">
                        <h5
                          class="post-title mb-15 text-limit-3-row font-medium"
                        >
                          <a [routerLink]="['/home', post.urlPara]">{{
                            post.title
                          }}</a>
                        </h5>
                        <div class="entry-meta meta-1 float-left font-sm">
                          <span class="post-on has-dot">{{
                            post.postDate
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </li>
                  
                </ul>
              </div>
            </div>
            <!-- <div class="sidebar-widget widget_instagram wow fadeIn animated">
              <div class="widget-header-1 position-relative mb-30">
                <h5 class="mt-5 mb-30 font-heading">Gallery</h5>
              </div>
              <div class="instagram-gellay">
                <ul class="insta-feed">
                  <li>
                    <a
                      href="assets/imgs/thumbnail-3.jpg"
                      class="play-video"
                      data-animate="zoomIn"
                      data-duration="1.5s"
                      data-delay="0.1s"
                      ><img
                        class="border-radius-10"
                        src="assets/imgs/news/thumb-1.jpg"
                        alt=""
                    /></a>
                  </li>
                  <li>
                    <a
                      href="assets/imgs/thumbnail-4.jpg"
                      class="play-video"
                      data-animate="zoomIn"
                      data-duration="1.5s"
                      data-delay="0.1s"
                      ><img
                        class="border-radius-10"
                        src="assets/imgs/news/thumb-2.jpg"
                        alt=""
                    /></a>
                  </li>
                  <li>
                    <a
                      href="assets/imgs/thumbnail-5.jpg"
                      class="play-video"
                      data-animate="zoomIn"
                      data-duration="1.5s"
                      data-delay="0.1s"
                      ><img
                        class="border-radius-10"
                        src="assets/imgs/news/thumb-3.jpg"
                        alt=""
                    /></a>
                  </li>
                  <li>
                    <a
                      href="assets/imgs/thumbnail-3.jpg"
                      class="play-video"
                      data-animate="zoomIn"
                      data-duration="1.5s"
                      data-delay="0.1s"
                      ><img
                        class="border-radius-10"
                        src="assets/imgs/news/thumb-4.jpg"
                        alt=""
                    /></a>
                  </li>
                  <li>
                    <a
                      href="assets/imgs/thumbnail-4.jpg"
                      class="play-video"
                      data-animate="zoomIn"
                      data-duration="1.5s"
                      data-delay="0.1s"
                      ><img
                        class="border-radius-10"
                        src="assets/imgs/news/thumb-5.jpg"
                        alt=""
                    /></a>
                  </li>
                  <li>
                    <a
                      href="assets/imgs/thumbnail-5.jpg"
                      class="play-video"
                      data-animate="zoomIn"
                      data-duration="1.5s"
                      data-delay="0.1s"
                      ><img
                        class="border-radius-10"
                        src="assets/imgs/news/thumb-6.jpg"
                        alt=""
                    /></a>
                  </li>
                </ul>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="mt-65">
        <button class="btn btn-lg bg-dark text-white d-inline-block">
          Load more posts
        </button>
      </div>
    </div>
  </div>
  <!-- <section class="newsletter bg-brand-3 pt-100 pb-100">
    <div class="container">
      <div class="position-relative">
        <div class="row">
          <div class="col-lg-8">
            <div class="header-title-2 mb-65">
              <h4 class="mb-0 text-grey-400 wow fadeIn animated">
                <img src="assets/imgs/theme/svg/send.svg" alt="" />
                <span>Subscribe</span>
              </h4>
              <h3 class="font-heading wow fadeIn animated">
                to Our Newsletter
              </h3>
            </div>
            <form class="form-subcriber mt-30 d-flex wow fadeIn animated">
              <input
                type="email"
                class="form-control bg-white font-small"
                placeholder="Enter your email"
              />
              <button class="btn bg-dark text-white" type="submit">
                Subscribe
              </button>
            </form>
          </div>
        </div>
        <img
          src="assets/imgs/theme/sketch-1.png"
          alt=""
          class="sketch-1 wow fadeIn animated"
        />
      </div>
    </div>
  </section> -->
</main>
