<main>
  <section class="pt-65 pb-35 bg-brand-4">
    <div class="container">
      <div class="archive-header">
        <div class="archive-header-title">
          <h1 class="font-heading mb-30">Currency Converter</h1>
          <p class="mb-0">Live Exchange Rate Calculator</p>
        </div>
        <div class="breadcrumb">
          <a [routerLink]="['/categories']" rel="nofollow">Home</a>
          <span></span>
          <a [routerLink]="['/calculaters']" rel="nofollow">Calculators</a>
          <!-- Added Calculators link -->
          <span></span> Currency Converter
        </div>
      </div>
    </div>
  </section>
  <!-- <h2></h2> -->
  <section class="pt-30 pb-65">
    <div class="container">
      <div class="form-group">
        <label for="amount">Amount:</label>
        <input
          type="number"
          [(ngModel)]="amount"
          class="form-control"
          placeholder="Enter amount"
          (input)="convert()"
        />
      </div>

      <div class="form-group">
        <label for="fromCurrency">From:</label>
        <select
          [(ngModel)]="fromCurrency"
          class="form-control"
          (change)="onCurrencyChange()"
        >
          <option *ngFor="let currency of currencyList" [value]="currency.code">
            {{ currency.code }} - {{ currency.country }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="toCurrency">To:</label>
        <select
          [(ngModel)]="toCurrency"
          class="form-control"
          (change)="onCurrencyChange()"
        >
          <option *ngFor="let currency of currencyList" [value]="currency.code">
            {{ currency.code }} - {{ currency.country }}
          </option>
        </select>
      </div>

      <button class="btn btn-primary" (click)="getRates()">Convert</button>

      <div *ngIf="convertedAmount !== null">
        <h3>
          Converted Amount: {{ convertedAmount | number : "1.2-2" }}
          {{ toCurrency }}
        </h3>
      </div>
    </div>
  </section>
</main>
