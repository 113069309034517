import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calorie-calc',
  templateUrl: './calorie-calc.component.html',
  styleUrls: ['./calorie-calc.component.scss']
})
export class CalorieCalcComponent implements OnInit {

 

  ngOnInit(): void {
  }
  gender: string = 'male'; // default gender
  weight: number | null = null; // in kg
  height: number | null = null; // in cm
  age: number | null = null; // in years
  activityLevel: string = 'sedentary'; // default activity level

  bmr: number | null = null; // Basal Metabolic Rate
  calories: number | null = null; // Total Daily Energy Expenditure

  calculateCalories(): void {
    if (this.weight && this.height && this.age) {
      // Calculate Basal Metabolic Rate (BMR) using Harris-Benedict equation
      if (this.gender === 'male') {
        this.bmr = 88.362 + (13.397 * this.weight) + (4.799 * this.height) - (5.677 * this.age);
      } else if (this.gender === 'female') {
        this.bmr = 447.593 + (9.247 * this.weight) + (3.098 * this.height) - (4.330 * this.age);
      }

      // Calculate Total Daily Energy Expenditure (TDEE) based on activity level
      switch (this.activityLevel) {
        case 'sedentary':
          this.calories = this.bmr! * 1.2;
          break;
        case 'lightlyActive':
          this.calories = this.bmr! * 1.375;
          break;
        case 'moderatelyActive':
          this.calories = this.bmr! * 1.55;
          break;
        case 'veryActive':
          this.calories = this.bmr! * 1.725;
          break;
        case 'extraActive':
          this.calories = this.bmr!* 1.9;
          break;
        default:
          this.calories = null;
          break;
      }
    } else {
      this.bmr = null;
      this.calories = null;
    }
  }
}