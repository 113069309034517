import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Chart } from 'chart.js';
import { MatSliderChange } from '@angular/material/slider';

@Component({
  selector: 'app-sip',
  templateUrl: './sip.component.html',
  styleUrls: ['./sip.component.scss'],
})
export class SipComponent implements OnInit {
  sipForm!: FormGroup;
  dynamicSIPMessage: string = '';
  chart: any;
  investedAmount: number = 0;  // Declare investedAmount
  returns: number = 0;         // Declare returns
  totalValue: number = 0;      // Declare totalValue

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.sipForm = this.fb.group({
      investment: ['10000'],
      rate: ['12'],
      timePeriod: ['10'],
      totalValue: [''],
    });

    this.calculateSIP()
  }

  // Calculate SIP
  calculateSIP() {
    const P = this.sipForm.value.investment;
    const r = this.sipForm.value.rate / 100 / 12; // monthly rate
    const n = this.sipForm.value.timePeriod * 12; // total months
  
    this.totalValue = P * ((Math.pow(1 + r, n) - 1) / r) * (1 + r);
    this.investedAmount = P * n;
    this.returns = this.totalValue - this.investedAmount;
  
    this.sipForm.patchValue({ totalValue: this.totalValue.toFixed(2) });
  
    // You no longer need the dynamic message string
    this.renderChart(this.investedAmount, this.returns);
  }
  

  // Update Chart
  renderChart(investedAmount: number, returns: number) {
    if (this.chart) {
      this.chart.destroy();
    }
    this.chart = new Chart('sipChart', {
      type: 'doughnut',
      data: {
        labels: ['Invested Amount', 'Returns'],
        datasets: [
          {
            data: [investedAmount, returns],
            backgroundColor: ['#4caf50', '#03a9f4'],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  }

  // Handle Slider Input Events
  onInvestmentSliderChange(event: MatSliderChange) {
    this.sipForm.patchValue({ investment: event.value });
    
  }

  onInvestmentSliderChange2(event: MatSliderChange) {
    this.sipForm.patchValue({ investment: event.value });
    this.calculateSIP()
  }

  onRateSliderChange(event: MatSliderChange) {
    this.sipForm.patchValue({ rate: event.value });
    // this.calculateSIP()
  }

  onRateSliderChange2(event: MatSliderChange) {
    this.sipForm.patchValue({ rate: event.value });
    this.calculateSIP()
  }

  onTimePeriodSliderChange(event: MatSliderChange) {
    this.sipForm.patchValue({ timePeriod: event.value });
    // this.calculateSIP()
  }

  onTimePeriodSliderChange2(event: MatSliderChange) {
    this.sipForm.patchValue({ timePeriod: event.value });
    this.calculateSIP()
  }

  // Handle Input Field Events
  updateInvestment(event: any) {
    this.sipForm.patchValue({ investment: event.target.value });
    
    this.calculateSIP()
  }

 

  updateRate(event: any) {
    this.sipForm.patchValue({ rate: event.target.value });
    this.calculateSIP()
  }

  updateTimePeriod(event: any) {
    this.sipForm.patchValue({ timePeriod: event.target.value });
    this.calculateSIP()
  }
}
