import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
 

export const MAJOR_CURRENCIES: { code: string, country: string }[] = [
  { code: 'USD', country: 'United States' },
  { code: 'EUR', country: 'Eurozone' },
  { code: 'JPY', country: 'Japan' },
  { code: 'GBP', country: 'United Kingdom' },
  { code: 'AUD', country: 'Australia' },
  { code: 'CAD', country: 'Canada' },
  { code: 'CHF', country: 'Switzerland' },
  { code: 'CNY', country: 'China' },
  { code: 'SEK', country: 'Sweden' },
  { code: 'NZD', country: 'New Zealand' },
  { code: 'MXN', country: 'Mexico' },
  { code: 'SGD', country: 'Singapore' },
  { code: 'HKD', country: 'Hong Kong' },
  { code: 'NOK', country: 'Norway' },
  { code: 'KRW', country: 'South Korea' },
  { code: 'INR', country: 'India' },
  { code: 'RUB', country: 'Russia' },
  { code: 'ZAR', country: 'South Africa' },
  { code: 'BRL', country: 'Brazil' },
  { code: 'ARS', country: 'Argentina' },
  { code: 'PLN', country: 'Poland' },
  { code: 'HUF', country: 'Hungary' },
  { code: 'CZK', country: 'Czech Republic' },
  { code: 'ILS', country: 'Israel' },
  { code: 'DKK', country: 'Denmark' },
  { code: 'THB', country: 'Thailand' },
  { code: 'MYR', country: 'Malaysia' },
  { code: 'PHP', country: 'Philippines' },
  { code: 'AED', country: 'United Arab Emirates' },
  { code: 'KWD', country: 'Kuwait' },
  { code: 'QAR', country: 'Qatar' },
  { code: 'OMR', country: 'Oman' },
  { code: 'BND', country: 'Brunei' },
  { code: 'BDT', country: 'Bangladesh' },
  { code: 'KZT', country: 'Kazakhstan' },
  { code: 'LKR', country: 'Sri Lanka' },
  { code: 'UAH', country: 'Ukraine' },
  { code: 'CLP', country: 'Chile' },
  { code: 'COP', country: 'Colombia' },
  { code: 'PEN', country: 'Peru' },
  { code: 'MAD', country: 'Morocco' },
  { code: 'MUR', country: 'Mauritius' },
  { code: 'RSD', country: 'Serbia' },
  { code: 'BAM', country: 'Bosnia and Herzegovina' },
  { code: 'DOP', country: 'Dominican Republic' },
  { code: 'VEF', country: 'Venezuela' },
  { code: 'ZMW', country: 'Zambia' },
  { code: 'JOD', country: 'Jordan' },
];

@Component({
  selector: 'app-ex-rate',
  templateUrl: './ex-rate.component.html',
  styleUrls: ['./ex-rate.component.scss']
})
export class ExRateComponent implements OnInit {
  amount: number = 1;
  fromCurrency: string = 'USD';
  toCurrency: string = 'INR';
  convertedAmount: number | null = null;
  currencyList = MAJOR_CURRENCIES;
  rates: any = {};

  constructor(private exchangeRateService: AppService) {}

  ngOnInit(): void {
    this.getRates();
  }

  onCurrencyChange() {
    this.getRates(); // Fetch rates whenever currency changes
  }

  getRates() {
    this.exchangeRateService.getExchangeRates(this.fromCurrency).subscribe({
      next: (data) => {
        console.log('API Response:', data); // Log the API response
        if (data && data.conversion_rates) {
          this.rates = data.conversion_rates;
          this.convert();
        } else {
          console.error('Invalid data format received from API');
          this.rates = {};
        }
      },
      error: (err) => {
        console.error('Failed to fetch exchange rates:', err);
        this.rates = {}; // Handle errors
      }
    });
  }

  convert() {
    if (this.amount && this.rates && this.rates[this.toCurrency]) {
      this.convertedAmount = this.amount * this.rates[this.toCurrency];
    } else {
      this.convertedAmount = null;
      console.error(`Conversion rate for ${this.toCurrency} is not available.`);
    }
  }
}
