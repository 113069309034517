import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { DomSanitizer, Meta, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [DatePipe],
})
export class HomeComponent implements OnInit {
  dtueiheaders = new MatTableDataSource();
  dataForFilter: any = [];
  drFilter = {};
  detInit: any = [];
  videoUrl: SafeResourceUrl | null = null;
  posts: any[] = [];

  categoryLink: string = 'category.html';
  category: string = 'Lifestyle';
  postDate: string = 'September 15, 2021';
  title: string = '';
  descri: string = '';
  ytube: string = '';
  imgpath: string = '';
  id: string | null = null;

  constructor(
    private route: ActivatedRoute,
    public appService: AppService,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private meta: Meta,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    // Load data when component initializes
    this.loadData();
    this.fetchPosts();
  }

  setDynamicMetaTags(): void {
    // Set the page title
    this.titleService.setTitle(this.title);

    // Update meta tags dynamically
    this.meta.updateTag({ name: 'description', content: 'test descri222' });
    this.meta.updateTag({ property: 'og:title', content: 'test title' });
    this.meta.updateTag({ property: 'og:description', content: 'test desri' });
    // this.meta.updateTag({ property: 'og:image', content: this.imgpath });
    this.meta.updateTag({ property: 'og:image:secure', content: 'https://b-ill.com/assets/imgs/ogtest.webp' });
    
    this.meta.updateTag({ property: 'og:url', content: `https://www.b-ill.com/home/${this.id}` });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
  }

  fetchPosts(): void {
    this.appService.getBlogDataById('Related', this.id).subscribe(
      (data) => {
        // Assume data is an array of posts
        this.posts = data.map((post: any) => ({
          title: post.Title,
          imgUrl: post.filename,
          postDate: post.createddt,
          link: post.UrlPara,
        }));
      },
      (error) => {
        console.error('Error fetching posts:', error);
      }
    );
  }

  public loadData() {
    this.appService.getBlogDataById('Load', this.id).subscribe((data) => {
      const firstItem = data[0] as any;

      if (firstItem['Error'] === true) {
        if (firstItem['message'] === 'NO ITEMS FOUND.') {
          this.dtueiheaders.data = [];
        } else {
          this.appService.msgBox('Error', firstItem['message']);
        }
      } else {
        // Update post details from the fetched data
        this.detInit = data;
        this.dataForFilter = data;
        this.dtueiheaders.data = data;
        this.updatePostDetails(firstItem);
      }
    });
  }

  private updatePostDetails(firstItem: any) {
    // Dynamically set the post details
    this.setDynamicMetaTags();
    
    this.categoryLink = 'category.html';
    this.category = firstItem.Category;
    this.title = firstItem.Title;
    this.descri = firstItem.Descri;
    this.ytube = firstItem.YoutubeLink;
    this.imgpath = firstItem.filename;

    // Set video URL, if available
    if (this.ytube) {
      const unsafeUrl = this.convertToEmbedUrl(this.ytube);
      this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
    } else {
      this.videoUrl = null;
    }

    // Format the post date
    const date = new Date(firstItem.createddt);
    this.postDate = this.datePipe.transform(date, 'MMMM d, yyyy') || '';

    // Update the meta tags with the new data
    
  }

  private convertToEmbedUrl(unsafeUrl: string): string {
    // Convert to YouTube embed URL
    let videoId: string | undefined;
    if (unsafeUrl.includes('v=')) {
      videoId = unsafeUrl.split('v=')[1].split('&')[0];
    } else {
      videoId = unsafeUrl.split('/').pop();
    }

    return videoId ? `https://www.youtube.com/embed/${videoId}` : '';
  }
}
