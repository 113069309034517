import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { CategoryComponent } from './category/category.component';
import { ContactComponent } from './contact/contact.component';
import { PppComponent } from './ppp/ppp.component';
import { SipComponent } from './calc/sip/sip.component';
import { ExRateComponent } from './calc/ex-rate/ex-rate.component';
import { BmiCalcComponent } from './calc/bmi-calc/bmi-calc.component';
import { CalorieCalcComponent } from './calc/calorie-calc/calorie-calc.component';
import { CalcMenuComponent } from './calc/calc-menu/calc-menu.component';
import { FdCalculaterComponent } from './calc/fd-calculater/fd-calculater.component';

const routes: Routes = [
  { path: '', redirectTo: '/categories', pathMatch: 'full' }, // Redirect to 'home' by default
  { path: 'home/:id', component: MainComponent },
  { path: 'categories', component: CategoryComponent },
  { path: 'ppp_calculater', component: PppComponent },
  { path: 'sip_calculater', component: SipComponent },
  { path: 'bmi_calculater', component: BmiCalcComponent },
  { path: 'calorie_calculater', component: CalorieCalcComponent },
  { path: 'exchange_rate', component: ExRateComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'calculaters', component: CalcMenuComponent },
  { path: 'fd_calculater', component: FdCalculaterComponent },
  { path: '**', redirectTo: '/categories' } // Wildcard route for handling 404s
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
