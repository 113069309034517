<footer class="pt-65 bg-dark">
  <div class="container">
    <div class="row mb-65">
      <div class="col-md-6">
        <div class="logo wow fadeIn animated">
          <a href="index.html">
            <img src="assets/imgs/theme/logo.svg" alt=""
          /></a>
        </div>
      </div>
      <div class="col-md-6">
        <!-- <div class="d-flex text-right text-sm-left align-self-center justify-content-end wow fadeIn animated">
                    <h5 class="mr-15 text-white mb-0 align-self-center">All you need to build new site</h5>
                    <button class="btn btn-lg bg-brand-1">Download Now</button>
                </div> -->
      </div>
      <div class="col-12">
        <div class="bottom-line mt-40"></div>
      </div>
    </div>
    <div class="row bottom-area-2">
      <div class="col-lg-4 col-md-6">
        <div class="sidebar-widget widget-about wow fadeIn animated mb-30">
          <div class="widget-header-2 position-relative mb-30">
            <h5 class="mt-5 mb-30">About</h5>
          </div>
          <div class="textwidget">
            <p>
              B-ill.com (Bills of Wisdom, Stories of Life). 
              At B-ill.com, we’re passionate about sharing
              insights, tips, and stories that resonate with everyday life.
              Whether it's exploring new trends, diving into lifestyle hacks, or
              offering thoughtful reflections, our goal is to bring you content
              that informs, entertains, and inspires. Join us on this journey as
              we uncover the little joys and big ideas that make life a bit more
              exciting. Stay curious, stay informed, and most importantly, stay
              connected with B-ill.com.
            </p>
            <!-- <p><strong class="color-black">Address</strong><br>
                            123 Main Street<br>
                            New York, NY 10001</p>
                        <p><strong class="color-black">Phone</strong><br>
                            (+01) 234 567 89</p> -->
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div
          class="sidebar-widget widget_categories wow fadeIn animated mb-30"
          data-wow-delay="0.1s"
        >
          <div class="widget-header-2 position-relative mb-30">
            <h5 class="mt-5 mb-30">Quick link</h5>
          </div>
          <ul class="font-small">
            <li class="cat-item cat-item-2"><a href="#">About me</a></li>
            <li class="cat-item cat-item-4"><a href="#">Help & Support</a></li>
            <li class="cat-item cat-item-5">
              <a href="#">Licensing Policy</a>
            </li>
            <!-- <li class="cat-item cat-item-6"><a href="#">Refund Policy</a></li>
            <li class="cat-item cat-item-7"><a href="#">Hire me</a></li> -->
            <li class="cat-item cat-item-7"><a [routerLink]="['/contact']">Contact</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div
          class="sidebar-widget widget_tagcloud wow fadeIn animated mb-30"
          data-wow-delay="0.2s"
        >
          <!-- <div class="widget-header-2 position-relative mb-30">
            <h5 class="mt-5 mb-30">Tagcloud</h5>
          </div>
          <div class="tagcloud mt-50">
            <a class="tag-cloud-link" href="category.html">beautiful</a>
            <a class="tag-cloud-link" href="category.html">New York</a>
            <a class="tag-cloud-link" href="category.html">droll</a>
            <a class="tag-cloud-link" href="category.html">intimate</a>
            <a class="tag-cloud-link" href="category.html">loving</a>
            <a class="tag-cloud-link" href="category.html">travel</a>
            <a class="tag-cloud-link" href="category.html">fighting </a>
          </div> -->
        </div>
      </div>
    </div>
    <div class="footer-copy-right pt-30 mt-20 wow fadeIn animated font-md">
      <p class="float-md-left font-small text-muted">&copy; 2024, B-ill.com</p>
      <ul
        class="social-network d-inline-block list-inline color-white mb-20 float-right"
      >
        <li class="list-inline-item">
          <a href="#" target="_blank" title="Facebook"
            ><i class="elegant-icon social_facebook"></i
          ></a>
        </li>
        <!-- <li class="list-inline-item"><a href="#" target="_blank" title="Tweet now"><i class="elegant-icon social_twitter"></i></a></li>
                <li class="list-inline-item"><a href="#" target="_blank" title="Pin it"><i class="elegant-icon social_pinterest"></i></a></li>
                <li class="list-inline-item"><a href="#" target="_blank" title="Pin it"><i class="elegant-icon social_skype"></i></a></li> -->
      </ul>
    </div>
  </div>
</footer>
<!-- End Footer -->
<div class="dark-mark"></div>
