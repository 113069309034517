<main>
  <div class="container single-content">
    <div class="entry-header entry-header-style-1 mb-50 pt-50">
      <div class="post-meta-1 mb-20">
        <a
          [href]="categoryLink"
          class="tag-category bg-brand-1 shadown-1 text-dark button-shadow hover-up-3"
          tabindex="0"
        >
          {{ category }}
        </a>
        <span class="post-date text-muted font-md">{{ postDate }}</span>
      </div>

      <h1 class="entry-title mb-50 fw-700">
        {{ title }}
      </h1>
    </div>
    <!--end single header-->

    <figure class="image mb-30 m-auto text-center border-radius-10 hover-up-3">
      <img class="border-radius-10" src="{{ imgpath }}" alt="post-title" />
    </figure>
    <!--figure-->

    <article class="entry-wraper mb-50">
      <div class="excerpt mb-30">
        <p>{{ descri }}</p>
      </div>

      <!-- Render the video only if videoUrl is available -->
      <div class="video-container" *ngIf="videoUrl">
        <iframe [src]="videoUrl" width="560" height="315" frameborder="0" allowfullscreen></iframe>
      </div>

      <!--More posts-->
      <div class="single-more-articles border-radius-10">
        <div class="widget-header-2 position-relative mb-30">
          <h5 class="mb-30 font-heading">Related posts</h5>
          <button class="single-more-articles-close">
            <i class="elegant-icon icon_close"></i>
          </button>
        </div>
        <div class="post-block-list post-module-1">
          <ul class="list-post">
            <li *ngFor="let post of posts">
              <div class="d-flex latest-small-thumb">
                <div
                  class="post-thumb d-flex mr-15 border-radius-10 img-hover-scale overflow-hidden"
                >
                  <a class="color-white" [href]="post.link" tabindex="0">
                    <img [src]="post.imgUrl" alt="{{ post.title }}" />
                  </a>
                </div>
                <div class="post-content media-body align-self-center">
                  <h5 class="post-title mb-15 text-limit-3-row font-medium">
                    <a [href]="post.link" tabindex="0">{{ post.title }}</a>
                  </h5>
                  <div class="entry-meta meta-1 float-left font-sm">
                    <span class="post-on has-dot">{{ post.postDate }}</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        
      </div>
    </article>
  </div>
</main>
