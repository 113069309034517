import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as Chart from 'chart.js';

@Component({
  selector: 'app-fd-calculater',
  templateUrl: './fd-calculater.component.html',
  styleUrls: ['./fd-calculater.component.scss']
})
export class FdCalculaterComponent implements OnInit {
 
  fdForm: FormGroup;
  principal: number = 0;
  rate: number = 0;
  timePeriod: number = 0;
  compounding: string = 'yearly';
  interest: number = 0;
  maturityAmount: number = 0;
  fdChart: any;

  constructor(private fb: FormBuilder) {
    this.fdForm = this.fb.group({
      principal: [100000],
      rate: [6.5],
      timePeriod: [5],
      compounding: ['yearly'],
    });
  }

  ngOnInit() {
    this.calculateFD();
  }

  calculateFD() {
    // Fetch the form values
    this.principal = this.fdForm.value.principal;
    const rate = this.fdForm.value.rate;
    const timePeriod = this.fdForm.value.timePeriod;
    const compounding = this.fdForm.value.compounding;
  
    let periods, ratePerPeriod;
  
    // Determine compounding frequency
    if (compounding === 'monthly') {
      ratePerPeriod = rate / 12 / 100;
      periods = timePeriod * 12;
    } else {
      ratePerPeriod = rate / 100;
      periods = timePeriod;
    }
  
    // Calculate maturity amount and interest
    this.maturityAmount = this.principal * Math.pow(1 + ratePerPeriod, periods);
    this.interest = this.maturityAmount - this.principal;
  
    // Update the chart with the correct values
    this.updateChart();
  }
  

  updateChart() {
    const ctx = document.getElementById('fdChart') as HTMLCanvasElement;
    if (this.fdChart) {
      this.fdChart.destroy();
    }
    this.fdChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: ['Principal Amount', 'Est. Interest'],
        datasets: [
          {
            data: [this.principal, this.interest],
            backgroundColor: ['#FF6384', '#36A2EB'],
            hoverBackgroundColor: ['#FF6384', '#36A2EB'],
          },
        ],
      },
    });
  }

  // Slider and Input updates
  onPrincipalSliderChange(event: any) {
    this.fdForm.patchValue({ principal: event.value });
    this.calculateFD();
  }

  updatePrincipal(event: any) {
    this.calculateFD();
  }

  onRateSliderChange(event: any) {
    this.fdForm.patchValue({ rate: event.value });
    this.calculateFD();
  }

  updateRate(event: any) {
    this.calculateFD();
  }

  onTimePeriodSliderChange(event: any) {
    this.fdForm.patchValue({ timePeriod: event.value });
    this.calculateFD();
  }

  updateTimePeriod(event: any) {
    this.calculateFD();
  }
}