import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-cat-det',
  templateUrl: './cat-det.component.html',
  styleUrls: ['./cat-det.component.scss']
})
export class CatDetComponent implements OnInit {

  dtueiheaders = new MatTableDataSource();
  videoUrl!: SafeResourceUrl;
  postsArray: any = [];

  categoryLink: string = 'category.html'; // default link or fetched from the data
  category: string = 'Lifestyle'; // default category or fetched from the data
  postDate: string = 'September 15, 2021'; // default date or fetched from the data
  title: string = '';
  descri: string = '';
  ytube: string = '';
  imgpath: string = '';
  popularPostsArray: any[] = []; // Array to hold most popular posts data

  constructor(public appService: AppService, private datePipe: DatePipe, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.loadData();
    this.loadDataMostPopular();
  }

  public loadDataMostPopular() {
    this.appService.getBlogData('S').subscribe((data) => { // Assuming getMostPopularData is your API call for most popular posts
        if (data && data.length > 0) {
            this.updateMostPopularPostDetails(data);
        }
    });
  }

  private updateMostPopularPostDetails(posts: any[]) {
    posts.forEach((post) => {
        const categoryLink = 'category.html';
        const category = post.Category;
        const title = post.Title;
        const description = post.Descri;
        const imgPath = post.filename;
        const urlPara = post.UrlPara;
        const videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.convertToEmbedUrl(post.YoutubeLink));
        const postDate = this.datePipe.transform(new Date(post.createddt), 'MMMM d, yyyy') || '';

        this.popularPostsArray.push({
            categoryLink,
            category,
            title,
            description,
            imgPath,
            videoUrl,
            postDate,
            urlPara
        });
    });
  }

 
 


  public loadData() {
    // this.appService.setHistory(this.form, 'UEIHeader-list');
    this.appService.getBlogData('S').subscribe((data) => {
        if (data && data.length > 0) {
            const firstItem = data[0] as any; // or as { Error: boolean, message: string };

            if (firstItem['Error'] === true && firstItem['message'] === 'NO ITEMS FOUND.') {
                this.dtueiheaders.data = [];
            } else if (firstItem['Error'] === true) {
                this.appService.msgBox('Error', firstItem['message']);
            } else {
              console.log(data)
                this.dtueiheaders.data = data;
                this.updatePostDetails(data); // Pass the entire data array to the updatePostDetails method
            }
        } else {
            this.dtueiheaders.data = [];
        }
    });
}

private updatePostDetails(posts: any[]) {
    // Loop through each item in the posts array
    posts.forEach((post, index) => {
        const categoryLink = 'category.html'; // Set the category link
        const category = post.Category; // Set the category
        const title = post.Title; // Set the title
        const description = post.Descri; // Set the description
        const imgPath = post.filename; // Set the image path
        const urlPara = post.UrlPara; 
        // Convert YouTube URL to embed URL
        const unsafeUrl = post.YoutubeLink;
        const videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.convertToEmbedUrl(unsafeUrl));

        // Format the date
        const date = new Date(post.createddt); // Assuming createddt is in a format recognized by the Date constructor
        const postDate = this.datePipe.transform(date, 'MMMM d, yyyy') || '';

        // You can now use these variables (category, title, description, imgPath, videoUrl, postDate) to dynamically update your HTML.

        // Example: You might want to push these details into an array that you loop through in your HTML template using *ngFor
        this.postsArray.push({
            categoryLink,
            category,
            title,
            description,
            imgPath,
            videoUrl,
            postDate,
            urlPara
        });
    });
}

private convertToEmbedUrl(unsafeUrl: string): string {
    // Convert YouTube URL to embed URL
    const videoId = unsafeUrl.split('v=')[1] || unsafeUrl.split('/').pop();
    return `https://www.youtube.com/embed/${videoId}`;
}

}
