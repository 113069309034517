<div class="container text-center">
    <h2>Calculators</h2>
    <div class="row">
      <div class="col-md-3 col-sm-6 mb-4">
        <button class="btn btn-primary btn-icon" (click)="navigateTo('/ppp_calculater')">
          <i class="fas fa-balance-scale"></i>
          <span class="d-block mt-2">PPP</span>
        </button>
      </div>
      <div class="col-md-3 col-sm-6 mb-4">
        <button class="btn btn-primary btn-icon" (click)="navigateTo('/sip_calculater')">
          <i class="fas fa-chart-line"></i>
          <span class="d-block mt-2">SIP </span>
        </button>
      </div>
      <div class="col-md-3 col-sm-6 mb-4">
        <button class="btn btn-primary btn-icon" (click)="navigateTo('/exchange_rate')">
          <i class="fas fa-exchange-alt"></i>
          <span class="d-block mt-2">Exchange Rate</span>
        </button>
      </div>
      <div class="col-md-3 col-sm-6 mb-4">
        <button class="btn btn-primary btn-icon" (click)="navigateTo('/bmi_calculater')">
          <i class="fas fa-weight"></i>
          <span class="d-block mt-2">BMI</span>
        </button>
      </div>
      <div class="col-md-3 col-sm-6 mb-4">
        <button class="btn btn-primary btn-icon" (click)="navigateTo('/calorie_calculater')">
          <i class="fas fa-fire-alt"></i>
          <span class="d-block mt-2">Calorie</span>
        </button>
      </div>

      <div class="col-md-3 col-sm-6 mb-4">
        <a class="btn btn-primary btn-icon" [routerLink]="['/fd_calculater']">
          <i class="fas fa-piggy-bank"></i>
          <span class="d-block mt-2">FD</span>
        </a>
      </div>

      
    </div>
  </div>
  