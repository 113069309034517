<main>
    <form [formGroup]="fdForm" (ngSubmit)="calculateFD()">
      <section class="pt-65 pb-35 bg-brand-4">
        <div class="container">
          <div class="archive-header">
            <div class="archive-header-title">
              <h1 class="font-heading mb-30">FD Calculator</h1>
              <p class="mb-0">
                Estimate the future value of your fixed deposit.
              </p>
            </div>
            <div class="breadcrumb">
              <a [routerLink]="['/categories']" rel="nofollow">Home</a>
              <span></span>
              <a [routerLink]="['/calculaters']" rel="nofollow">Calculators</a> <!-- Added Calculators link -->
              <span></span> FD Calculator
            </div>
            
          </div>
        </div>
      </section>
  
      <section class="pt-30 pb-65">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <div class="row">
                <!-- Principal Amount Input and Slider -->
                <div class="col-12">
                  <div class="form-group">
                    <label for="principal">Principal Amount (₹)</label>
                    <mat-slider
                      [min]="1000"
                      [max]="10000000"
                      step="1000"
                      formControlName="principal"
                      (input)="onPrincipalSliderChange($event)"
                      thumbLabel
                      tickInterval="100000"
                    ></mat-slider>
                    <input
                      class="form-control"
                      formControlName="principal"
                      id="principal"
                      type="number"
                      min="1000"
                      max="10000000"
                      (input)="updatePrincipal($event)"
                      placeholder="Enter Principal Amount"
                    />
                  </div>
                </div>
  
                <!-- Interest Rate Input and Slider -->
                <div class="col-12">
                  <div class="form-group">
                    <label for="rate">Interest Rate (% p.a)</label>
                    <mat-slider
                      [min]="1"
                      [max]="15"
                      step="0.1"
                      formControlName="rate"
                      (input)="onRateSliderChange($event)"
                      thumbLabel
                      tickInterval="1"
                    ></mat-slider>
                    <input
                      class="form-control"
                      formControlName="rate"
                      id="rate"
                      type="number"
                      min="1"
                      max="15"
                      step="0.1"
                      (input)="updateRate($event)"
                      placeholder="Enter Interest Rate"
                    />
                  </div>
                </div>
  
                <!-- Time Period Input and Slider -->
                <div class="col-12">
                  <div class="form-group">
                    <label for="timePeriod">Investment Time Period (Years)</label>
                    <mat-slider
                      [min]="1"
                      [max]="40"
                      step="1"
                      formControlName="timePeriod"
                      (input)="onTimePeriodSliderChange($event)"
                      thumbLabel
                      tickInterval="5"
                    ></mat-slider>
                    <input
                      class="form-control"
                      formControlName="timePeriod"
                      id="timePeriod"
                      type="number"
                      min="1"
                      max="40"
                      (input)="updateTimePeriod($event)"
                      placeholder="Enter Time Period (Years)"
                    />
                  </div>
                </div>
  
                <!-- Compounding Frequency Input -->
                <div class="col-12">
                  <div class="form-group">
                    <label for="compounding">Compounding Frequency</label>
                    <select
                      class="form-control"
                      formControlName="compounding"
                      id="compounding"
                    >
                      <option value="monthly">Monthly</option>
                      <option value="yearly">Yearly</option>
                    </select>
                  </div>
                </div>
  
                <!-- FD Result -->
                <div class="container" style="padding-left: 60px; padding-right: 60px;">
                  <div class="row">
                    <!-- Row 1: Principal Amount -->
                    <!-- <div class="col-md-6 text-left">
                      <p class="investment-details">
                        <strong>Principal Amount:</strong>
                      </p>
                    </div>
                    <div class="col-md-6 text-right">
                      <p class="investment-details">
                        ₹{{ principal | number : "1.0-0" }}
                      </p>
                    </div>
   -->
                    <!-- Row 2: Est. Interest -->
                    <div class="col-md-6 text-left">
                      <p class="investment-details">
                        <strong>Est. Interest:</strong>
                      </p>
                    </div>
                    <div class="col-md-6 text-right">
                      <p class="investment-details">
                        ₹{{ interest | number : "1.0-0" }}
                      </p>
                    </div>
  
                    <!-- Row 3: Maturity Amount -->
                    <div class="col-md-6 text-left">
                      <p class="investment-details" style="font-size: 22;">
                        <strong>Maturity Amount:</strong>
                      </p>
                    </div>
                    <div class="col-md-6 text-right">
                      <p class="investment-details" style="font-size: 22; font-weight: bold;">
                        ₹{{ maturityAmount | number : "1.0-0" }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <!-- Chart / Result Section -->
            <div class="col-md-4">
              <div class="pie-chart">
                <!-- Placeholder for Pie Chart -->
                <canvas id="fdChart" style="width: 100%; height: 400px"></canvas>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </main>
  