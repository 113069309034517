<main>
  <section class="pt-65 pb-35 bg-brand-4">
    <div class="container">
      <div class="archive-header">
        <div class="archive-header-title">
          <h1 class="font-heading mb-30">BMI Calculater</h1>
          <p class="mb-0">BMI Calculater</p>
        </div>
        <div class="breadcrumb">
          <a [routerLink]="['/categories']" rel="nofollow">Home</a>
          <span></span>
          <a [routerLink]="['/calculaters']" rel="nofollow">Calculators</a>
          <!-- Added Calculators link -->
          <span></span> BMI Calculater
        </div>
      </div>
    </div>
  </section>
  <!-- <h2></h2> -->
  <section class="pt-30 pb-65">
    <div class="container">
  
    <form (ngSubmit)="calculateBMI()">
      <div class="form-group">
        <label for="weight">Weight (kg):</label>
        <input type="number" id="weight" [(ngModel)]="weight" name="weight" class="form-control" required />
      </div>
  
      <div class="form-group">
        <label>Height:</label>
        <div class="row">
          <div class="col">
            <label for="feet">Feet</label>
            <input type="number" id="feet" [(ngModel)]="feet" name="feet" class="form-control" (input)="onFeetInchChange()" placeholder="Feet" />
          </div>
          <div class="col">
            <label for="inches">Inches</label>
            <input type="number" id="inches" [(ngModel)]="inches" name="inches" class="form-control" (input)="onFeetInchChange()" placeholder="Inches" />
          </div>
        </div>
      </div>
  
      <div class="form-group">
        <label for="centimeters">Or Height (cm):</label>
        <input type="number" id="centimeters" [(ngModel)]="centimeters" name="centimeters" class="form-control" (input)="onCmChange()" placeholder="Centimeters" />
      </div>
  
      <button type="submit" class="btn btn-primary">Calculate</button>
    </form>
  
    <div *ngIf="bmi !== null">
      <h3>BMI: {{ bmi | number:'1.2-2' }}</h3>
      <p>{{ bmiCategory }}</p>
    </div>
  </div>
  </section>
  </main>