<main>
  <form [formGroup]="sipForm" (ngSubmit)="calculateSIP()">
    <section class="pt-65 pb-35 bg-brand-4">
      <div class="container">
        <div class="archive-header">
          <div class="archive-header-title">
            <h1 class="font-heading mb-30">SIP Calculator</h1>
            <p class="mb-0">
              Estimate the future value of your investments through SIPs
            </p>
          </div>
          <div class="breadcrumb">
            <a [routerLink]="['/categories']" rel="nofollow">Home</a>
            <span></span>
            <a [routerLink]="['/calculaters']" rel="nofollow">Calculators</a> <!-- Added Calculators link -->
            <span></span> SIP Calculator
          </div>
        </div>
      </div>
    </section>

    <section class="pt-30 pb-65">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="row">
              <!-- Monthly Investment Input and Slider -->
              <div class="col-12">
                <div class="form-group">
                  <label for="investment">Monthly Investment (₹)</label>
                  <mat-slider
                    [min]="500"
                    [max]="200000"
                    step="100"
                    formControlName="investment"
                    (input)="onInvestmentSliderChange($event)"
                    (change)="onInvestmentSliderChange2($event)"
                    thumbLabel
                    tickInterval="10000"
                  ></mat-slider>
                  <input
                    class="form-control"
                    formControlName="investment"
                    id="investment"
                    type="number"
                    min="500"
                    max="1000000"
                    (input)="updateInvestment($event)"
                    placeholder="Enter Monthly Investment"
                  />
                </div>
              </div>

              <!-- Expected Return Rate Input and Slider -->
              <div class="col-12">
                <div class="form-group">
                  <label for="rate">Expected Return Rate (% p.a)</label>
                  <mat-slider
                    [min]="1"
                    [max]="30"
                    step="0.1"
                    formControlName="rate"
                    (input)="onRateSliderChange($event)"
                    (change)="onRateSliderChange2($event)"
                    thumbLabel
                    tickInterval="1"
                  ></mat-slider>
                  <input
                    class="form-control"
                    formControlName="rate"
                    id="rate"
                    type="number"
                    min="1"
                    max="30"
                    step="0.1"
                    (input)="updateRate($event)"
                    placeholder="Enter Expected Rate of Return"
                  />
                </div>
              </div>

              <!-- Time Period Input and Slider -->
              <div class="col-12">
                <div class="form-group">
                  <label for="timePeriod">Investment Time Period (Years)</label>
                  <mat-slider
                    [min]="1"
                    [max]="40"
                    step="1"
                    formControlName="timePeriod"
                    (input)="onTimePeriodSliderChange($event)"
                    (change)="onTimePeriodSliderChange2($event)"
                    thumbLabel
                    tickInterval="5"
                  ></mat-slider>
                  <input
                    class="form-control"
                    formControlName="timePeriod"
                    id="timePeriod"
                    type="number"
                    min="1"
                    max="40"
                    (input)="updateTimePeriod($event)"
                    placeholder="Enter Time Period (Years)"
                  />
                </div>
              </div>

              <!-- SIP Result -->
              <!-- <div class="col-12">
                <div class="form-group">
                  <input
                    class="form-control"
                    formControlName="totalValue"
                    id="totalValue"
                    type="text"
                    readonly
                    placeholder="Total Value"
                  />
                </div>
              </div> -->

              <!-- Submit Button -->
              <!-- <div class="form-group">
                <button class="btn btn-lg bg-dark text-white" type="submit">
                  Calculate SIP
                </button>
              </div> -->

              <!-- Dynamic Result Message -->
              <!-- Dynamic Result Message -->
              <div class="container"  style="padding-left: 60px; padding-right: 60px;">
                <div class="row">
                  <!-- Row 1: Investment Amount -->
                  <div class="col-md-6 text-left">
                    <p class="investment-details">
                      <strong>Investment Amount:</strong>
                    </p>
                  </div>
                  <div class="col-md-6 text-right">
                    <p class="investment-details">
                      ₹{{ investedAmount | number : "1.0-0" }}
                    </p>
                  </div>
              
                  <!-- Row 2: Est. Return -->
                  <div class="col-md-6 text-left">
                    <p class="investment-details">
                      <strong>Est. Return:</strong>
                    </p>
                  </div>
                  <div class="col-md-6 text-right">
                    <p class="investment-details">
                      ₹{{ returns | number : "1.0-0" }}
                    </p>
                  </div>
              
                  <!-- Row 3: Total Value -->
                  <div class="col-md-6 text-left">
                    <p class="investment-details" style="font-size: 22;">
                      <strong>Total Value:</strong>
                    </p>
                  </div>
                  <div class="col-md-6 text-right">
                    <p class="investment-details" style="font-size: 22; font-weight: bold;">
                      ₹{{ totalValue | number : "1.0-0" }}
                    </p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>

          <!-- Chart / Result Section -->
          <div class="col-md-4">
            <div class="pie-chart">
              <!-- Placeholder for Pie Chart -->
              <canvas id="sipChart" style="width: 100%; height: 400px"></canvas>
            </div>
          </div>
        </div>
      </div>
    </section>
  </form>
</main>
